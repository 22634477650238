import { AppUtils } from './../../../../utils/app-utils';
import { Component, Input, OnInit, HostListener } from '@angular/core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { DgSidebarItemModel } from '../../dg-model/dg-sidebar-item-model';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'dg-sidebar',
  templateUrl: './dg-sidebar.component.html',
  styleUrls: ['./dg-sidebar.component.css']
})
export class DgSidebarComponent implements OnInit {
  public icon = faBars;
  private exibirAberto = true;

  isExpanded = true;
  isShowing = false;
  showSubSubMenu: boolean = false;
  showSubMenus: Map<Number, boolean>;
  superUser = false
  activeIndex = null;

  @Input()
  public menuItens: Array<DgSidebarItemModel>;

  public completeMenu: Array<DgSidebarItemModel>;

  constructor(private util: AppUtils, private routeService: Router) {
    this.showSubMenus = new Map();
    this.completeMenu = this.menuItens;
  }

  showSubmenus(index): boolean {
    let showSubmenu = this.showSubMenus.get(index);
    if (this.util.isNullOrUndefined(showSubmenu)) {
      this.showSubMenus.set(index, false);
      return false;
    } else {
      return showSubmenu;
    }
  }

  activeSubmenus(index){
    let subMenuUrl = this.completeMenu[index].subitem.map(item => item.url);
    let activeUrl = this.routeService.url;
    
    return subMenuUrl.some(url => activeUrl.startsWith(url));
  }

  ngOnInit(): void {
    /* if (localStorage.getItem("userName") == "ALEXANDRE GONÇALVES PEREIRA"){
      this.completeMenu = [this.menuItens[0], this.menuItens[1], this.menuItens[5], this.menuItens[6], this.menuItens[10]]
    } else{
      this.completeMenu = this.menuItens;
      if ((localStorage.getItem("userName") == "GIOVANE MICHELON DE CASTRO" || localStorage.getItem("userName") == "ISABELE CRISTIE ARANTES ABDALA GREGORIO")){
        this.completeMenu[this.completeMenu.findIndex(page => page.label == 'Caixa de entrada')].subitem.find(item => item.label == 'Tarefas Distribuídas').showItem = true;
      } else{
        this.completeMenu[this.completeMenu.findIndex(page => page.label == 'Caixa de entrada')].subitem.find(item => item.label == 'Tarefas Distribuídas').showItem = false;
      }
    } */

    this.completeMenu = this.menuItens;
  }

  @HostListener('window:update-sidebar')
  updateSidebar() {
    this.ngOnInit()
  }
  
  closeSidebar(){
    if(innerWidth < 901){
      window.dispatchEvent(new CustomEvent('sidebar-event', {detail: {fechar: true}}));
    } else{

    }
  }
    
 
  showSubMenu(index: Number) {
    let showSubmenu = this.showSubMenus.get(index);
    if (this.util.isNullOrUndefined(showSubmenu)) {
      this.showSubMenus.set(index, false);
    } else {
      this.showSubMenus.set(index, !showSubmenu);
    }
  }

}
