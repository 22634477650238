import { DgTableEntity } from "src/app/components/dg-shared/dg-data-table/model/DgTableEntity";

export class ConfiguracaoEntity extends DgTableEntity {

    constructor(
        id?: number,
        emailsComunicadoCadastro?: string[],
    ) {
        super();
        this.id = id;
        this.emailsComunicadoCadastro = emailsComunicadoCadastro;
    }

    public id: number;
    public emailsComunicadoCadastro: string[];
}
