import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { DgSharedModule } from './components/dg-shared/dg-shared.module';
import { PipesModule } from './pipe/pipes.module';
import { SnackBarService } from './services/snack-bar.service';
import { AppUtils } from './utils/app-utils';
import { DgTemplateModule } from './components/dg-shared/dg-template/dg-template.module';
import { HomeModule } from './pages/home/home.module';
import { LoginComponent } from './pages/login/login.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { RecuperaTokenService } from './services/recupera-token.service';
import { CertificadoModule } from './pages/certificado/certificado.module';
import { CadastroSeloModule } from './pages/cadastro-selo/cadastro-selo.module';
import { CarbonoNeutroModule } from './pages/carbono-neutro/carbono-neutro.module';
import { ConfiguracaoModule } from './pages/configuracao/configuracao.module';
import { ValidarModule } from './pages/validar/validar.module';
import { CookieService } from 'ngx-cookie-service';
import { CacheInterceptor } from './services/http/cache-interceptor';

// regirstrando localidade para pipes de data do angular
// o PROVIDER { provide: LOCALE_ID, useValue: "pt-BR" }
// está diretamente conectado a essa função
registerLocaleData(localePt);

// configuração base de ngx-mask
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    MatTabsModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatStepperModule,
    PipesModule,
    DgSharedModule,
    HomeModule,
    FontAwesomeModule,
    NgMultiSelectDropDownModule,
    DgTemplateModule,
    NgxMaskModule.forRoot(),
    CertificadoModule,
    CadastroSeloModule,
    CarbonoNeutroModule,
    ConfiguracaoModule,
    ValidarModule
  ],
  providers: [
    AppUtils,
    RecuperaTokenService,
    SnackBarService,
    CookieService,
    {provide: OverlayContainer, useClass: FullscreenOverlayContainer},
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    { provide: LOCALE_ID, useValue: "pt-BR" },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
