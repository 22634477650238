import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

const { version } = require('./../../../../package.json');

@Component({
  selector: 'app-carbono-neutro',
  templateUrl: './carbono-neutro.component.html',
  styleUrls: ['./carbono-neutro.component.scss']
})
export class CarbonoNeutroComponent implements OnInit {

  version = version;

  constructor(public router: Router) {
    window.dispatchEvent(new CustomEvent('hide-header'));
  }

  ngOnInit(): void {
  }

  selos() {
    this.router.navigateByUrl('/selos');
};

}
