import { Pipe, PipeTransform } from '@angular/core';
import { TipoDocumento } from '../enum/tipo-documento.enum';

@Pipe({
  name: 'tipoDocumento'
})
export class TipoDocumentoPipe implements PipeTransform {

  transform(value: string): string {
    const tipoDocumento = TipoDocumento;
    return tipoDocumento[value];
  }

}
