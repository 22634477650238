import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEmpty } from 'rxjs/operators';
import { isNull, isUndefined } from 'util';
import { LoggedUser } from '../model/logged-user';
import base64url, { Base64Url } from 'base64url';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AppUtils {

  public readonly TIPO_PDF = 'application/pdf';

  public readonly TIPO_DOC = 'application/msword';

  public readonly TIPO_DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

  private devMode_: boolean;
  private nameEnvironment_: string;
  private webServiceUrl: string;
  private urlFront: string;
  private loadingSubject = new BehaviorSubject<boolean>(false);

  constructor(httpClient: HttpClient) {
    /* httpClient.get('assets/webserviceconfig.json').subscribe((file: any) => {
      this.devMode_ = file.devMode;
      this.loadingSubject.next(file.devMode);
      this.webServiceUrl = file.devUrl;
    }, (err) => {
    }); */
    this.devMode_ = !environment.production;
    this.nameEnvironment_ = environment.name;
    this.loadingSubject.next(!environment.production);
    this.webServiceUrl = environment.URL_GATEWAY;
    this.urlFront = environment.URL_FRONT;
  }

  get devMode() {
    return this.loadingSubject.asObservable();
  }

  get nameEnvironment(){
    return this.nameEnvironment_;
  }

  public getBaseUrlWebService(): string {
    return this.webServiceUrl;
  }

  public getUrlFront(): string {
    return this.urlFront;
  }

  public isNullOrUndefined(value: any): boolean {
    if (isNull(value) || isUndefined(value)) {
      return true;
    } else {
      return false;
    }
  }

  public isNullOrUndefinedOrEmpty(value: string): boolean {
    if (isNull(value) || isUndefined(value) || value.trim().length <= 0) {
      return true;
    } else {
      return false;
    }
  }

  public stringifyMap(valueMap: Map<string, string>) {
    return JSON.stringify(AppUtils.convertMap(valueMap));
  }

  static convertMap(map: Map<any, any>) {
    const convertMap = {};
    map.forEach((value, key) => {
      convertMap[key] = value;
    });
    return convertMap;
  }

  base64ToUint8Array(base64) {
    const byteString = window.atob(base64);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      arrayBuffer[i] = byteString.charCodeAt(i);
    }
    const int8Array = new Uint8Array(arrayBuffer);
    return int8Array;
  }

  base64ToBlob(base64, extensaoArquivoSelecionado) {
    const int8Array = this.base64ToUint8Array(base64);
    const blob = new Blob([int8Array], {type: extensaoArquivoSelecionado });
    return blob;
  }

  loadScriptsForBpmn(document) {
    let body = <HTMLDivElement> document.body;
    /* let scriptPolyfills = document.createElement('script');
    scriptPolyfills.innerHTML = '';
    scriptPolyfills.src = './assets/polyfills.js';
    body.appendChild(scriptPolyfills); */

    let scriptSentry = document.createElement('script');
    scriptSentry.innerHTML = '';
    scriptSentry.src = './assets/sentry.js';
    body.appendChild(scriptSentry);

    let scriptApp = document.createElement('script');
    scriptApp.innerHTML = '';
    scriptApp.src = './assets/app.js';
    scriptApp.async = true;
    body.appendChild(scriptApp);

    /* let scriptAnalytics = document.createElement('script');
    scriptAnalytics.innerHTML = '';
    scriptAnalytics.src = './assets/analytics.js';
    scriptAnalytics.async = true;
    body.appendChild(scriptAnalytics); */
  }


  //Pegará o usuário da string JWT.
  //Passar como argumento a string com o jwt
  //O módulo base64url(no package.json) exigiu uma dependência e configuração adicional
  //A depencia foi a instalação de outro módulo chamado 'buffer'
  //A configuração foi definir o módulo 'buffer' como variável global.
  //link usado para configuração abaixo:
  //https://stackoverflow.com/questions/55021263/buffer-is-not-defined-error-after-migrating-to-angular-7
  public takeUserInfoFromPayload(jwtString:string): LoggedUser{
    let tempReturn = JSON.parse(base64url.decode(jwtString.split('.')[1]));

    return tempReturn.details;
  }


  //Transforma de base 64 para file. Retirado do component peticionar do Gaid.
  base64ToFile(base64Src: string, tipoArquivo: string){
    const byteArray = new Uint8Array(atob(base64Src).split('').map(char => char.charCodeAt(0)));
    return new Blob([byteArray], {type: tipoArquivo});
  }

}
