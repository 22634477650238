import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  formLogin = new FormGroup({
    login: new FormControl('', Validators.required),
    senha: new FormControl('', Validators.required)
  });

  constructor(private snack: MatSnackBar) { }

  ngOnInit(): void {
  }

  logar(): void {
    if (this.formLogin.get('login').value === 'user' && this.formLogin.get('senha').value === 'user') {
      this.snack.open('Logado com sucesso!', 'fechar', {
        duration: 2000,
      });
    } else {
      this.snack.open('Credenciais inválidas', 'fechar', {
        duration: 2000,
      });
    }
  }

}
