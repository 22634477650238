import { Component, Input, OnInit } from '@angular/core';
import { isUndefined } from 'min-dash';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dg-card',
  templateUrl: './dg-card.component.html',
  styleUrls: ['./dg-card.component.css']
})
export class DgCardComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  padd: string = "padding-top: 80px;";

  @Input()
  style: string = "margin-left: 0px; margin-right: 0px;";

  showTitle: boolean;

  constructor() { }

  ngOnInit(): void {
    this.showTitle = !this.title && !isUndefined(this.title);
  }

}
