export class DgCustomButton {

  constructor(label: string, funcao: Function, clazz: string, event?: string, validacao?: Function) {
    this.label = label;
    this.funcao = funcao;
    this.clazz = clazz;
    this.event = event;
    this.validacao = validacao;
  }

  public label: string;

  public funcao: Function;

  public clazz: string;

  public event: string;

  public validacao: Function;
}
