import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DgActionbarModule } from './dg-actionbar/dg-actionbar.module';
import { DgDataTableModule } from './dg-data-table/dg-data-table.module';
import { DgSidebarModule } from './dg-template/dg-sidebar/dg-sidebar.module';
import { DgTemplateModule } from './dg-template/dg-template.module';
import { DgReceivedAvisoModule } from './dg-received-aviso/dg-received-aviso.module';
import { MatMenuModule } from '@angular/material/menu';
import { DgButtonComponent } from './dg-button/dg-button.component';
import { DgModalComponent } from "./dg-modal/dg-modal/dg-modal.component";

@NgModule({
    imports: [
        DgDataTableModule,
        DgTemplateModule,
        DgActionbarModule,
        DgSidebarModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatCardModule,
        MatButtonModule,
        MatToolbarModule,
        MatInputModule,
        MatSelectModule,
        MatDialogModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatInputModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        FormsModule,
        CommonModule,
        DgReceivedAvisoModule,
        MatMenuModule
    ],
  exports: [
    DgActionbarModule,
    DgDataTableModule,
    DgTemplateModule,
    DgSidebarModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatCardModule,
    MatButtonModule,
    MatToolbarModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatInputModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    FormsModule,
    DgReceivedAvisoModule,
    DgButtonComponent,
    DgModalComponent
  ],
  declarations: [DgButtonComponent, DgModalComponent]
})
export class DgSharedModule { }
