import { ColumnType } from "src/app/components/dg-shared/dg-data-table/enum/column-type";
import { FilterType } from "src/app/components/dg-shared/dg-data-table/enum/filter-type";
import { OperatorType } from "src/app/components/dg-shared/dg-data-table/enum/operator-type";
import { DgFilterField, DgTableColumn, DgTableEntity } from "src/app/components/dg-shared/dg-data-table/model/DgTableEntity";
import { CategoriaPipe } from "../pipe/categoria.pipe";
import { CpfCnpjPipe } from "../pipe/cpf-cnpj.pipe";
import { StatusSeloPipe } from "../pipe/status-selo.pipe";

export class CadastroSeloEntity extends DgTableEntity {

    constructor(
        id?: number, 
        protocolo?: string,
        modalidade?: string, 
        dataInclusao?: Date,  
        razaoSocial?: string,
        cpfCnpj?: string, 
        nome?: string,
        sobrenome?: string,
        nomeRepresentante?: string,
        cpfRepresentante?: string,
        email?: string,
        emailRepresentante?: string,
        telefone?: string,
        cep?: string,
        uf?: string,
        municipio?: string,
        bairro?: string,
        logradouro?: string,
        numero?: string,
        complemento?: string,  
        status?: string,  
        dataCancelamento?: Date,
        consideracoesSetor?: {consideracao?: string, nomeUsuarioInclusao?: string, dataInclusao?: Date, id?: number}[],
        documentos?: {id?: number, chave?: string, arquivo?: string,  nome?: string, nomeArquivo?: string}[],         
        propriedades?: {identificacao?: string, car?: string, atividade?: string, licencas?: string, outorga?: string}[],
        empreendimentos?: {empreendimento?: string, atividade?: string, licencas?: string, outorga?: string}[]  
        ) {
        super();
        this.id = id;
        this.protocolo = protocolo;
        this.modalidade = modalidade; 
        this.dataInclusao = dataInclusao;
        this.razaoSocial = razaoSocial;
        this.cpfCnpj = cpfCnpj; 
        this.nome = nome;
        this.sobrenome = sobrenome;
        this.nomeRepresentante = nomeRepresentante;
        this.cpfRepresentante = cpfRepresentante;
        this.email = email;
        this.emailRepresentante  = emailRepresentante ;
        this.telefone = telefone;
        this.cep = cep;
        this.uf = uf;
        this.municipio = municipio;
        this.bairro = bairro;
        this.logradouro = logradouro;
        this.numero = numero;
        this.complemento = complemento;           
        this.propriedades = propriedades;
        this.empreendimentos = empreendimentos;
        this.status = status;
        this.documentos = documentos;
        this.dataCancelamento = dataCancelamento;
        this.consideracoesSetor = consideracoesSetor;
    }
    public id: number;

    @DgTableColumn('Protocolo', ColumnType.MOSTRAR_VALOR, null, true)
    @DgFilterField('Protocolo', FilterType.TEXTO, '', null, '', '', 1)
    public protocolo?: string;

    @DgTableColumn('CPF / CNPJ', ColumnType.MOSTRAR_VALOR, CpfCnpjPipe, false)
    @DgFilterField('CPF / CNPJ', FilterType.TEXTO, '', null, '', '', 2)
    public cpfCnpj?: string;

    @DgTableColumn('Categoria', ColumnType.MOSTRAR_VALOR, CategoriaPipe)
    @DgFilterField('Categoria', FilterType.COMBO, 'categoriaFiltro', null, '', '', 3)
    public modalidade?: string; 
    
    @DgTableColumn('Data de adesão', ColumnType.MOSTRAR_DATA)
    @DgFilterField("Data de adesão", FilterType.DATA, '', OperatorType.IGUAL, '', 'dataInclusao', 4)
    public dataInclusao?: Date;

    @DgTableColumn('Data de cancelamento', ColumnType.MOSTRAR_DATA)
    public dataCancelamento?: Date;

    @DgTableColumn('Status do selo', ColumnType.MOSTRAR_VALOR, StatusSeloPipe)
    @DgFilterField('Status do selo', FilterType.COMBO, 'statusFiltro', null, '', '', 5)
    public status?: string;

    public razaoSocial?: string;
    public nome?: string;
    public sobrenome?: string;
    public nomeRepresentante?: string;
    public cpfRepresentante?: string;
    public email?: string;
    public emailRepresentante?: string;
    public telefone?: string;
    public cep?: string;
    public uf?: string;
    public municipio?: string;
    public bairro?: string;
    public logradouro?: string;
    public numero?: string;
    public complemento?: string;
    public consideracoesSetor?: {consideracao?: string, nomeUsuarioInclusao?: string, dataInclusao?: Date, id?: number}[];
    public documentos?: {id?: number, chave?: string, arquivo?: string,  nome?: string, nomeArquivo?: string}[];
    public propriedades?: {identificacao?: string, car?: string, atividade?: string, licencas?: string, outorga?: string}[];
    public empreendimentos?: {empreendimento?: string, atividade?: string, licencas?: string, outorga?: string}[]; 
}
