import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppUtils } from 'src/app/utils/app-utils';
import { RecuperaTokenService } from '../recupera-token.service';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  constructor(private util: AppUtils, protected recuperaToken: RecuperaTokenService,
    protected activatedRoute: ActivatedRoute, private cookieService: CookieService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.util.nameEnvironment == 'ambiente-datagrupo'){
      request = request.clone({
      setHeaders: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': `Bearer ${this.recuperaToken.getToken(this.activatedRoute, this.cookieService)}`
      },
      });

    } else{
        request = request.clone({
            setHeaders: {
            'Access-Control-Allow-Origin': this.util.getBaseUrlWebService(),
            'Authorization': `Bearer ${this.recuperaToken.getToken(this.activatedRoute, this.cookieService)}`
            },
            withCredentials: true
        });

    }

    return next.handle(request);

  }
}
