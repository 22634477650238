import { Pipe, PipeTransform } from '@angular/core';
import { StatusSelo } from 'src/app/enum/status-selo.enum';

@Pipe({
  name: 'statusSelo'
})
export class StatusSeloPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    return StatusSelo[value];
  }

}
