import { HttpHeaders } from '@angular/common/http';
import { AppUtils } from 'src/app/utils/app-utils';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RestReturn } from 'src/app/components/common/rest-return';
import { AbstractEntity } from '../../model/abstract.entity';
import { RecuperaTokenService } from '../recupera-token.service';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

export abstract class AbstractHttpService<T extends AbstractEntity> {
  protected readonly path: string;

  protected constructor(protected http: HttpClient, private context: string, private resource: string,
    protected recuperaToken: RecuperaTokenService, protected activatedRoute: ActivatedRoute,
    protected cookieService: CookieService) {
    this.path = context ? `${context}${resource}` : resource;
  }

  protected findOne(id: number | string, endpoint?: string): Observable<RestReturn<T>> {
    const url = this.addEndpoint(endpoint);
    let httpParam: HttpParams = new HttpParams();
    

    return this.http.get<RestReturn<T>>(`${url}/${id}`, {params: httpParam});
  }

  protected findAll(filtros?: Map<string, any>, sortDirection?: string, pageIndex?: number,
    pageSize?: number, headers?: Map<string, string>, options?: {
    id?: number | string;
    endpoint?: string;
  }): Observable<RestReturn<T>> {
    let url: string;

    if (options) {
      url = this.addEndpoint(options.endpoint);

      if (options.id) {
        url = `${url}/${options.id}`;
      }
    } else {
      url = this.path + "/todos";
    }

    let httpHeaders: HttpHeaders = new HttpHeaders();
    if (headers) {
      headers.forEach((value, key) => {
        httpHeaders = httpHeaders.append(key, value);
      });
    }

    let httpParam: HttpParams = new HttpParams();
    

    if (filtros && filtros.size > 0) {
      httpParam = httpParam.append('filtros', JSON.stringify(AppUtils.convertMap(filtros)));
    }

    if (sortDirection) {
      httpParam = httpParam.append('sortDirection', sortDirection);
    }

    if ((pageIndex || pageIndex >= 0) && (pageSize || pageSize >= 0)) {
      httpParam = httpParam.append('pageIndex', pageIndex.toString()).append('pageSize', pageSize.toString());
    }

    return this.http.get<RestReturn<T>>(url, {params: httpParam, headers: httpHeaders});
  }

  protected save(data: T, endpoint?: string): Observable<RestReturn<T>> {
    const url = this.addEndpoint(endpoint);

    let httpParam: HttpParams = new HttpParams();
    

    return this.http.post<RestReturn<T>>(url, data, {params: httpParam});
  }

  public delete(id?: number | string, ids?: number[] | string[], endpoint?: string): Observable<RestReturn<T>> {
    const url = this.addEndpoint(endpoint);

    let httpParam: HttpParams = new HttpParams();
    

    let idsParaEnviar = [];
    if (id) {
      idsParaEnviar = [id];
    } else {
      idsParaEnviar = ids;
    }

    httpParam = httpParam.append("ids", JSON.stringify(idsParaEnviar));
    return this.http.delete<RestReturn<T>>(`${url}`, {params: httpParam});
  }

  protected update(data: any, endpoint?: string): Observable<RestReturn<T>> {
    const url = this.addEndpoint(endpoint);

    let httpParam: HttpParams = new HttpParams();
    

    return this.http.put<RestReturn<T>>(url, data, {params: httpParam});
  }

  public saveOrUpdate(data: T, endpoint?: string): Observable<RestReturn<T>> {
    if (data.id != null) {
      console.log('update')
      return this.update(data, endpoint);
    } else {
      console.log('save')
      return this.save(data, endpoint);

    }
  }

  protected addEndpoint(endpoint: string): string {
    return endpoint ? `${this.path}${endpoint}` : this.path;
  }

  public pesquisar(id?: number | string, filtros?: Map<string, any>, sortDirection?: string, pageIndex?: number,
    pageSize?: number, headers?: Map<string, string>, options?: {
      id?: number | string;
      endpoint?: string;
    }): Observable<RestReturn<T>> {
    if (id) {
      return this.findOne(id);
    } else {
      return this.findAll(filtros, sortDirection, pageIndex, pageSize, headers, options);
    }
  }
}
