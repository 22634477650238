import {Component, OnInit, Input, HostListener, ChangeDetectorRef, ViewChild} from '@angular/core';

import { ModalCustomButton } from '../ModalCustomButton';

@Component({
  selector: 'dg-modal',
  templateUrl: './dg-modal.component.html',
  styleUrls: ['./_dg-modal.component.scss']
})
export class DgModalComponent implements OnInit {

  config = {
    open: false,
    animate: false, // controla quano animação já foi executada ou não. evita erros de animação
    htmlHeader: false,
    htmlFooter: false,
    zIndex: 800,
    bloqueiaClose: false
  }

  static listaModalAtivo = 800;

  @Input('size') size?: 'sm' | 'md' | 'lg' | 'xl' | 'full' = 'lg';
  @Input('alignV') alignV?: 'top' | 'bottom' | 'center' = 'center';
  @Input('alignH') alignH?: 'left' | 'right' | 'center' = 'center';
  @Input('title') title?: string = "titulo padrão";
  @Input('titleSpan') titleSpan?: string;
  @Input('bgClose') bgClose?: boolean = false;
  @Input('escClose') escClose?: boolean = true;
  @Input() customActionButtons: ModalCustomButton[];

  @HostListener('document:keydown.esc', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.escClose) {
      this.close();
    }
  }

  constructor(private cdRef: ChangeDetectorRef) { }

  @ViewChild('htmlHeader') htmlHeader;
  @ViewChild('htmlFooter') htmlFooter;
  ngAfterViewInit() {
    this.config.htmlHeader = this.htmlHeader.nativeElement && this.htmlHeader.nativeElement.children.length > 0;
    this.config.htmlFooter = this.htmlFooter.nativeElement && this.htmlFooter.nativeElement.children.length > 0;
    this.cdRef.detectChanges();
  }

  public open(configs?: {
    size?: 'sm' | 'md' | 'lg' | 'xl' | 'full',
    title?: string,
    alignV?: 'top' | 'bottom' | 'center',
    alignH?: 'left' | 'right' | 'center',
    bgClose?: boolean,
    escClose?: boolean
  }): void {
    if (this.config.open) return;
    if (configs) {
      if (configs.size) {
        this.size = configs.size;
      }
      if (configs.title) {
        this.config.htmlHeader = false;
        this.title = configs.title;
      }
      if (configs.alignV) {
        this.alignV = configs.alignV;
      }
      if (configs.alignH) {
        this.alignH = configs.alignH;
      }
      if (configs.bgClose) {
        this.bgClose = configs.bgClose;
      }
      if (configs.escClose) {
        this.escClose = configs.escClose;
      }
    }

    this.config.zIndex = DgModalComponent.addOpen();

    this.config.open = true;
  }

  public close() {
    if (!this.config.bloqueiaClose) {
      DgModalComponent.addClose();
      this.config.open = false;
    }
  }

  public closeAll(){
    DgModalComponent.realCloseAll();
  }

  protected static realCloseAll() {

  }


  public bloqueiaClose(){
    this.config.bloqueiaClose = true;
  }

  public liberaClose(){
    this.config.bloqueiaClose = false;
  }

  ngOnInit(): void {
  }

  private static addOpen(){
    DgModalComponent.listaModalAtivo = DgModalComponent.listaModalAtivo + 1;
    return DgModalComponent.listaModalAtivo;
  }

  private static addClose(){
    DgModalComponent.listaModalAtivo = DgModalComponent.listaModalAtivo - 1;
    return DgModalComponent.listaModalAtivo;
  }

  bgClick(){
    if (this.bgClose) {
      this.close();
    }
  }

  executarFuncao(func: Function | null | undefined, event): void {
    if (typeof func === 'function') {
      func();
    }
    if (event) {
      window.dispatchEvent(new CustomEvent(event));
    }
  }
}
