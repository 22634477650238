import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

const DEFAULT_DURATION = 6000;
const DEFAULT_ACTION = 'Fechar';

@Injectable()
export class SnackBarService {

  constructor(private snackBar: MatSnackBar) { }

  customMessage(message: string, action: string, duration: number) {
    this.snackBar.open(message, action, {
      duration: duration,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['sema-snackbar']
    });
  }

  message(message: string) {
    if (message){
      if (message.length > 100){
        message = message.substring(0, 100) + ' ...'
      }
      this.customMessage(message, DEFAULT_ACTION, DEFAULT_DURATION);
    }
  }

  messageFormInvalid() {
    this.customMessage('Preencha os campos obrigatórios', DEFAULT_ACTION, DEFAULT_DURATION);
  }

  messageFormInvalidField(field) {
    this.customMessage(`Preencha o campo ${field}`, DEFAULT_ACTION, DEFAULT_DURATION);
  }

  messageError() {
    this.customMessage('Desculpe, algo de errado aconteceu', DEFAULT_ACTION, DEFAULT_DURATION);
  }

}
