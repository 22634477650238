
//angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

//DG
import { AbstractHttpService } from '../../../../services/http/abstract-http.service';
import { AppUtils } from '../../../../utils/app-utils';
import { RecuperaTokenService } from '../../../../services/recupera-token.service';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class ReceivedAvisoService extends AbstractHttpService<messagesQuadroEntity> {

  constructor(
    protected http:HttpClient,
    protected util:AppUtils,
    protected recuperaToken: RecuperaTokenService,
    protected activatedRoute: ActivatedRoute,
    protected cookieService: CookieService
  ) { 
    super(
      http,
      util.getBaseUrlWebService(),
      '',
      recuperaToken,
      activatedRoute,
      cookieService
    )
  }


  //Verificar se há novas mensagens.
  messagesInQuadroAviso():Observable<messagesQuadroEntity>{

    let url = `${this.util.getBaseUrlWebService()}/api/v1/siga/responsabilizacao/quadro/aviso/possui/avisos`

    let params = new HttpParams().append('token', this.recuperaToken.getToken(this.activatedRoute, this.cookieService));

    return this.http.get<messagesQuadroEntity>(url,{'params': params});
  }



  //Sinalizar ciencia de novas mensagens recebidas
  AwareQuadroAviso():Observable<messagesQuadroEntity>{

    let url = `${this.util.getBaseUrlWebService()}/api/v1/siga/responsabilizacao/quadro/aviso/ciencia`

    let params = new HttpParams().append('token', this.recuperaToken.getToken(this.activatedRoute, this.cookieService));

    return this.http.post<messagesQuadroEntity>(url,[],{'params': params});
  }
}




export class messagesQuadroEntity {
  id:number;
  mensagem: string;
  values: number[];
  ended;
  tipoArquivo;
}
