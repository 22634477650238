import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'byteToSize'
})
export class ByteToSizePipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (value == 0) return '0 Byte';
    var i = Math.floor(Math.log(value) / Math.log(1024));
    return Math.round(value / Math.pow(1024, i)) + ' ' + sizes[i];
  }

}
