import { Injectable } from '@angular/core';

import htmlToPdfmake from 'html-to-pdfmake';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;



/**
 *  Service de transcrição de interface entre aplicação e metodos necessários
 *  para conexão com PDFMake. incluindo biblioteca de terceiro para transcrever
 *  HTML em marcação de estilo do próprio.
 */
@Injectable({
  providedIn: 'root'
})
export class PdfMakeService {

  private paramPage = {};

  constructor() { }

  /**
   * Use esse método para transcrever HTML em marcadores de estilo PDFMake
   * @param value<html>
   */
  // tslint:disable-next-line:no-unnecessary-initializer
  convertToPdf(value: any) {
    return htmlToPdfmake(value);
  }

  // tslint:disable-next-line:max-line-length
  setParamPage(paramPage: {
    footer?: object | [];
    header?: object | [];
    pageMargins?: [number, number, number, number] | [number, number];
    pageSize?: string;
    pageOrientation?: string; 
    background?: any; 
    styles?: any;
    defaultStyle?: any;
    watermark?: any;
  }) {
    this.paramPage = paramPage;
  }

  open(text: any = 'texto do pdf') {

    const documentDefinition = {
      content: text,
      ...this.paramPage
    };

    pdfMake.createPdf(documentDefinition).open();
  }

  print(text: string = 'texto do pdf') {
    const documentDefinition = { content: text };
    pdfMake.createPdf(documentDefinition).print();
  }

  download(text: string = 'texto do pdf') {
    const documentDefinition = { content: text };
    pdfMake.createPdf(documentDefinition).download();
  }

  base64(text: any = 'texto do pdf', callbeck: any ) {
    const documentDefinition = {
      content: text,
      ...this.paramPage
    };

    const base64 = pdfMake.createPdf(documentDefinition);
    base64.getBase64((data) => {
      callbeck(data);
    });
  }
}
