import { Component, OnInit } from '@angular/core';
import { CertificadoService } from './service/certificado.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { cpf, cnpj } from 'cpf-cnpj-validator'; 
import { CertificadoCarbonoNeutroService } from 'src/app/components/certificado-carbono-neutro/certificado-carbono-neutro.service';
import { FormCarbonoNeutroService } from 'src/app/components/form-carbono-neutro/form-carbono-neutro.service';
import { CategoriaPipe } from '../cadastro-selo/pipe/categoria.pipe';
import { CategoriaSelo } from 'src/app/enum/categoria-selo.enum';
import { url } from 'inspector';
import { AppUtils } from 'src/app/utils/app-utils';

const { version } = require('./../../../../package.json');

@Component({
  selector: 'app-certificado',
  templateUrl: './certificado.component.html',
  styleUrls: ['./certificado.component.scss']
})
export class CertificadoComponent implements OnInit {

  controlForm = null;

  certificadosLocalizados = [];

  formPesquisa: FormGroup;

  categoriaSelo = CategoriaSelo;

  urlFront;

  version = version;

  constructor(private service: CertificadoService, private alert: SnackBarService, private dataPipe: DatePipe,
    protected dialog: MatDialog, private certificadoService: CertificadoCarbonoNeutroService,
    private formService: FormCarbonoNeutroService, private util: AppUtils) { }

  ngOnInit(): void {
    this.formPesquisa = new FormGroup({
      cpfCnpj: new FormControl('', [Validators.required, this.cpfValidador()]),
      protocolo: new FormControl('', Validators.required),
    })

    this.urlFront = this.util.getUrlFront();
  }

  scrollElementId(id){
    setTimeout(() => {
      var pageElement = document.getElementById(id) as any;
      if (pageElement){
        pageElement.scrollIntoView(); 
      }
    }, 200)
  }

  abrirCertificado(certidicado: {nome: string, cidade: string, status: string, cpfCnpj: string, empreendimentos: any[], propriedades: any[], modalidade: string, protocolo: string, id: number, hash: string}){
    let urlQrcode = `${this.urlFront}/#/validar/${certidicado.id}/${certidicado.hash}  `
    console.log(urlQrcode);
    
    if(certidicado.status == 'OUTORGADO'){
      this.certificadoService.open(certidicado.nome, certidicado.cpfCnpj, certidicado.modalidade,
         certidicado.cidade, certidicado.empreendimentos, certidicado.propriedades, urlQrcode);
    } else{
      this.alert.message('Erro ao tentar criar certificado')
    }

    /* if (certidicado.modalidade == 'Financiador'){
      this.certificadoService.openFinanciador(certidicado.nome,
      'Investimento de R$500.000,00 (Quinhentos mill reais) no projeto SOS Amazônia.',
      urlQrcode);

    } else if (certidicado.modalidade == 'Apoiador'){
      this.certificadoService.openApoiador(certidicado.nome,
      'Divulgação do Programa Carbono Neutro MT no Congresso Brasileiro de Reflorestamanto Ambiental.',
      urlQrcode);

    } else if (certidicado.modalidade == 'Compromissário'){
      this.certificadoService.openCompromissario(certidicado.nome,
      'Neutralizou as emissões de 7,89 ton de CO2.',
      urlQrcode);

    } else{
      this.alert.message('Erro ao tentar criar certificado')
    } */
    
  }

  abrirCertificadoEn(certidicado: {nome: string, cidade: string, status: string, cpfCnpj: string, empreendimentos: any[], propriedades: any[], modalidade: string, protocolo: string, id: number, hash: string}){
    let urlQrcode = `${this.urlFront}/#/validar/${certidicado.id}/${certidicado.hash}  `
    console.log(urlQrcode);
    
    if(certidicado.status == 'OUTORGADO'){
      this.certificadoService.openEn(certidicado.nome, certidicado.cpfCnpj, certidicado.modalidade,
        certidicado.cidade, certidicado.empreendimentos, certidicado.propriedades, urlQrcode);
    } else{
      this.alert.message('Erro ao tentar criar certificado')
    }
    
  }

  localizarCertificado(){
    if (this.formPesquisa.valid){
      let protocolo = document.getElementById('protocolo') as any;
      this.certificadosLocalizados = [];
      const filtros: Map<string, any> = new Map();
      //filtros.set('nomeRazao', this.formPesquisa.controls['nomeRazao'].value);
      filtros.set('cpfCnpj', this.formPesquisa.controls['cpfCnpj'].value);
      filtros.set('protocolo', protocolo.value);

      this.service.buscarCertificado(filtros).subscribe(resp => {
        if(resp.values){
          if (resp.values.length > 0){
            let nome = resp.values[0].razaoSocial ? resp.values[0].razaoSocial : `${resp.values[0].nome} ${resp.values[0].sobrenome}`
            this.certificadosLocalizados.push(
              {nome: nome,
               cidade: resp.values[0].municipio + '/'+ resp.values[0].uf,
               cpfCnpj: resp.values[0].cpfCnpj,
               propriedades: resp.values[0].propriedades,
               empreendimentos: resp.values[0].empreendimentos,
               status: resp.values[0].status,
               modalidade: this.categoriaSelo[resp.values[0].modalidade],
               protocolo: resp.values[0].protocolo,
               id: resp.values[0].id,
               hash: resp.validacao}
            );
          } else{
            this.alert.message('Nenhum selo localizado')
          }
        } else{
          this.alert.message('Nenhum selo localizado')
        }
      }, error => this.alert.message(error.error.mensagem))

    } else {
      this.alert.message('Preencha todos os campos');
      this.formPesquisa.markAllAsTouched();
    }
  }

  cpfValidador(): ValidatorFn{
    return (control:AbstractControl) : ValidationErrors | null => {
      let num = control.value;
      if (num.length == 11){
        return !cpf.isValid(num) ? {cpfValid:true}: null;
      } else if (num.length == 14){
        return !cnpj.isValid(num) ? {cpfValid:true}: null;
      } else{
        return null
      }
    }
  }
}
