import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) { }

  public transform(value: any, type: string): any {
      const replaced = this.replaceAngularJs(value);
      return this.sanitizer.bypassSecurityTrustHtml(replaced);
  }

  public replaceAngularJs(value: any): string {
    const replaced = value.replaceAll('ng-if', '*ngIf')
    .replaceAll('ng-show="', '[hidden]="!')
    .replaceAll('ng-hide', '[hidden]');

    return replaced;
  }
}
