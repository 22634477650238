import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DgDataTableDataSource } from './datasource/dg-data-table.datasource';
import { ColumnType } from './enum/column-type';
import { DgCustomButton } from './model/DgCustomButton';
import { AppUtils } from 'src/app/utils/app-utils';
import { Component, Input, OnInit, HostListener, ViewChild, AfterViewInit, AfterContentInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DgFilter, DgTableEntity, DgTableField } from './model/DgTableEntity';
import { DatePipe, Location } from '@angular/common';
import { AbstractHttpService } from 'src/app/services/http/abstract-http.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { MatPaginator } from '@angular/material/paginator';
import { tap } from 'rxjs/operators';
import { DgConfirmDialog } from '../dg-confirm-dialog/dg-confirm-dialog.component';
import { RecuperaTokenService } from 'src/app/services/recupera-token.service';
import { FilterType } from './enum/filter-type';
import { ComboBoxValue } from './abstract/dg-combo-box-value';

import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import { HttpParams } from '@angular/common/http';


@Component({
  selector: 'dg-data-table',
  templateUrl: './dg-data-table.component.html',
  styleUrls: ['./dg-data-table.component.css'],
  //Provider util para introduzir locale e disponibilizar digitalização da data.
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    }
  ],
})
export class DgDataTableComponent implements OnInit, AfterViewInit, AfterContentInit {

  public searchFields: Map<string, DgFilter>;
  public fields: string[];
  public tableColumns: Map<string, DgTableField>;
  public columns: string[];

  @Input()
  tableName: string;

  @Input()
  entityMapping: DgTableEntity;

  @Input()
  customMiddleLeftButtons: DgCustomButton[];

  @Input()
  customMiddleRightButtons: DgCustomButton[];

  @Input()
  customBottonLeftButton: DgCustomButton[];

  @Input()
  customBottonRightButton: DgCustomButton[];

  @Input()
  customActionTableButtons: DgCustomButton[];

  @Input()
  insertNewButton: boolean = true;

  @Input()
  editButton: boolean = true;

  @Input()
  editAction: boolean = true;

  @Input()
  editButtonLabel = 'Editar';

  @Input()
  deleteButton: boolean = true;

  @Input()
  footerdeleteButton: boolean = true;

  @Input()
  filterButton: boolean = true;

  @Input()
  cleanButton: boolean = true;

  @Input()
  actionMenu: boolean = true;

  @Input()
  actionMenuLabel: string = "Ações";

  @Input()
  dateFormat: string = 'dd/MM/yyyy H:mm:ss';

  @Input()
  service: AbstractHttpService<any>;

  @Input()
  url: string;

  searchForm: FormGroup;

  @Input()
  searchFilter: boolean = true;

  @Input()
  fazerPrimeiraPesquisa = true;

  @Input()
  usaFiltros = true;

  @Input()
  numMaximoCaracter = 140;

  selecionarTodos = false;

  deletarTodos = false;

  @Input()
  showTable: boolean = true;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @Input()
  processBeforeConsume?: Function;

  @Input()
  endpointOptions;

  @Input()
  comboBoxValues: Map<string, ComboBoxValue[]>;

  @Input()
  id;

  @Input()
  changeToEditStateCustomEvent: string;

  @Input()
  checkBoxMultiSelection: boolean = true;

  @Input()
  changeTableEvent = null;

  ultimoFiltro;

  public dataSource: DgDataTableDataSource;

  private exibirSelectForDeleteColumn: boolean = false;

  constructor(private router: Router, private utils: AppUtils, private datePipe: DatePipe, private location: Location,
    private alert: SnackBarService, private dialog: MatDialog, protected route: ActivatedRoute,) {

  }

  ngOnInit(): void {
    this.searchFields = this.getFields();
    if (this.utils.isNullOrUndefined(this.searchFields) || this.searchFields.size <= 0) {
      this.searchFilter = false;
    } else {
      this.fields = this.getFieldsOrdered();
    }
    this.tableColumns = this.getColumnsNames();
    if (this.actionMenu) {
      this.adicionarActionButton();
    } else {
      this.removerActionButton();
    }
    this.initFormControl();
  }

  getFieldsOrdered(): string[] {
    const keys = Array.from(this.searchFields.keys());
    return keys.sort((keyOne, keyTwo) => {
      if (this.getFieldOrder(keyOne) > this.getFieldOrder(keyTwo)) {
        return 1;
      } else if (this.getFieldOrder(keyOne) < this.getFieldOrder(keyTwo)) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  getFieldOrder(key): number {
    const field: DgFilter = this.searchFields.get(key);
    if (field.ordem) {
      return field.ordem;
    } else {
      return 0;
    }
  }

  getFieldonfig(key): DgFilter {
    return this.searchFields.get(key);
  }

  removerActionButton() {
    this.tableColumns.delete(ColumnType.TABLE_ACTION_COLUMN);
  }

  adicionarActionButton() {
    this.tableColumns.set(ColumnType.TABLE_ACTION_COLUMN, new DgTableField(this.actionMenuLabel, ColumnType.TABLE_ACTION_COLUMN, '', false, '', false));
  }

  ngAfterContentInit() {
    this.dataSource = new DgDataTableDataSource(this.service, this.alert, this.processBeforeConsume, this.endpointOptions);
  }

  ngAfterViewInit(getFiltros = true) {
    this.dataSource = new DgDataTableDataSource(this.service, this.alert, this.processBeforeConsume, this.endpointOptions);
    setTimeout(() => {
      this.paginator.page
        .pipe(
          tap(() => {
            this.popularTabela(null, this.id, this.ultimoFiltro);
          })
        )
        .subscribe();
        if (this.fazerPrimeiraPesquisa) {
          this.popularTabela(null, this.id);
        } else{
          if (getFiltros && this.usaFiltros){
            this.getFiltrosUrl()
          }
        }
    })
  }

  @HostListener('window:atualizar-tabela-event', ['$event'])
  popularTabela(event?, id?: number, filtros?: Map<string, any>): void {
    let nomeTabela: string;
    let ultimoFiltro;
    if (event) {
      filtros = event.detail.filtros;
      id = event.detail.id? event.detail.id : this.id;
      nomeTabela = event.detail.nomeTabela? event.detail.nomeTabela : null;
      ultimoFiltro = event.detail.ultimoFiltro? event.detail.ultimoFiltro : false;
      if (ultimoFiltro){
        filtros = this.ultimoFiltro;
      }
    } else{
      id = this.id;
    }
    if (nomeTabela){
      if (nomeTabela == this.tableName){
        this.dataSource.pesquisar(id, filtros, '', this.paginator.pageIndex, this.paginator.pageSize);
        this.ultimoFiltro = filtros;
      }
      if (this.changeTableEvent){
        window.dispatchEvent(new CustomEvent(this.changeTableEvent, {detail: {table: this.dataSource}}));
      }
    } else{
      this.dataSource.pesquisar(id, filtros, '', this.paginator.pageIndex, this.paginator.pageSize);
      this.ultimoFiltro = filtros;
      if (this.changeTableEvent){
        window.dispatchEvent(new CustomEvent(this.changeTableEvent, {detail: {table: this.dataSource}}));
      }
    }
  }

  private getColumnsNames() {
    if (this.entityMapping) {
      return this.entityMapping.tableColumns;
    }
  }

  private getFields() {
    if (this.entityMapping) {
      return this.entityMapping.searchFields;
    }
  }

  private initFormControl() {
    if (this.searchFilter) {
      const formControls = {};
      this.searchFields.forEach((value, key) => {
          formControls[key] = new FormControl(null);
      });
      this.searchForm = new FormGroup(formControls);
    }

    this.columns = [];
    this.tableColumns.forEach((value, key) => {
      this.columns.push(key);
    });
  }

  changeToEditState(entity) {
    if (this.editAction) {
      if (this.changeToEditStateCustomEvent) {
        window.dispatchEvent(new CustomEvent(this.changeToEditStateCustomEvent, {detail: {selectedItem: entity}}));
      } else {
        this.router.navigate([this.url, entity['id']]);
      }
    }
  }

  search() {
    var url = this.router.url.split('?filtros')[0];
    if (this.filterButton && this.searchFilter && this.searchFields) {
      const filtros: Map<string, any> = new Map();
      this.searchFields.forEach((value, key) => {
        const inputValue = this.searchForm.get(key).value;
        if (inputValue) {
          if (value.tipoCampo == FilterType.DATA) {
            filtros.set(value.operador + '$' + value.formato + '$' + value.campoPesquisa, inputValue);
          } else if (value.tipoCampo == FilterType.NUMBER) {
            filtros.set(value.operador + '$' + value.campoPesquisa, inputValue);
          }  else {
            if (value.campoPesquisa && value.campoPesquisa.length > 0) {
              filtros.set(value.campoPesquisa, inputValue);
            } else {
              filtros.set(key, inputValue);
            }
          }
        }
      });
      this.location.replaceState(`${url}` + `?filtros=${this.utils.stringifyMap(filtros)}`.replace('{', '%7B').replace('}', '%7D'));
      if (filtros.size != 0){
        this.popularTabela(null, null, filtros);
      }
    }
  }

  getFiltrosUrl(filtroFazio=false){
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length > 0 || filtroFazio){
        var filtrosUrl = JSON.parse(params['filtros']);
        const filtros: Map<string, any> = new Map();
        Object.keys(filtrosUrl).forEach(key => {
          const inputValue = filtrosUrl[key];
          if (inputValue) {
            filtros.set(key, inputValue)
          }
        });
        if (filtros.size != 0 || filtroFazio){
          this.popularTabela(null, null, filtros);
        }
      }
    });
  }

  deleteSelectedItem(entityId: number) {
    if (this.deleteButton) {
      if (entityId) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '20%';
        dialogConfig.data = {
          titulo: 'Confirmar Exclusão',
          conteudo: 'Deseja mesmo deletar o item selecionado'
        };
        const dialogRef = this.dialog.open(DgConfirmDialog, dialogConfig);
        dialogRef.afterClosed().subscribe(podeDeletar => {
          if (podeDeletar) {
            this.service.delete(entityId).subscribe(resultado => {
              this.popularTabela(null, this.id);
              this.alert.message(resultado.mensagem);
            });
          }
        });
      } else if (this.possuiDeletados()) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '20%';
        dialogConfig.data = {
          titulo: 'Confirmar Exclusão',
          conteudo: 'Deseja mesmo deletar os itens selecionados'
        };
        const dialogRef = this.dialog.open(DgConfirmDialog, dialogConfig);
        dialogRef.afterClosed().subscribe(podeDeletar => {
          if (podeDeletar) {
            const deletados: number[] = this.pegarDeletados();
            this.service.delete(null, deletados).subscribe(resultado => {
              this.showSelectForDeleteColumn();
              this.popularTabela(null, this.id);
              this.alert.message(resultado.mensagem);
            },
            err => this.alert.message(err.error.mensagem));
          }
        });
      } else {
        this.showSelectForDeleteColumn();
      }
    }
  }

  pegarDeletados(): number[] {
    let deletados = [];
    if (this.dataSource && this.dataSource.getData() && this.dataSource.getData().length > 0) {
      this.dataSource.getData().forEach(value => {
        if (value['marcadoParaDeletar']) {
          deletados.push(value['id']);
        }
      });
    }
    return deletados;
  }

  showSelectForDeleteColumn() {
    this.exibirSelectForDeleteColumn = !this.exibirSelectForDeleteColumn;
    if (this.exibirSelectForDeleteColumn) {
      this.tableColumns.set(ColumnType.TABLE_SELECT_REMOVE_COLUMN, new DgTableField('Todos', ColumnType.TABLE_SELECT_REMOVE_COLUMN, '', false, '', false));
    } else {
      this.tableColumns.delete(ColumnType.TABLE_SELECT_REMOVE_COLUMN);
      this.limparSelecoes();
    }
    this.initFormControl();
  }

  limparSelecoes() {
    if (this.dataSource && this.dataSource.getData()) {
      this.dataSource.getData().forEach(tableValue => {
        tableValue['marcadoParaDeletar'] = false;
      });
    }
  }

  resetData() {
    if (this.cleanButton && this.searchFilter) {
      this.searchForm.reset();
    }
    // this.popularTabela(null, this.id);
    this.ngAfterViewInit(false)
  }

  changeToInsertState() {
    if (this.insertNewButton) {
      this.router.navigate([this.url, 'Novo']);
    }
  }

  formatTableValue(value: any, tipo: string): any {
    if (ColumnType.MOSTRAR_DATA === tipo) {
      return this.datePipe.transform(value, this.dateFormat);
    } else if (ColumnType.MOSTRAR_BOOLEAN === tipo) {
      return value ? 'Sim' : 'Não';
    } else if (ColumnType.HTML_COLUMN === tipo) {
      return value;
    } else {
      try{
        value = value.length > this.numMaximoCaracter ? value.substring(0, this.numMaximoCaracter) + '...' : value; 
      }catch{}
      
      return value;
    }
  }

  mostrarValor(tipo: string) {
    return tipo === ColumnType.MOSTRAR_VALOR || tipo === ColumnType.MOSTRAR_DATA ||
    tipo === ColumnType.MOSTRAR_BOOLEAN || tipo === ColumnType.HTML_COLUMN;
  }

  mostrarCheckbox(tipo: string) {
    return tipo === ColumnType.MOSTRAR_CHECKBOX;
  }

  mostrarActionMenu(tipo: string) {
    return tipo === ColumnType.TABLE_ACTION_COLUMN;
  }

  mostrarSelectRemoveColumn(tipo: string) {
    return tipo === ColumnType.TABLE_SELECT_REMOVE_COLUMN;
  }

  mostrarValorHtml(tipo: string) {
    return tipo === ColumnType.HTML_COLUMN;
  }

  alterarValorBooleano(value, key) {
    value[key] = !value[key];

    if (this.dataSource && !this.checkBoxMultiSelection) {
      this.dataSource.getData().forEach(tableValue => {
        if (value != tableValue){
          tableValue[key] = false
        }
      });
    } 

    let todosSelecionados = true;
    if (this.dataSource && this.dataSource.getData()) {
      this.dataSource.getData().forEach(tableValue => {
        if (!tableValue[key]) {
          todosSelecionados = false;
        }
      });
    }
    this.selecionarTodos = todosSelecionados;
  }

  marcarParaDeletar(value) {
    value['marcadoParaDeletar'] = !value['marcadoParaDeletar'];

    let todosSelecionados = true;
    if (this.dataSource && this.dataSource.getData()) {
      this.dataSource.getData().forEach(tableValue => {
        if (!tableValue['marcadoParaDeletar']) {
          todosSelecionados = false;
        }
      });
    }
    this.deletarTodos = todosSelecionados;
  }

  selecionarTodosParaDeletar() {
    this.deletarTodos = !this.deletarTodos;
    if (this.dataSource) {
      this.dataSource.getData().forEach(value => value['marcadoParaDeletar'] = this.deletarTodos);
    }
  }

  todosMarcadosParaDeletar(): boolean {
    return this.deletarTodos;
  }

  possuiDeletados(): boolean {
    let possuiDeletados = false;
    if (this.dataSource && this.dataSource.getData() && this.dataSource.getData().length > 0) {
      this.dataSource.getData().forEach(value => {
        if (value['marcadoParaDeletar']) {
          possuiDeletados = true;
        }
      });
    }
    return possuiDeletados;
  }

  selecionarTodosItens(key) {
    this.selecionarTodos = !this.selecionarTodos;
    if (this.dataSource) {
      this.dataSource.getData().forEach(value => value[key] = this.selecionarTodos);
    }
  }

  isAllSelect(): boolean {
    return this.selecionarTodos;
  }

  possuiSelecao(key): boolean {
    let possuiSelecao = false;
    if (this.dataSource && this.dataSource.getData() && this.dataSource.getData().length > 0) {
      this.dataSource.getData().forEach(value => {
        if (value[key]) {
          possuiSelecao = true;
        }
      });
    }
    return possuiSelecao;
  }

  buttonValid(botao, selectedRow){
    if (botao.validacao){
      return botao.validacao.call(this, selectedRow, this.dataSource.getData(), this.service);
    } else{
      return false;
    }
  }

  executarFuncao(botao, selectedRow) {
    if (botao.funcao) {
      botao.funcao.call(this, selectedRow, this.dataSource.getData(), this.service);
    } else if (botao.event) {
      window.dispatchEvent(new CustomEvent(botao.event, {detail: {selectedRow: selectedRow}}));
    }
  }

  mostrarFiltroTexto(tipoCampo) {
    return FilterType.TEXTO === tipoCampo;
  }

  mostrarFiltroCombo(tipoCampo) {
    return FilterType.COMBO === tipoCampo;
  }

  mostrarFiltroData(tipoCampo) {
    return FilterType.DATA === tipoCampo;
  }

  obterValoresCombo(functionValores) {
    functionValores.call(this);
  }

  obterValoresFiltroCombo(key) {
    return this.comboBoxValues.get(key);
  }
}
