import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DgActionbarComponent } from './dg-actionbar.component';
import { DgActionbuttonComponent } from './dg-actionbutton/dg-actionbutton.component';
import { DgCardModule } from '../dg-card/dg-card.module';



@NgModule({
  declarations: [DgActionbarComponent, DgActionbuttonComponent],

  imports: [
    CommonModule,
    DgCardModule
  ],
  exports: [DgActionbarComponent, DgActionbuttonComponent]
})
export class DgActionbarModule { }
