import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";
import { RestReturn } from "src/app/components/common/rest-return";
import { RecuperaTokenService } from "src/app/services/recupera-token.service";
import { AppUtils } from "src/app/utils/app-utils";

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  protected readonly path: string;

  constructor(protected http: HttpClient, private util: AppUtils, protected cookieService: CookieService, protected recuperaToken: RecuperaTokenService, protected activatedRoute: ActivatedRoute) {
    this.path = util.getBaseUrlWebService() + `/api/v1/siga/autuacao/obter/versao/sistema`;
  }

  public buscarVersao(): Observable<RestReturn<any>> {
    const url = this.path;
    let httpParam: HttpParams = new HttpParams();
    

    return this.http.get<RestReturn<any>>(`${url}`, { params: httpParam });
  }

  public buscarEndereco(cep: string): Observable<any> {
    const url = `https://viacep.com.br/ws/${cep}/json`;

    return this.http.get<any>(`${url}`);
  }

  public buscarCEP(cep: string): Observable<RestReturn<any>> {
    const url = this.util.getBaseUrlWebService() + `/api/v1/carbono/neutro/validar/cep`;

    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("cep", cep);

    return this.http.get<RestReturn<any>>(`${url}`, { params: httpParam });
  }

  public buscasProtocolo(): Observable<RestReturn<any>> {
    const url = this.util.getBaseUrlWebService() + `/api/v1/carbono/neutro/buscar/protocolo`;

    return this.http.get<RestReturn<any>>(`${url}`);
  }

  public enviarCadastro(cadastro): Observable<RestReturn<any>> {
    const url = this.util.getBaseUrlWebService() + `/api/v1/carbono/neutro/cadastrar/requisicao`;

    return this.http.post<RestReturn<any>>(`${url}`, cadastro);
  } 

  public validarRecaptcha(response: string): Observable<RestReturn<any>> {
    const url = this.util.getBaseUrlWebService() + `/api/v1/carbono/neutro/validar/requisicao`;

    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("secret", "6LeFAC8fAAAAAMPNhDv7k3KMUsJWKFqapy-PFqhe");
    httpParam = httpParam.append("response", response);

    return this.http.post<RestReturn<any>>(`${url}`, null, { params: httpParam });
  } 

  public validarHrecaptcha(response: string): Observable<RestReturn<any>> {
    const url = this.util.getBaseUrlWebService() + `/api/v1/carbono/neutro/validar/requisicao`;

    let httpParam: HttpParams = new HttpParams();
    //httpParam = httpParam.append("secret", "0xf262EA5d94707FcdC448b3C053b24C85620F634e");
    httpParam = httpParam.append("response", response);

    return this.http.post<RestReturn<any>>(`${url}`, null, { params: httpParam });
  } 

  public checkRecaptcha(response: string): Observable<any> {
    const url = "https://www.google.com/recaptcha/api/siteverify";

    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("secret", "6LeFAC8fAAAAAMPNhDv7k3KMUsJWKFqapy-PFqhe");
    httpParam = httpParam.append("response", response);

    return this.http.post<any>(`${url}`, null, { params: httpParam });
  } 

  public checkHrecaptcha(response: string): Observable<any> {
    const url = "https://hcaptcha.com/siteverify";

    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("secret", "0xf262EA5d94707FcdC448b3C053b24C85620F634e");
    httpParam = httpParam.append("response", response);

    return this.http.post<any>(`${url}`, null, { params: httpParam });
  }

}
