import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DgConfirmDialog } from 'src/app/components/dg-shared/dg-confirm-dialog/dg-confirm-dialog.component';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { ConfiguracaoEntity } from '../model/configuracao.entity';
import { ConfiguracaoService } from '../service/configuracao.service';

@Component({
  selector: 'app-configuracao-insert',
  templateUrl: './configuracao-main.component.html',
  styleUrls: ['./configuracao-main.component.css']
})
export class ConfiguracaoMainComponent implements OnInit {
  entity = new ConfiguracaoEntity();
  campoEmail: string = '';
  montarTela: boolean = false;

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  constructor(
    private service: ConfiguracaoService,
    protected alert: SnackBarService,
    protected dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    var id: number = 1
    window.dispatchEvent(new CustomEvent('hide-header'));
    window.dispatchEvent(new CustomEvent('show-loading'));
    
    this.service.pesquisar(id).subscribe(rest => {
      console.log(rest);
      this.montarTela = true;
      this.entity = rest.values[0];
      this.montarTela = true;
      window.dispatchEvent(new CustomEvent('hide-loading'));

    });
    if (this.entity.emailsComunicadoCadastro) {
      this.entity.emailsComunicadoCadastro = [];
    }
  }

  adicionaEmail() {
    if (this.campoEmail == "") {
      this.alert.message("O campo de email está vazio");
      return false;
    } else {
      this.entity.emailsComunicadoCadastro.push(this.campoEmail);
      this.campoEmail = "";
      this.emailFormControl.markAsUntouched();
    }
  }

  removerLinhas(id) {
    const dialogRef = this.dialog.open(DgConfirmDialog, { data: { conteudo: 'Deseja realmente excluir este E-mail? Esta ação é permanente.', titulo: 'Excluir E-mail' } });
    dialogRef.afterClosed().subscribe(podeDeletar => {
      if (podeDeletar) {
        this.entity.emailsComunicadoCadastro = this.entity.emailsComunicadoCadastro.filter((item) => {
          return item !== id;
        })
        this.service.saveOrUpdate(this.entity).subscribe()
      }
    })
  }

  salvar() {
    if (this.entity.emailsComunicadoCadastro.length <= 0) {
      this.alert.message("Adicione no mínimo um e-mail");
      return false;
    } else {
      this.service.saveOrUpdate(this.entity).subscribe(resp => {
        this.alert.message(resp.mensagem);
      }, (err) => {
        this.alert.message(err.error.mensagem);
      });
    }
  }
}



