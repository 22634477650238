import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { CadastroSeloInsertComponent } from './pages/cadastro-selo/cadastro-selo-insert/cadastro-selo-insert.component';
import { CadastroSeloMainComponent } from './pages/cadastro-selo/cadastro-selo-main/cadastro-selo-main.component';
import { CarbonoNeutroComponent } from './pages/carbono-neutro/carbono-neutro.component';
import { CertificadoComponent } from './pages/certificado/certificado.component';
import { ConfiguracaoMainComponent } from './pages/configuracao/configuracao-main/configuracao-main.component';
import { HomeComponent } from './pages/home/home.component';
import { ValidarComponent } from './pages/validar/validar.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full'},

  { path: 'home', component: HomeComponent },

  { path: 'certificado', component: CertificadoComponent },

  { path: 'validar/:id/:hash', component: ValidarComponent },

  { path: 'cadastro', component: CarbonoNeutroComponent },

  { path: 'configuracao', data: { breadcrumb: 'Configuração' }, component: ConfiguracaoMainComponent },

  { path: "selos", data: { breadcrumb: 'Programa Carbono Neutro MT' }, component: CadastroSeloMainComponent },
  {
    path: 'selos', data: { breadcrumb: 'Programa Carbono Neutro MT' },
    children: [
      { path: ':id', component: CadastroSeloInsertComponent, data: { breadcrumb: 'INSERIR & EDITAR' } },
    ]
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      useHash: true
    }),
    SweetAlert2Module.forRoot(),
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [
    RouterModule,
  ]
})
export class AppRoutingModule { }
