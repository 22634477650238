import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CertificadoCarbonoNeutroService } from 'src/app/components/certificado-carbono-neutro/certificado-carbono-neutro.service';
import { CategoriaSelo } from 'src/app/enum/categoria-selo.enum';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { AppUtils } from 'src/app/utils/app-utils';
import { ValidarService } from './service/validar.service';

const { version } = require('./../../../../package.json');

@Component({
  selector: 'app-validar',
  templateUrl: './validar.component.html',
  styleUrls: ['./validar.component.scss']
})
export class ValidarComponent implements OnInit {

  certificadoLocalizado;
  certificadoLocalizados = [];
  categoriaSelo = CategoriaSelo;

  version = version;

  urlFront;

  constructor(protected route: ActivatedRoute, protected alert: SnackBarService, private util: AppUtils,
     private validarService: ValidarService, private certificadoService: CertificadoCarbonoNeutroService) { }

  ngOnInit(): void {
    this.getIdFromRoute();
    this.urlFront = this.util.getUrlFront();
  }


  getIdFromRoute() {
    window.dispatchEvent(new CustomEvent('show-loading'));

    this.route.params.subscribe(params => {
      const id = params['id'];
      const hash = params['hash'];
      this.validarService.validarCertificado(id, hash).subscribe(resp => {
        this.certificadoLocalizado = false;
        window.dispatchEvent(new CustomEvent('hide-loading'));

        if(resp.values){
          if (resp.values.length > 0){
            let nome = resp.values[0].razaoSocial ? resp.values[0].razaoSocial : `${resp.values[0].nome} ${resp.values[0].sobrenome}`
            
            this.certificadoLocalizados.push(
              {nome: nome,
                cidade: resp.values[0].municipio + '/'+ resp.values[0].uf,
                cpfCnpj: resp.values[0].cpfCnpj,
                propriedades: resp.values[0].propriedades,
                empreendimentos: resp.values[0].empreendimentos,
                status: resp.values[0].status,
                modalidade: this.categoriaSelo[resp.values[0].modalidade],
                protocolo: resp.values[0].protocolo,
                id: resp.values[0].id,
                hash: hash}
            );

            if (resp.values[0].status == 'OUTORGADO'){
              this.certificadoLocalizado = true;
            }
            
          } 
        }
      }, error => {
        window.dispatchEvent(new CustomEvent('hide-loading'));
        this.alert.message(error.error.mensagem);
      })
    }, error => this.alert.message(error.error.mensagem)).unsubscribe();
  }


  abrirCertificado(certidicado: {nome: string, cidade: string, status: string, cpfCnpj: string, empreendimentos: any[], propriedades: any[], modalidade: string, protocolo: string, id: number, hash: string}){
    let urlQrcode = `${this.urlFront}/#/validar/${certidicado.id}/${certidicado.hash}  `
    console.log(urlQrcode);
    
    if(certidicado.status == 'OUTORGADO'){
      this.certificadoService.open(certidicado.nome, certidicado.cpfCnpj, certidicado.modalidade,
         certidicado.cidade, certidicado.empreendimentos, certidicado.propriedades, urlQrcode);
    } else{
      this.alert.message('Erro ao tentar criar certificado')
    }

    /* if (certidicado.modalidade == 'Financiador'){
      this.certificadoService.openFinanciador(certidicado.nome,
      'Investimento de R$500.000,00 (Quinhentos mill reais) no projeto SOS Amazônia.',
      urlQrcode);

    } else if (certidicado.modalidade == 'Apoiador'){
      this.certificadoService.openApoiador(certidicado.nome,
      'Divulgação do Programa Carbono Neutro MT no Congresso Brasileiro de Reflorestamanto Ambiental.',
      urlQrcode);

    } else if (certidicado.modalidade == 'Compromissário'){
      this.certificadoService.openCompromissario(certidicado.nome,
      'Neutralizou as emissões de 7,89 ton de CO2.',
      urlQrcode);

    } else{
      this.alert.message('Erro ao tentar criar certificado')
    } */
    
  }

  abrirCertificadoEn(certidicado: {nome: string, cidade: string, status: string, cpfCnpj: string, empreendimentos: any[], propriedades: any[], modalidade: string, protocolo: string, id: number, hash: string}){
    let urlQrcode = `${this.urlFront}/#/validar/${certidicado.id}/${certidicado.hash}  `
    console.log(urlQrcode);
    
    if(certidicado.status == 'OUTORGADO'){
      this.certificadoService.openEn(certidicado.nome, certidicado.cpfCnpj, certidicado.modalidade,
        certidicado.cidade, certidicado.empreendimentos, certidicado.propriedades, urlQrcode);
    } else{
      this.alert.message('Erro ao tentar criar certificado')
    }
    
  }

}
