import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dg-content-body',
  templateUrl: './dg-content-body.component.html',
  styleUrls: ['./dg-content-body.component.css']
})
export class DgContentBodyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
