import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { RestReturn } from 'src/app/components/common/rest-return';
import { RecuperaTokenService } from 'src/app/services/recupera-token.service';
import { AppUtils } from 'src/app/utils/app-utils';

@Injectable({
  providedIn: 'root'
})
export class ValidarService {

  protected readonly path: string;

  constructor(protected http: HttpClient, private util: AppUtils,
     protected recuperaToken: RecuperaTokenService, protected activatedRoute: ActivatedRoute) {
    this.path = util.getBaseUrlWebService();
  }

  public validarCertificado(idCadastro: string, hashConfirmacao: string): Observable<RestReturn<any>> {
    const url = this.path;
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("idCadastro", idCadastro)
    .append("hashConfirmacao", hashConfirmacao);

    return this.http.get<RestReturn<any>>(`${url}/api/v1/carbono/neutro/validar/cadastro`, { params: httpParam });
  }
}
