import {Component, Input, OnInit} from '@angular/core';
import {Actionbutton} from './actionbutton';

@Component({
  selector: 'dg-actionbutton',
  templateUrl: './dg-actionbutton.component.html',
  styleUrls: ['./dg-actionbutton.component.css']
})
export class DgActionbuttonComponent implements OnInit {

  @Input() button: Actionbutton;

  constructor() { }

  ngOnInit(): void {
  }

  executarBotao() {
    if (this.button.action) {
      this.button.action.call(this);
    } else if (this.button.event) {
      window.dispatchEvent(new CustomEvent(this.button.event));
    }
  }

}
