import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { DgInsertEditComponent } from './dg-insert-edit.component';
import {DgCardModule} from '../../dg-card/dg-card.module';
import {DgSharedModule} from '../../dg-shared.module';

@NgModule({
  declarations: [DgInsertEditComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        FormsModule,
        DgCardModule,
        DgSharedModule,
    ],
  exports: [DgInsertEditComponent]
})
export class DgInsertEditModule { }
