import { RestReturn } from './../../../common/rest-return';
import { catchError, finalize } from 'rxjs/operators';
import { DgTableEntity } from 'src/app/components/dg-shared/dg-data-table/model/DgTableEntity';
import { DataSource } from "@angular/cdk/table";
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { AbstractHttpService } from 'src/app/services/http/abstract-http.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { MatPaginator } from '@angular/material/paginator';

export class DgDataTableDataSource implements DataSource<DgTableEntity> {

  private subject = new BehaviorSubject<DgTableEntity[]>([]);

  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  public itemsAmount = 0;

  constructor(private service: AbstractHttpService<any>, private alert: SnackBarService, private processBeforeConsume?: Function, private endpointOptions?) {
  }

  pesquisar(id: number, filtros: Map<string, any>, sortDirection: string, pageIndex: number, pageSize: number, headers?: Map<string, string>) {
    if (this.service) {
      window.dispatchEvent(new CustomEvent('show-loading'));
      this.service.pesquisar(id, filtros, sortDirection, pageIndex, pageSize, headers, this.endpointOptions)
        .pipe(
          catchError(() => of([])),
          finalize(() => window.dispatchEvent(new CustomEvent('hide-loading'))))
        .subscribe((response: RestReturn<DgTableEntity>) => {
          if (!response.mensagem) {
            if (this.processBeforeConsume) {
              this.subject.next(this.processBeforeConsume(response.values));
            } else {
              this.subject.next(response.values);
            }
            if (response.totalItens) {
              this.itemsAmount = response.totalItens;
            } else if (response.values) {
              this.itemsAmount = response.values.length;
            } else {
              this.itemsAmount = 0;
            }
          } else {
            this.alert.message(response.mensagem);
            this.itemsAmount = 0;
          }
        });
    }
  }

  getData(): DgTableEntity[] {
    return this.subject.getValue();
  }

  setData(newData: DgTableEntity[]): void {
    this.subject.next(newData);
  }

  connect(collectionViewer: CollectionViewer): Observable<DgTableEntity[] | readonly DgTableEntity[]> {
    return this.subject.asObservable();
  }
  disconnect(collectionViewer: CollectionViewer): void {
    return this.subject.complete();
  }

}
