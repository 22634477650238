//Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//Material
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

//Dg
import { DgReceivedAvisoDialogComponent } from './dg-received-aviso.component';



@NgModule({
  declarations: [
    DgReceivedAvisoDialogComponent
  ],
  imports: [
    CommonModule,

    MatDialogModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [

  ],
  entryComponents: [
    DgReceivedAvisoDialogComponent,
  ]
})
export class DgReceivedAvisoModule { }
