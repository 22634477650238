import { Component, Input, OnInit, Injectable } from '@angular/core';
import { SnackBarService } from 'src/app/services/snack-bar.service';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'dg-file-input',
  templateUrl: './dg-file-input.component.html',
  styleUrls: ['./dg-file-input.component.css']
})
export class DgFileInputComponent implements OnInit {
  private arquivos: Array<File> = new Array<File>();
  @Input('multiple') multiple: boolean = false;
  @Input('accept') accept: string = '*';
  @Input('maxSize') maxSize: number = null;
  @Input() label = true;
  @Input() contatorArquivos = true;
  @Input() classFile = 'form-docs';

  imageDocs: string[] = ['image/png', 'image/gif', 'image/jpeg', 'image/svg'];

  constructor(private alert: SnackBarService) {
  }

  ngOnInit(): void {
  }

  public atualizarArquivos(inputInfo){
    var campoInvalid = document.getElementById('file-upload') as any;
    campoInvalid.classList.remove('invalid');
    // this.arquivos = this.multiple? this.arquivos.concat(Array.from(inputInfo)) : Array.from(inputInfo);
    this.filtarArquivos(this.multiple? this.arquivos.concat(Array.from(inputInfo)) : Array.from(inputInfo))
  }

  filtarArquivos(files: File[]){
    let tipoAceitos = this.accept.split(',');
    if (!files.every(file => tipoAceitos.includes(file.type))){
      this.alert.message('Documento não permitido')
    }
    this.arquivos = files.filter(file => tipoAceitos.includes(file.type))
    if (this.maxSize){
      setTimeout(() => {
        this.checkSize()
      }, 100)
    }
  }

  checkSize(){
    this.arquivos.forEach((file, index) => {
      if (file.size > this.maxSize){
        this.arquivos.splice(index, 1)
        this.alert.message('Documento maior que o permitido')
      }
    })
  }

  public removerArquivo(index){
    this.arquivos.splice(index, 1);
  }

  public getArquivos(): Array<File>{
    return this.arquivos;
  }

  public appendArquivo(arquivo: File): void{
    this.arquivos.push(arquivo);
  }

  public removerTodos(){
    this.arquivos = [];
  }

}
