import { Location } from '@angular/common';
import { DgTableEntity } from 'src/app/components/dg-shared/dg-data-table/model/DgTableEntity';
import { SnackBarService } from './../../../services/snack-bar.service';
import { HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AbstractHttpService } from "src/app/services/http/abstract-http.service";
import { DgConfirmDialog } from '../dg-confirm-dialog/dg-confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

export abstract class DgAbstractInsertEditComponent<E extends DgTableEntity> implements OnInit {

  public entity: E;

  public entityId: any;

  constructor(protected route: ActivatedRoute, protected service: AbstractHttpService<E>, protected alert: SnackBarService,
    protected location: Location, protected dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.getIdFromRoute();
  }

  abstract initNewEntity();

  getIdFromRoute() {
    this.route.params.subscribe(params => {
      this.entityId = params['id'] != 'Novo'?  params['id'] : null;
      if (this.entityId) {
        this.fetchEntity(this.entityId);
      } else {
        this.initNewEntity();
      }
    }, error => this.alert.message(error.error.mensagem)).unsubscribe();
  }

  fetchEntity(id) {
    if (id) {
      this.service.pesquisar(id).subscribe((response) => {

        this.entity = response.values[0];
        this.afterFetchEntity();
      }, error => {
        this.alert.message(error.error.mensagem);
        
      });
    }
  }

  afterFetchEntity() {

  }

  @HostListener('window:voltar-insert-edit-event', ['$event'])
  voltar(event?) {
    this.location.back();
  }

  @HostListener('window:deletar-insert-edit-event', ['$event'])
  deletar() {
    const dialogRef = this.dialog.open(DgConfirmDialog, {data: {conteudo: 'Deseja excluir este registro? Esta ação é permanente.', titulo: 'Excluir registro'}});
    dialogRef.afterClosed().subscribe(podeDeletar => {
      if (podeDeletar) {
        window.dispatchEvent(new CustomEvent('show-loading'));
        this.service.delete(this.entity.id)
          .subscribe(
            value => {
              this.alert.message(value.mensagem);
              window.dispatchEvent(new CustomEvent('hide-loading'));
            },
            error => {
              this.alert.message(error.error.mensagem);
              window.dispatchEvent(new CustomEvent('hide-loading'));
            }, () => this.voltar());
      }
    });
  }

  @HostListener('window:salvar-insert-edit-event', ['$event'])
  salvar() {
    const canSave = this.beforeSaveEntity();
    if (canSave) {
      window.dispatchEvent(new CustomEvent('show-loading'));
      this.service.saveOrUpdate(this.entity)
        .subscribe(
          response => {
            this.alert.message(response.mensagem);
            this.entity = response.values[0];
            this.afterSaveEntity();
            window.dispatchEvent(new CustomEvent('hide-loading'));
          },
          error => {
            this.alert.message(`Houve algum erro na sua requisição : ${error.error.mensagem}`);
            window.dispatchEvent(new CustomEvent('hide-loading'));
          }, () => this.voltar());
    }
  }

  beforeSaveEntity(): boolean {
    return true;
  }

  afterSaveEntity() {

  }
}
