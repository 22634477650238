import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileName'
})
export class FileNamePipe implements PipeTransform {

  transform(value: string, maxSize: number = 30): string {
    if(value.length >= maxSize){
      return value.slice(0, maxSize) + '...';
    }
    return value;
  }

}
