import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DgActionbarModule } from 'src/app/components/dg-shared/dg-actionbar/dg-actionbar.module';
import { HomeComponent } from './home.component';
import { HomeRoutingModule } from './home.routing.module';
import { DgReceivedAvisoModule } from '../../components/dg-shared/dg-received-aviso/dg-received-aviso.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatIconModule } from '@angular/material/icon';
import { DgCardModule } from 'src/app/components/dg-shared/dg-card/dg-card.module';
import { DgConfirmDialogModule } from 'src/app/components/dg-shared/dg-confirm-dialog/dg-confirm-dialog.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { BreadcrumbModule } from 'angular-crumbs';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { DgFileInputModule } from 'src/app/components/dg-shared/dg-file-input/dg-file-input.module';


export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  imports: [
    DgCardModule,
    DgConfirmDialogModule,
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatDividerModule,
    BreadcrumbModule,
    MatTableModule,
    MatIconModule,
    FontAwesomeModule,
    MatCardModule,
    DgCardModule,
    FontAwesomeModule,
    MatButtonModule,
    NgxMaskModule.forRoot(),
    MatCheckboxModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatRadioModule,
    DgFileInputModule

  ],
  declarations: [
    HomeComponent,
  ]
})
export class HomeModule { }
