import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { DgReceivedAvisoComponent } from '../../components/dg-shared/dg-received-aviso/dg-received-aviso.component';
import { faHome, faEdit, faUser, faFileAlt, faBoxes, faBook, faDatabase, faCog, faBell, faGlobe,
   faEnvelope, faDesktop, faChartBar, faTree, faMountain, faHands } from '@fortawesome/free-solid-svg-icons';
import { HomeService } from './service/home.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { DatePipe } from '@angular/common';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DgConfirmDialog } from 'src/app/components/dg-shared/dg-confirm-dialog/dg-confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { cpf, cnpj } from 'cpf-cnpj-validator'; 
import { FormCarbonoNeutroService } from 'src/app/components/form-carbono-neutro/form-carbono-neutro.service';
import { FormularioEntity } from './model/formulario.entity';
import { TermoCompromissoService } from 'src/app/components/termo-compromisso/termo-compromisso.service';
import { DgFileInputComponent } from 'src/app/components/dg-shared/dg-file-input/dg-file-input/dg-file-input.component';
import { DgCustomButton } from 'src/app/components/dg-shared/dg-data-table/model/DgCustomButton';
import { CategoriaSelo } from 'src/app/enum/categoria-selo.enum';
import { CadastroSeloEntity } from '../cadastro-selo/model/cadastro-selo.entity';
import { get } from 'scriptjs';
import { environment } from 'src/environments/environment';
import { AppUtils } from 'src/app/utils/app-utils';

const { version } = require('./../../../../package.json');

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public usuarioExterno = false;
  faGlobe = faGlobe;
  faHands = faHands;
  faBell = faBell;
  faHome = faHome;
  faEdit = faEdit;
  faUser = faUser;
  faFileAlt = faFileAlt;
  faBoxes = faBoxes;
  faDatabase = faDatabase;
  faBook = faBook;
  faCog = faCog;
  faDownload = faDesktop;
  faEnvelope = faEnvelope;
  faTree = faTree;
  faMountain = faMountain;

  version = version;

  faChartBar = faChartBar;

  enumCategoria = CategoriaSelo;

  controlForm : string = null;
  formPessoaFisica: FormGroup;
  formPessoaJuridica: FormGroup;
  propriedadesControl = null;

  ruraisCheck = false;
  urbanosCheck = false;

  checkPropriedades = true;

  rurais: {identificacao: string, car: string, atividade: string, licencas: string, outorga: string}[] = [];
  urbanos: {empreendimento: string, atividade: string, licencas: string, outorga: string}[] = [];

  checkFormalizar = false;
  checkDeclarar = false;
  mensagemFinal = false;

  documento: CadastroSeloEntity;
  protocolo: string;

  listaArquivos: {arquivo: string, nome: string}[] = [];

  cadastroPrograma: {nome: string, cpfCnpj: string, protocolo: string, certificado: string,
    emails: string[], arquivos: {arquivo: string, nome: string}[]};

  @ViewChild('fileInput') fileInput: DgFileInputComponent;

  siteKey = environment.SITEKEY_HCAPTCHA;

  devMode = false;

  constructor(private service: HomeService, private alert: SnackBarService, private dataPipe: DatePipe, private util: AppUtils,
     protected dialog: MatDialog, private formCarbonoService: FormCarbonoNeutroService, private termoService: TermoCompromissoService) { }


  ngOnInit(): void {

    this.util.devMode.subscribe(mode => {
      this.devMode = mode;
    });

    this.formPessoaFisica = new FormGroup({
      tipoAdesao: new FormControl('', Validators.required),
      nome: new FormControl('', Validators.required),
      sobrenome: new FormControl('', Validators.required),
      cpf: new FormControl('', [Validators.required, this.cpfValidador()]),
      email: new FormControl('', [Validators.required, Validators.email]),
      telefone: new FormControl('', Validators.required),
      cep: new FormControl('', Validators.required),
      bairro: new FormControl('', Validators.required),
      logradouro: new FormControl('', Validators.required),
      numero: new FormControl('', Validators.required),
      complemento: new FormControl(''),
      municipio: new FormControl('', Validators.required),
      uf: new FormControl('', Validators.required),
    });

    this.formPessoaJuridica = new FormGroup({
      tipoAdesao: new FormControl('', Validators.required),
      razaoSocial: new FormControl('', Validators.required),
      cnpj: new FormControl('', [Validators.required, this.cnpjValidador()]),
      email: new FormControl('', [Validators.required, Validators.email]),
      nomeRepresentante: new FormControl('', Validators.required),
      cpfRepresentante: new FormControl('', [Validators.required, this.cpfValidador()]),
      emailRepresentante: new FormControl('', [Validators.required, Validators.email]),
      telefone: new FormControl('', Validators.required),
      cep: new FormControl('', Validators.required),
      bairro: new FormControl('', Validators.required),
      logradouro: new FormControl('', Validators.required),
      numero: new FormControl('', Validators.required),
      complemento: new FormControl(''),
      municipio: new FormControl('', Validators.required),
      uf: new FormControl('', Validators.required),
    });
    
    this.insertRurais();
    this.insertUrbanos();
    this.updateSiteKey();

    this.documento = new CadastroSeloEntity();
  }

  updateSiteKey(){
    let hCaptcha = document.getElementsByClassName('h-captcha')[0] as any;
    hCaptcha.setAttribute('data-sitekey', this.siteKey);
  }

  cpfValidador(): ValidatorFn{
    return (control:AbstractControl) : ValidationErrors | null => {
      let numCpf = control.value;

      return !cpf.isValid(numCpf) ? {cpfValid:true}: null;
    }
  }

  cnpjValidador(): ValidatorFn{
    return (control:AbstractControl) : ValidationErrors | null => {
      let numCnpj = control.value;

      return !cnpj.isValid(numCnpj) ? {cpfValid:true}: null;
    }
  }

  loadGoogleRecaptcha(){
    get("https://www.google.com/recaptcha/api.js", () => {});
  }

  loadHrecaptcha(){
    get("https://js.hcaptcha.com/1/api.js", () => {});
  }

  checkDocAssinados(){
    if (this.checkDeclarar && this.checkFormalizar){
      window.dispatchEvent(new CustomEvent('show-loading'));

      this.gerarDocumentos();
      const customButtons: DgCustomButton[] = [
        new DgCustomButton('Não! Quero encaminhar sem assinar digitalmente', null, 'grayButton', 'nao', null),
        new DgCustomButton('Sim! Quero assinar antes de encaminhar', null, 'blueButton', 'sim', null),
      ]
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent('hide-loading'));
        if (this.controlForm){
          const dialogRef = this.dialog.open(DgConfirmDialog, { data: 
            { conteudo: 'Deseja assinar os documentos digitalmente antes de enviar?',
              titulo: 'Assinar documentos',
              customButtons: customButtons},
              minWidth: '365px'});
          dialogRef.afterClosed().subscribe(assinar => {
            if (assinar == 'sim') {
              if (this.controlForm.endsWith('fisica')){
                this.controlForm = 'anexos-fisica'
              } else if (this.controlForm.endsWith('juridica')){
                this.controlForm = 'anexos-juridica'
              }
            } else{
              this.enviarDados(false)
            }
          });
        }
      }, 5000);
    } else{
      this.alert.message('Aceite os termos acima')
    }
  }

  finalizarEnvio(){
    if (this.checkDeclarar && this.checkFormalizar){
      const dialogRef = this.dialog.open(DgConfirmDialog, { data: 
        { conteudo: 'O envio desses dados confirma o seu interesse em realizar a adesão ao Programa Carbono Neutro MT. Tem certeza que quer realizar o envio?',
        titulo: 'Enviar dados'}});
      dialogRef.afterClosed().subscribe(podeEnviar => {
        if (podeEnviar) {
          window.dispatchEvent(new CustomEvent('show-loading'));

          let anexos = this.listaArquivos
    
          try{
            let arquivos = this.fileInput.getArquivos();
            arquivos.forEach(file => {
              var reader = new FileReader();
              reader.onload = (function(theFile) {
                return function(e) {
                  var binaryData: any = e.target.result;
                  var base64String = window.btoa(binaryData);
                  anexos.push({
                    nome: file.name.replace('/', '.'),
                    arquivo: base64String
                  })
                };
              })(file);
              reader.readAsBinaryString(file);
            })
          } catch{}

          setTimeout(() => {
            let emails: string[] = [];
            if (this.documento.email){
              emails.push(this.documento.email)
            }
            if(this.documento.emailRepresentante){
              emails.push(this.documento.emailRepresentante)
            }
  
            /* this.cadastroPrograma = {
              nome: this.controlForm.endsWith('fisica')?
              `${this.documento.nome} ${this.documento.sobrenome}`:this.documento.razaoSocial,
              cpfCnpj: this.controlForm.endsWith('fisica')?
              this.documento.cpfCnpj:this.documento.cnpj,
              protocolo: this.documento.protoco,
              certificado: null,
              emails: emails,
              arquivos: anexos
            } */
            this.documento.documentos = anexos;
          
            this.service.enviarCadastro(this.documento).subscribe(response =>{
              this.alert.message(response.mensagem);
              this.controlForm = null;
              this.mensagemFinal = true;
              this.scrollElementId('mensagem-principal')
              window.dispatchEvent(new CustomEvent('hide-loading'));
            }, error => {
              this.alert.message('Erro ao tentar enviar dados');
              window.dispatchEvent(new CustomEvent('hide-loading'));
            })
          }, 2000);
        }
      });
    } else{
      this.alert.message('Aceite os termos acima')
    }
  }

  enviarDados(anexos = true){
    if (anexos){
      let files = this.fileInput.getArquivos();
      if (files.length > 0){
        this.finalizarEnvio()
      } else{
        this.alert.message('Nenhum documento anexado')
      }
    } else{
      this.finalizarEnvio()
    }
  }

  baixarMinuta(){
    const anchor = window.document.createElement('a');
    anchor.href = '/assets/static/media/Instrucao Normativa Carbono neutro MT.pdf';
    anchor.download = "Instrução Normativa Carbono Neutro MT.pdf";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(anchor.href);
  }

  baixarTermoReferencia(tipo: string){
    const anchor = window.document.createElement('a');
    if (tipo == 'APOIADOR'){
      anchor.href = '/assets/static/media/Termo de Referencia Apoiador.pdf';
      anchor.download = "Termo de Referencia Apoiador.pdf";
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(anchor.href);
    } else if (tipo == 'COMPROMISSARIO'){
      anchor.href = '/assets/static/media/Termo de Referencia Compromissario.pdf';
      anchor.download = "Termo de Referencia Compromissario.pdf";
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(anchor.href);
    }
  }

  downloadBlob(nome, base64): void {
    let blob = this.base64ToFile(base64, 'application/pdf');
    const anchor = window.document.createElement('a');
    anchor.href = window.URL.createObjectURL(blob);
    anchor.download = nome;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(anchor.href);
  }

  formCarbonoNeutro(documento: CadastroSeloEntity, tipo){
    this.formCarbonoService.open(documento, tipo);
    /* if (baixar){
      this.termoApreensaoService.base64(base64 => {
        this.downloadBlob(base64, documento)
      }, documento)
    } else{
      this.termoApreensaoService.open(documento)
    } */
  }

  termCarbonoNeutro(documento: CadastroSeloEntity, tipo){
    this.termoService.open(documento, tipo);
  }

  montarEndidadePF(){
    this.documento = new CadastroSeloEntity(
      null,
      this.protocolo,
      this.formPessoaFisica.controls['tipoAdesao'].value,
      new Date(),
      null,
      this.formPessoaFisica.controls['cpf'].value,
      this.formPessoaFisica.controls['nome'].value,
      this.formPessoaFisica.controls['sobrenome'].value,
      null,
      null,
      this.formPessoaFisica.controls['email'].value,
      null,
      this.formPessoaFisica.controls['telefone'].value,
      this.formPessoaFisica.controls['cep'].value,
      this.formPessoaFisica.controls['uf'].value,
      this.formPessoaFisica.controls['municipio'].value,
      this.formPessoaFisica.controls['bairro'].value,
      this.formPessoaFisica.controls['logradouro'].value,
      this.formPessoaFisica.controls['numero'].value,
      this.formPessoaFisica.controls['complemento'].value,
      null,
      null,
      [],
      [],
      this.ruraisCheck?this.rurais:null,
      this.urbanosCheck?this.urbanos:null
    )
  }

  montarEndidadePJ(){
    this.documento = new CadastroSeloEntity(
      null,
      this.protocolo,
      this.formPessoaJuridica.controls['tipoAdesao'].value,
      new Date(), 
      this.formPessoaJuridica.controls['razaoSocial'].value,
      this.formPessoaJuridica.controls['cnpj'].value,
      null,
      null,
      this.formPessoaJuridica.controls['nomeRepresentante'].value,
      this.formPessoaJuridica.controls['cpfRepresentante'].value,
      this.formPessoaJuridica.controls['email'].value,
      this.formPessoaJuridica.controls['emailRepresentante'].value,
      this.formPessoaJuridica.controls['telefone'].value,
      this.formPessoaJuridica.controls['cep'].value,
      this.formPessoaJuridica.controls['uf'].value,
      this.formPessoaJuridica.controls['municipio'].value,
      this.formPessoaJuridica.controls['bairro'].value,
      this.formPessoaJuridica.controls['logradouro'].value,
      this.formPessoaJuridica.controls['numero'].value,
      this.formPessoaJuridica.controls['complemento'].value,
      null,
      null,
      [],
      [],
      this.ruraisCheck?this.rurais:null,
      this.urbanosCheck?this.urbanos:null
    )
  }

  @HostListener('window:update-home')
  updateHome() {
    this.ngOnInit()
  }

  completarEnderecoFisica(){
    if (this.formPessoaFisica.controls['cep'].valid) {
      window.dispatchEvent(new CustomEvent('show-loading'));
      this.service.buscarCEP(this.formPessoaFisica.controls['cep'].value).subscribe(response => {
        window.dispatchEvent(new CustomEvent('hide-loading'));
        const resp = JSON.parse(response.mensagem)
        this.formPessoaFisica.controls['bairro'].setValue(resp.bairro);
        this.formPessoaFisica.controls['logradouro'].setValue(resp.logradouro);
        this.formPessoaFisica.controls['municipio'].setValue(resp.localidade);
        this.formPessoaFisica.controls['uf'].setValue(resp.uf);
      }, erro => window.dispatchEvent(new CustomEvent('hide-loading')))
    }
  }

  completarEnderecoJuridica(){
    if (this.formPessoaJuridica.controls['cep'].valid) {
      window.dispatchEvent(new CustomEvent('show-loading'));
      this.service.buscarCEP(this.formPessoaJuridica.controls['cep'].value).subscribe(response => {
        window.dispatchEvent(new CustomEvent('hide-loading'));
        const resp = JSON.parse(response.mensagem)
        this.formPessoaJuridica.controls['bairro'].setValue(resp.bairro);
        this.formPessoaJuridica.controls['logradouro'].setValue(resp.logradouro);
        this.formPessoaJuridica.controls['municipio'].setValue(resp.localidade);
        this.formPessoaJuridica.controls['uf'].setValue(resp.uf);
      }, erro => window.dispatchEvent(new CustomEvent('hide-loading')))
    }
  }

  proximo(){
    if (this.controlForm == 'pessoa-fisica'){
      if (this.formPessoaFisica.valid){
        
        const token = document.getElementsByClassName('h-captcha')[0]
          .children[0].getAttribute('data-hcaptcha-response')

        if (token){
          window.dispatchEvent(new CustomEvent('show-loading'));

          this.service.validarHrecaptcha(token).subscribe(resp => {
            window.dispatchEvent(new CustomEvent('hide-loading'));
            const response = JSON.parse(resp.mensagem)
            if (response.success){
              if (this.formPessoaFisica.controls['tipoAdesao'].value == 'APOIADOR' || this.formPessoaFisica.controls['tipoAdesao'].value == 'FINANCIADOR'){
                this.controlForm = 'propriedades-fisica';
                this.urbanosCheck = false;
                this.ruraisCheck = false;
                this.checkPropriedades = false;
                this.checkFormalizar = true;
              } else{
                this.controlForm = 'propriedades-fisica'
                this.checkPropriedades = true;
              }
            } else{
              this.alert.message("Resolva o hCaptcha")
            }
          }, error => {
            this.alert.message(error.error.mensagem)
            window.dispatchEvent(new CustomEvent('hide-loading'));

          })
        } else if(this.devMode){
          if (this.formPessoaFisica.controls['tipoAdesao'].value == 'APOIADOR' || this.formPessoaFisica.controls['tipoAdesao'].value == 'FINANCIADOR'){
            this.controlForm = 'propriedades-fisica';
            this.urbanosCheck = false;
            this.ruraisCheck = false;
            this.checkPropriedades = false;
            this.checkFormalizar = true;
          } else{
            this.controlForm = 'propriedades-fisica'
            this.checkPropriedades = true;
          }
        } else{
          this.alert.message("Resolva o hCaptcha")
        }

      } else{
        this.formPessoaFisica.markAllAsTouched()
        this.alert.message('Preencha todos os campos obrigatórios')
      }
    } else if (this.controlForm == 'pessoa-juridica'){
      if (this.formPessoaJuridica.valid){
        
        const token = document.getElementsByClassName('h-captcha')[0]
          .children[0].getAttribute('data-hcaptcha-response')

        if (token){
          window.dispatchEvent(new CustomEvent('show-loading'));

          this.service.validarHrecaptcha(token).subscribe(resp => {
            window.dispatchEvent(new CustomEvent('hide-loading'));
            const response = JSON.parse(resp.mensagem)
            if (response.success){
              if (this.formPessoaJuridica.controls['tipoAdesao'].value == 'APOIADOR' || this.formPessoaJuridica.controls['tipoAdesao'].value == 'FINANCIADOR'){
                this.controlForm = 'propriedades-juridica';
                this.urbanosCheck = false;
                this.ruraisCheck = false;
                this.checkPropriedades = false;
                this.checkFormalizar = true;
              } else{
                this.controlForm = 'propriedades-juridica'
                this.checkPropriedades = true;
              }
            } else{
              this.alert.message("Resolva o hCaptcha")
            }
          }, error => {
            this.alert.message(error.error.mensagem)
            window.dispatchEvent(new CustomEvent('hide-loading'));

          })
        } else if(this.devMode){
          if (this.formPessoaJuridica.controls['tipoAdesao'].value == 'APOIADOR' || this.formPessoaJuridica.controls['tipoAdesao'].value == 'FINANCIADOR'){
            this.controlForm = 'propriedades-juridica';
            this.urbanosCheck = false;
            this.ruraisCheck = false;
            this.checkPropriedades = false;
            this.checkFormalizar = true;
          } else{
            this.controlForm = 'propriedades-juridica'
            this.checkPropriedades = true;
          }
        } else{
          this.alert.message("Resolva o hCaptcha")
        }
      } else{
        this.formPessoaJuridica.markAllAsTouched()
        this.alert.message('Preencha todos os campos obrigatórios')
      }
    }
  }

  proximoAnexo(){
    if (this.checkFormalizar){
       this.gerarDocumentos()
       if (this.controlForm.endsWith('fisica')){
         this.controlForm = 'anexos-fisica'
       } else if (this.controlForm.endsWith('juridica')){
         this.controlForm = 'anexos-juridica'
       }
    } else{
      this.alert.message('Aceite o termo acima')
    }
  }

  voltarAnexo(){
    // this.loadGoogleRecaptcha();
    this.loadHrecaptcha();
    this.checkPropriedades = (this.formPessoaJuridica.controls['tipoAdesao'].value == 'APOIADOR' 
        || this.formPessoaJuridica.controls['tipoAdesao'].value == 'FINANCIADOR');

    if (this.controlForm.endsWith('fisica')){
      this.controlForm = 'propriedades-fisica'
    } else if (this.controlForm.endsWith('juridica')){
      this.controlForm = 'propriedades-juridica'
    }
  }

  voltar(){
    // this.loadGoogleRecaptcha();
    this.loadHrecaptcha();
    if (this.controlForm.endsWith('fisica')){
      this.controlForm = 'pessoa-fisica'
    } else if (this.controlForm.endsWith('juridica')){
      this.controlForm = 'pessoa-juridica'
    }
  }

  protected base64ToFile(base64Src: any, tipoArquivo: string){
    const byteArray = new Uint8Array(atob(base64Src).split('').map(char => char.charCodeAt(0)));
    return new Blob([byteArray], {type: tipoArquivo});
  }

  gerarDocumentos(){
    if (this.controlForm.endsWith('fisica')){
      this.montarEndidadePF();
      this.baixarTermoReferencia(this.formPessoaFisica.controls['tipoAdesao'].value);
    } else{
      this.montarEndidadePJ();
      this.baixarTermoReferencia(this.formPessoaJuridica.controls['tipoAdesao'].value);
    }
    this.baixarMinuta();
    setTimeout(() =>{
      this.service.buscasProtocolo().subscribe(resp =>{
        this.protocolo = resp.mensagem;
        this.documento.protocolo = resp.mensagem;
        this.formCarbonoService.base64(base64 => {

          this.listaArquivos.push({
            nome: `Formulário de adesão ${this.documento.protocolo.replace('/', '.')}.pdf`,
            arquivo: `${base64}`
          })

          this.termoService.base64(base64_termo => {
            this.listaArquivos.push({
              nome: `Termo de Compromisso Voluntário ${this.documento.protocolo.replace('/', '.')}.pdf`,
              arquivo: `${base64_termo}`
            })
            
            this.downloadBlob(`Formulário de adesão ${this.documento.protocolo.replace('/', '.')} - assinar e datar.pdf`, base64)
            this.downloadBlob(`Termo de compromisso voluntário ${this.documento.protocolo.replace('/', '.')} - assinar e datar.pdf`, base64_termo)


          }, this.documento, this.controlForm.endsWith('fisica')?'PF':'PJ');

        }, this.documento, this.controlForm.endsWith('fisica')?'PF':'PJ')
      }, error => {
        this.alert.message('Erro ao tentar gerar protocolo');
        this.controlForm = null
      })
    }, 2000)
  }


  scrollElementId(id){
    setTimeout(() => {
      var pageElement = document.getElementById(id) as any;
      if (pageElement){
        pageElement.scrollIntoView(); 
      }
    }, 200)
  }

  insertRurais(){
    this.rurais.push({
      identificacao: '', car: '', atividade: '', licencas: '', outorga: ''
    })
  }

  insertUrbanos(){
    this.urbanos.push({
      empreendimento: '', atividade: '', licencas: '', outorga: ''
    })
  }

  removerPropriedade(index) {
    const dialogRef = this.dialog.open(DgConfirmDialog, { data: 
      { conteudo: 'Deseja realmente excluir essa propriedade? Esta ação é permanente.', titulo: 'Excluir Propriedade' } });
    dialogRef.afterClosed().subscribe(podeDeletar => {
      if (podeDeletar) {
        this.rurais.splice(index, 1);
      }
    });
  }

  removerEmpreendimento(index) {
    const dialogRef = this.dialog.open(DgConfirmDialog, { data: 
      { conteudo: 'Deseja realmente excluir esse empreendimento? Esta ação é permanente.', titulo: 'Excluir Empreendimento' } });
    dialogRef.afterClosed().subscribe(podeDeletar => {
      if (podeDeletar) {
        this.urbanos.splice(index, 1);
      }
    });
  }
}
