import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dg-breadcrumb',
  templateUrl: './dg-breadcrumb.component.html',
  styleUrls: ['./dg-breadcrumb.component.css']
})
export class DgBreadcrumbComponent implements OnInit {

  verBarraBotao = true;

  constructor() { }

  ngOnInit() {
  }

  console(obj){
    console.log(obj)
  }

  barraLateral(fechar){
    var painel = document.getElementsByClassName('main-panel') as any;
    var barra = document.getElementsByClassName('sidebar') as any;
    if(fechar){
      for (var i = 260; i > -1; i--){
        painel[0].style.width = `calc(100% - ${i}px)`;
        barra[0].style.left = `${i - 260}px`
      }
    } else{
      for (var i = 1; i < 261; i++){
        painel[0].style.width = `calc(100% - ${i}px)`;
        barra[0].style.left = `${i - 260}px`
      }
    }
  }
}
