import {Injector, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'tableDynamicPipe'
})
export class TableDynamicPipe implements PipeTransform {

  constructor(private injector: Injector) { }

  transform(value: string, pipeToken: string, ...args: unknown[]): string {
    if (!pipeToken) {
      return value;
    } else {
      const pipe = this.injector.get(pipeToken);
      return pipe.transform(value, ...args);
    }
  }
}
