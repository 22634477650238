import { Injectable } from '@angular/core';
import * as moment from 'moment';
import {commandFunction} from 'ngx-wig';
import { CategoriaSelo } from 'src/app/enum/categoria-selo.enum';
import { CadastroSeloEntity } from 'src/app/pages/cadastro-selo/model/cadastro-selo.entity';
import { PdfMakeService } from 'src/app/services/pdf-make.service';
import { BaseAutosTermosService, interfaceAssinatura } from '../base-autos-termos/base-autos-termos.service';

// tslint:disable-next-line:class-nam

@Injectable({
  providedIn: 'root'
})
export class TermoCompromissoService {

  categoriaEnum = CategoriaSelo;

  constructor(private makePdf: PdfMakeService, private docBase: BaseAutosTermosService) {}

  public open(d?: CadastroSeloEntity, tipo?: 'PF' | 'PJ') {
    this.makePdf.open(this.corpoDocumento(d, tipo));
  }

  public base64(callback: commandFunction, dados?: CadastroSeloEntity, tipo?: 'PF' | 'PJ') {
    this.makePdf.base64(
      this.corpoDocumento(dados, tipo),
      (data) => {
        callback(data);
      }
    );
  }

  private corpoDocumento(d?: CadastroSeloEntity, tipo?) {
    const nomePrograma = 	{
      margin: [0, 5, 0, 0],
      table: {
				widths: ['*'],
				body: [
					[{text: 'TERMO DE COMPROMISSO VOLUNTÁRIO', fontSize: 14, bold: true, fillColor: "#EEEEEE", alignment: 'center'}]
				]
			}
    };

    const titulo = {
      alignment: 'center',
      margin: [0, 5, 0, 0],
      table: {
				widths: [300, '*', '*'],
				body: [
					[{rowSpan: 2, text: `${this.categoriaEnum[d.modalidade]} - ${tipo}`, fontSize: 23, bold: true},
           {text: 'PROTOCOLO Nº:', fontSize: 8},
           {text: 'DATA:', fontSize: 8}],
          ['',
           {text: d?.protocolo, fontSize: 12, bold: true},
           {text: (moment(d.dataInclusao)).format('DD/MM/YYYY'), fontSize: 12, bold: true}],
				]
			}
    };

    let dadosLinha1, dadosLinha2, dadosLinha3, dadosLinha4, dadosLinha5, dadosLinha6, assinatura;

    if (tipo == 'PJ'){

      dadosLinha1 = 	{
        margin: [0, 20, 0, 0],
        table: {
          widths: ['*'],
          body: [
            [{text: [{text: 'RAZÃO SOCIAL:\n', fontSize: 6},
                     {text: d?.razaoSocial, fontSize: 8, bold: true}],
             border: [true, true, true, false]}]
          ]
        },
        layout: {
          defaultBorder: false,
        }
      };

      dadosLinha2 = 	{
        margin: [0, 0, 0, 0],
        table: {
          widths: ['*', '*'],
          body: [
            [{text: [{text: 'CNPJ:\n', fontSize: 6},
                     {text: this.docBase.cpfCnpfFormat(d?.cpfCnpj), fontSize: 8, bold: true}],
             border: [true, true, true, false]},
             {text: [{text: 'EMAIL:\n', fontSize: 6},
                     {text: d?.email, fontSize: 8, bold: true}],
             border: [true, true, true, false]}]
          ]
        },
        layout: {
          defaultBorder: false,
        }
      };

      dadosLinha3 = 	{
        margin: [0, 0, 0, 0],
        table: {
          widths: [200, '*', '*'],
          body: [
            [{text: [{text: 'NOME DO REPRESENTANTE:\n', fontSize: 6},
                     {text: d?.nomeRepresentante, fontSize: 8, bold: true}],
             border: [true, true, true, false]},
             {text: [{text: 'CPF DO REPRESENTANTE:\n', fontSize: 6},
                     {text: this.docBase.cpfCnpfFormat(d?.cpfRepresentante), fontSize: 8, bold: true}],
             border: [true, true, true, false]},
             {text: [{text: 'EMAIL DO REPRESENTANTE:\n', fontSize: 6},
                     {text: d?.emailRepresentante, fontSize: 8, bold: true}],
             border: [true, true, true, false]}],
          ]
        },
        layout: {
          defaultBorder: false,
        }
        
      };
  
      dadosLinha4 = 	{
        margin: [0, 0, 0, 0],
        table: {
          widths: ['*'],
          body: [
            [{text: [{text: 'LOGRADOURO:\n', fontSize: 6},
                     {text: this.docBase.textFormat(d?.logradouro), fontSize: 8, bold: true}],
             border: [true, true, true, false]}]
          ]
        },
        layout: {
          defaultBorder: false,
        }
      };
  
      dadosLinha5 = 	{
        margin: [0, 0, 0, 0],
        table: {
          widths: ['*', '*', '*'],
          body: [
            [{text: [{text: 'BAIRRO:\n', fontSize: 6},
                     {text: this.docBase.textFormat(d?.bairro), fontSize: 8, bold: true}],
             border: [true, true, true, false]},
             {text: [{text: 'NÚMERO:\n', fontSize: 6},
                     {text: this.docBase.textFormat(d?.numero), fontSize: 8, bold: true}],
             border: [true, true, true, false]},
             {text: [{text: 'COMPLEMENTO:\n', fontSize: 6},
                     {text: this.docBase.telefoneFormat(d?.complemento), fontSize: 8, bold: true}],
             border: [true, true, true, false]}],
          ]
        },
        layout: {
          defaultBorder: false,
        }
        
      };

      dadosLinha6 = 	{
        margin: [0, 0, 0, 0],
        table: {
          widths: [50, 200, 100, '*'],
          body: [
            [{text: [{text: 'UF:\n', fontSize: 6},
                     {text: this.docBase.textFormat(d?.uf), fontSize: 8, bold: true}],
             border: [true, true, true, true]},
             {text: [{text: 'MUNICÍPIO:\n', fontSize: 6},
                     {text: this.docBase.textFormat(d?.municipio), fontSize: 8, bold: true}],
             border: [true, true, true, true]},
             {text: [{text: 'CEP:\n', fontSize: 6},
                     {text: this.docBase.cepFormat(d?.cep), fontSize: 8, bold: true}],
             border: [true, true, true, true]},
             {text: [{text: 'TELEFONE:\n', fontSize: 6},
                     {text: this.docBase.telefoneFormat(d?.telefone), fontSize: 8, bold: true}],
             border: [true, true, true, true]}],
          ]
        },
        layout: {
          defaultBorder: false,
        }
        
      };

      assinatura = 	{
        margin: [0, 0, 0, 0],
        table: {
          dontBreakRows: true,
          widths: ['*'],
          body: [
            [{text: [
              {text: '\n\n\n............................................................ , ............ de ....................................... de ..................\n'},
              {text: '\n\n\n____________________________________________________\n'},
              {text: `${d?.nomeRepresentante}`, bold: true}
            ],
             fontSize: 10, alignment: 'center'}]
          ]
        },
        layout: 'noBorders'
      };
    } else{

      dadosLinha1 = 	{
        margin: [0, 20, 0, 0],
        table: {
          widths: ['*', 250, '*'],
          body: [
            [{text: [{text: 'NOME:\n', fontSize: 6},
                     {text: d?.nome, fontSize: 8, bold: true}],
             border: [true, true, true, false]},
             {text: [{text: 'SOBRENOME:\n', fontSize: 6},
                     {text: d?.sobrenome, fontSize: 8, bold: true}],
             border: [true, true, true, false]},
             {text: [{text: 'CPF:\n', fontSize: 6},
                     {text: this.docBase.cpfCnpfFormat(d?.cpfCnpj), fontSize: 8, bold: true}],
             border: [true, true, true, false]}]
          ]
        },
        layout: {
          defaultBorder: false,
        }
      };
  
      dadosLinha3 = 	{
        margin: [0, 0, 0, 0],
        table: {
          widths: ['*'],
          body: [
            [{text: [{text: 'LOGRADOURO:\n', fontSize: 6},
                     {text: this.docBase.textFormat(d?.logradouro), fontSize: 8, bold: true}],
             border: [true, true, true, false]}]
          ]
        },
        layout: {
          defaultBorder: false,
        }
      };
  
      dadosLinha4 = 	{
        margin: [0, 0, 0, 0],
        table: {
          widths: ['*', '*', '*'],
          body: [
            [{text: [{text: 'BAIRRO:\n', fontSize: 6},
                     {text: this.docBase.textFormat(d?.bairro), fontSize: 8, bold: true}],
             border: [true, true, true, false]},
             {text: [{text: 'NÚMERO:\n', fontSize: 6},
                     {text: this.docBase.textFormat(d?.numero), fontSize: 8, bold: true}],
             border: [true, true, true, false]},
             {text: [{text: 'COMPLEMENTO:\n', fontSize: 6},
                     {text: this.docBase.telefoneFormat(d?.complemento), fontSize: 8, bold: true}],
             border: [true, true, true, false]}],
          ]
        },
        layout: {
          defaultBorder: false,
        }
        
      };

      dadosLinha5 = 	{
        margin: [0, 0, 0, 0],
        table: {
          widths: [50, 200, 100, '*'],
          body: [
            [{text: [{text: 'UF:\n', fontSize: 6},
                     {text: this.docBase.textFormat(d?.uf), fontSize: 8, bold: true}],
             border: [true, true, true, true]},
             {text: [{text: 'MUNICÍPIO:\n', fontSize: 6},
                     {text: this.docBase.textFormat(d?.municipio), fontSize: 8, bold: true}],
             border: [true, true, true, true]},
             {text: [{text: 'CEP:\n', fontSize: 6},
                     {text: this.docBase.cepFormat(d?.cep), fontSize: 8, bold: true}],
             border: [true, true, true, true]},
             {text: [{text: 'TELEFONE:\n', fontSize: 6},
                     {text: this.docBase.telefoneFormat(d?.telefone), fontSize: 8, bold: true}],
             border: [true, true, true, true]}],
          ]
        },
        layout: {
          defaultBorder: false,
        }
        
      };

      assinatura = 	{
        margin: [0, 0, 0, 0],
        table: {
          dontBreakRows: true,
          widths: ['*'],
          body: [
            [{text: [
              {text: '\n\n\n............................................................ , ............ de ....................................... de ..................\n'},
              {text: '\n\n\n____________________________________________________\n'},
              {text: `${d?.nome} ${d?.sobrenome}`, bold: true}
            ],
             fontSize: 10, alignment: 'center'}]
          ]
        },
        layout: 'noBorders'
      };
    }

    let declaracao, corpo, declaracao2;

    if (d?.modalidade == 'CARBONO_0'){
      declaracao = 	{
        margin: [0, 20, 0, 20],
        table: {
          widths: ['*'],
          body: [
            [{text: 'Pelo presente instrumento, formaliza voluntariamente o compromisso de atingir a neutralização de emissões até 2035, com meta intermediária de redução de 80% das emissões até 2030, nos termos do Decreto nº 1.160, de 25 de outubro de 2021, e cumprimento do disposto na Instrução Normativa SEMA nº 05/2022.',
                      fontSize: 10, bold: true, fillColor: "#EEEEEE", alignment: 'center'}
            ]
          ]
        }
      };

      corpo = 	{text: 
        [
          {text: '\n\nI – DO OBJETO',
          fontSize: 10, bold: true},
          {text: '\n\n\nCLÁUSULA PRIMEIRA',
          fontSize: 10, bold: true, decoration: 'underline'},
          {text: ' – O presente Termo de Compromisso Voluntário tem por objeto a manifestação de vontade em realizar ações visando atingir a neutralização de emissões até 2035, com meta intermediária de redução de 80% das emissões até 2030, no(s) empreendimento(s) descritos no Formulário de Adesão, parte integrante do presente processo.',
          fontSize: 10},
          {text: '\n\n\n\nII – DAS OBRIGAÇÕES',
          fontSize: 10, bold: true},
          {text: '\n\n\nCLÁUSULA SEGUNDA',
          fontSize: 10, bold: true, decoration: 'underline'},
          {text: ' – O(A) COMPROMISSÁRIO(A), com o objetivo de obter e manter o Selo de Compromissário, se compromete a executar as ações abaixo relacionadas:',
          fontSize: 10},
          {text: '\n\nI – Apresentar à SEMA/MT o Plano de Ação voltado a neutralização de emissões até 2035, com meta intermediária de redução de 80% das emissões até 2030, nos moldes no Termo de Referência Padrão, no prazo de 1 (um) ano a contar da assinatura do presente instrumento;',
          fontSize: 10},
          {text: '\n\nII -  Observar as normas ambientais relativas a regularização ambiental e uso dos recursos naturais, obtendo todos os atos autorizativos necessários ao exercício das atividades vinculadas ao empreendimento;',
          fontSize: 10},
          {text: '\n\nIII – Apresentar Relatórios Anuais, todo mês de maio, acerca dos avanços na execução das ações voltadas ao atingimento da meta de neutralização de emissões até 2035, com meta intermediária de redução de 80% das emissões até 2030, nos moldes no Termo de Referência Padrão;',
          fontSize: 10},
          {text: '\n\nIV – Apresentar informações solicitadas pela SEMA, acerca das ações vinculadas ao Termo de Compromisso Voluntario do Programa Carbono Neutro MT.',
          fontSize: 10},
          {text: 'III - DO INADIMPLEMENTO',
          fontSize: 10, bold: true},
          {text: '\n\n\nCLÁUSULA TERCEIRA',
          fontSize: 10, bold: true, decoration: 'underline'},
          {text: ' – O descumprimento de qualquer das obrigações assumidas voluntariamente pelo(a) COMPROMISSÁRIO(A), não acarreta penalidade, contudo, implica no cancelamento do Selo Compromissário outorgado.',
          fontSize: 10},
        ], alignment: 'justify'
      }; 

      declaracao2 = 	{
        margin: [0, 20, 0, 20],
        table: {
          pageBreak: 'before',
          widths: ['*'],
          body: [
            [{text: 'Firma o presente Termo de Compromisso Voluntário para que gere seus efeitos jurídicos, nos termos da Instrução Normativa nº 05/2022.',
                      fontSize: 10, bold: true, fillColor: "#EEEEEE", alignment: 'center'}
            ]
          ]
        }
      };

    }
    if (d?.modalidade == 'APOIADOR'){
      declaracao = 	{
        margin: [0, 20, 0, 20],
        table: {
          widths: ['*'],
          body: [
            [{text: 'Pelo presente instrumento, formaliza voluntariamente o compromisso de apoiar a divulgação e implementação do programa Carbono Neutro MT, com objetivo de neutralização de emissões até 2035, com meta intermediária de redução de 80% das emissões até 2030, nos termos do Decreto nº 1.160, de 25 de outubro de 2021.',
                      fontSize: 10, bold: true, fillColor: "#EEEEEE", alignment: 'center'}
            ]
          ]
        }
      };

      corpo = 	{text: 
        [
          {text: '\n\nI – DO OBJETO',
          fontSize: 10, bold: true},
          {text: '\n\n\nCLÁUSULA PRIMEIRA',
          fontSize: 10, bold: true, decoration: 'underline'},
          {text: ' – O presente Termo de Compromisso Voluntário tem por objeto a manifestação de vontade em realizar ações visando apoiar a divulgação, fomentar a adesão e implementação do programa Carbono Neutro MT, nos termos do Decreto nº 1.160, de 25 de outubro de 2021, e cumprimento do disposto na Instrução Normativa SEMA nº 05/2022',
          fontSize: 10},
          {text: '\n\n\n\nII – DAS OBRIGAÇÕES',
          fontSize: 10, bold: true},
          {text: '\n\n\nCLÁUSULA SEGUNDA',
          fontSize: 10, bold: true, decoration: 'underline'},
          {text: ' – O(A) COMPROMISSÁRIO(A), com o objetivo de obter e manter o Selo de Apoiador, se compromete a executar as ações abaixo relacionadas:',
          fontSize: 10},
          {text: '\n\nI – Apresentar à SEMA/MT o Plano de Ação indicando as ações a serem realizadas com o objetivo de divulgar, fomentar a adesão e implementar o programa Carbono Neutro MT, nos moldes no Termo de Referência Padrão, no prazo de 1 (um) ano a contar da assinatura do presente instrumento;',
          fontSize: 10},
          {text: '\n\nII – Apresentar Relatórios Anuais, todo mês de maio, acerca dos avanços na execução das ações realizadas apoiando a divulgação, adesão e implementação do programa Carbono Neutro MT;',
          fontSize: 10},
          {text: '\n\nIII – Apresentar informações sempre que solicitadas pela SEMA, acerca das ações vinculadas ao Termo de Compromisso Voluntario do Programa Carbono Neutro MT.',
          fontSize: 10},
          {text: 'III – DO INADIMPLEMENTO',
          fontSize: 10, bold: true},
          {text: '\n\n\nCLÁUSULA TERCEIRA',
          fontSize: 10, bold: true, decoration: 'underline'},
          {text: ' – O descumprimento de qualquer das obrigações assumidas voluntariamente pelo(a) COMPROMISSÁRIO(A), não acarreta penalidade, contudo, implica no cancelamento do Selo de Apoiador outorgado.\n\n',
          fontSize: 10},
        ], alignment: 'justify'
      }; 

      declaracao2 = 	{
        margin: [0, 20, 0, 20],
        table: {
          widths: ['*'],
          pageBreak: 'before',
          body: [
            [{text: 'Firma o presente Termo de Compromisso Voluntário para que gere seus efeitos jurídicos, nos termos da Instrução Normativa nº 05/2022.',
                      fontSize: 10, bold: true, fillColor: "#EEEEEE", alignment: 'center'}
            ]
          ]
        }
      };

    }
    if (d?.modalidade == 'COMPROMISSARIO' || d?.modalidade == 'CARBONO_0'){
      declaracao = 	{
        margin: [0, 20, 0, 20],
        table: {
          widths: ['*'],
          body: [
            [{text: 'Pelo presente instrumento, formaliza voluntariamente o compromisso de atingir a neutralização de emissões até 2035, com meta intermediária de redução de 80% das emissões até 2030, nos termos do Decreto nº 1.160, de 25 de outubro de 2021, e cumprimento do disposto na Instrução Normativa SEMA nº 05/2022.',
                      fontSize: 10, bold: true, fillColor: "#EEEEEE", alignment: 'center'}
            ]
          ]
        }
      };

      corpo = 	{text: 
        [
          {text: '\n\nI – DO OBJETO',
          fontSize: 10, bold: true},
          {text: '\n\n\nCLÁUSULA PRIMEIRA',
          fontSize: 10, bold: true, decoration: 'underline'},
          {text: ' – O presente Termo de Compromisso Voluntário tem por objeto a manifestação de vontade em realizar ações visando atingir a neutralização de emissões até 2035, com meta intermediária de redução de 80% das emissões até 2030, no(s) empreendimento(s) descritos no Formulário de Adesão, parte integrante do presente processo.',
          fontSize: 10},
          {text: '\n\n\n\nII – DAS OBRIGAÇÕES',
          fontSize: 10, bold: true},
          {text: '\n\n\nCLÁUSULA SEGUNDA',
          fontSize: 10, bold: true, decoration: 'underline'},
          {text: ' – O(A) COMPROMISSÁRIO(A), com o objetivo de obter e manter o Selo de Compromissário, se compromete a executar as ações abaixo relacionadas:',
          fontSize: 10},
          {text: '\n\nI – Apresentar à SEMA/MT o inventário de gases de efeito estufa e o Plano de Ação voltado a neutralização de emissões até 2035, com meta intermediária de redução de 80% das emissões até 2030, nos moldes no Termo de Referência Padrão, no prazo de 1 (um) ano a contar da assinatura do presente instrumento;',
          fontSize: 10},
          {text: '\n\nII – Observar as normas ambientais relativas a regularização ambiental e uso dos recursos naturais, obtendo todos os atos autorizativos necessários ao exercício das atividades vinculadas ao empreendimento;',
          fontSize: 10},
          {text: '\n\nIII – Apresentar Relatórios Anuais, todo mês de maio, acerca dos avanços na execução das ações voltadas ao atingimento da meta de neutralização de emissões até 2035, com meta intermediária de redução de 80% das emissões até 2030, nos moldes no Termo de Referência Padrão;',
          fontSize: 10},
          {text: '\n\nIV – Apresentar informações solicitadas pela SEMA, acerca das ações vinculadas ao Termo de Compromisso Voluntario do Programa Carbono Neutro MT.',
          fontSize: 10},
          {text: 'III - DO INADIMPLEMENTO',
          fontSize: 10, bold: true},
          {text: '\n\n\nCLÁUSULA TERCEIRA',
          fontSize: 10, bold: true, decoration: 'underline'},
          {text: ' – O descumprimento de qualquer das obrigações assumidas voluntariamente pelo(a) COMPROMISSÁRIO(A), não acarreta penalidade, contudo, implica no cancelamento do Selo de Compromissário outorgado.',
          fontSize: 10},
        ], alignment: 'justify'
      }; 

      declaracao2 = 	{
        margin: [0, 20, 0, 20],
        table: {
          widths: ['*'],
          pageBreak: 'before',
          body: [
            [{text: 'Firma o presente Termo de Compromisso Voluntário para que gere seus efeitos jurídicos, nos termos da Instrução Normativa nº 05/2022.',
                      fontSize: 10, bold: true, fillColor: "#EEEEEE", alignment: 'center'}
            ]
          ]
        }
      };

    }
    if (d?.modalidade == 'FINANCIADOR'){
      declaracao = 	{
        margin: [0, 20, 0, 20],
        table: {
          widths: ['*'],
          body: [
            [{text: 'Pelo presente instrumento, formaliza voluntariamente o compromisso de financiar ações voltadas a implementação do programa Carbono Neutro MT, com objetivo de neutralização de emissões até 2035, com meta intermediária de redução de 80% das emissões até 2030, nos termos do Decreto nº 1.160, de 25 de outubro de 2021.',
                      fontSize: 10, bold: true, fillColor: "#EEEEEE", alignment: 'center'}
            ]
          ]
        }
      };

      corpo = 	{text: 
        [
          {text: '\n\nI – DO OBJETO',
          fontSize: 10, bold: true},
          {text: '\n\n\nCLÁUSULA PRIMEIRA',
          fontSize: 10, bold: true, decoration: 'underline'},
          {text: ' – O presente Termo de Compromisso Voluntário tem por objeto a manifestação de vontade em financiar ações voltadas a implementação do programa Carbono Neutro MT, nos termos do Decreto nº 1.160, de 25 de outubro de 2021, e cumprimento do disposto na Instrução Normativa SEMA nº 05/2022.',
          fontSize: 10},
          {text: '\n\n\n\nII – DAS OBRIGAÇÕES',
          fontSize: 10, bold: true},
          {text: '\n\n\nCLÁUSULA SEGUNDA',
          fontSize: 10, bold: true, decoration: 'underline'},
          {text: ' – O(A) COMPROMISSÁRIO(A), com o objetivo de obter e manter o Selo de Financiador, se compromete a executar as ações abaixo relacionadas:',
          fontSize: 10},
          {text: '\n\nI – Apresentar à SEMA/MT, juntamente com o presente termo de compromisso a documentação comprovando a contratação realizada visando o apoio financeiro a ações vinculadas ao Programa Carbono Neutro MT;',
          fontSize: 10},
          {text: '\n\nII – Apresentar Relatórios Anuais, todo mês de maio, demonstrando os desembolsos realizados; as ações financiadas e os avanços na execução das ações vinculadas ao programa Carbono Neutro MT;',
          fontSize: 10},
          {text: '\n\nIII – Apresentar informações sempre que solicitadas pela SEMA, acerca das ações vinculadas ao Termo de Compromisso Voluntario do Programa Carbono Neutro MT.',
          fontSize: 10},
          {text: 'III – DO INADIMPLEMENTO',
          fontSize: 10, bold: true},
          {text: '\n\n\nCLÁUSULA TERCEIRA',
          fontSize: 10, bold: true, decoration: 'underline'},
          {text: ' – O descumprimento de qualquer das obrigações assumidas voluntariamente pelo(a) COMPROMISSÁRIO(A), não acarreta penalidade, contudo, implica no cancelamento do Selo de Financiador outorgado.\n\n',
          fontSize: 10},
        ], alignment: 'justify'
      }; 

      declaracao2 = 	{
        margin: [0, 20, 0, 20],
        table: {
          widths: ['*'],
          pageBreak: 'before',
          body: [
            [{text: 'Firma o presente Termo de Compromisso Voluntário para que gere seus efeitos jurídicos, nos termos da Instrução Normativa nº 05/2022.',
                      fontSize: 10, bold: true, fillColor: "#EEEEEE", alignment: 'center'}
            ]
          ]
        }
      };

    }

    this.makePdf.setParamPage({
      header: this.docBase.getHeader(),
      footer: (currentPage, pageCount) => this.docBase.getFooter('Termo de Compromisso Voluntário | Programa Carbono Neutro MT', d?.protocolo, currentPage, pageCount, false),
      pageSize: 'A4',
      pageMargins: [40, 100, 40, 60],
      styles: {
        title: {
          fontSize: 13,
          bold: true,
          alignment: 'center',
          decoration: 'underline'
        },
        center: {
          alignment: 'center'
        }
      },
      defaultStyle: {
        p: {
          fontSize: 11,
          alignment: 'justify'
        }
      }
    });
    return [
      nomePrograma,
      titulo,
      dadosLinha1,
      dadosLinha2,
      dadosLinha3,
      dadosLinha4,
      dadosLinha5,
      dadosLinha6,
      declaracao,
      corpo,
      declaracao2,
      assinatura
    ];
  }

}
