import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DgCardComponent } from './dg-card.component';



@NgModule({
  declarations: [DgCardComponent],
  imports: [
    CommonModule
  ],
  exports: [DgCardComponent]
})
export class DgCardModule { }
