import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AbstractHttpService } from 'src/app/services/http/abstract-http.service';
import { RecuperaTokenService } from 'src/app/services/recupera-token.service';
import { AppUtils } from 'src/app/utils/app-utils';
import { ConfiguracaoEntity } from '../model/configuracao.entity';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracaoService extends AbstractHttpService<ConfiguracaoEntity>{

  constructor(http: HttpClient, private util: AppUtils, protected recuperaToken: RecuperaTokenService, protected activatedRoute: ActivatedRoute, protected cookieService: CookieService) {
    super(http, util.getBaseUrlWebService(), `/api/v1/carbono/neutro/configuracao`, recuperaToken, activatedRoute, cookieService);
  }
}
