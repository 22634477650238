import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
// import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatTable, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DgDataTableComponent } from './dg-data-table.component';
import { CamelCaseToSentenceCasePipe } from './pipe/camel-case-to-sentence-case.pipe';
import { DgConfirmDialogModule } from '../dg-confirm-dialog/dg-confirm-dialog.module';
import { TableDynamicPipe } from './pipe/table-dynamic.pipe';
import { getPtBrPaginatorIntl } from 'src/app/translate/pt-br-paginator-intl';
import { MatSelectModule } from '@angular/material/select';
import { DateAdapter } from '@angular/material/core';
import { TipoDocumentoPipe } from './pipe/tipo-documento.pipe';
import { NgxMaskModule, IConfig } from 'ngx-mask'

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

export const DEFAULT_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [DgDataTableComponent, CamelCaseToSentenceCasePipe, TableDynamicPipe, TipoDocumentoPipe],
  imports: [
    CommonModule,
    MatTableModule,
    MatTooltipModule,
    MatSortModule,
    MatPaginatorModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatMenuModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    MatDialogModule,
    DgConfirmDialogModule,
    MatSelectModule,
    MatDividerModule,
    MatDatepickerModule,
    NgxMaskModule.forRoot(),
    MatTooltipModule
  ],
  providers: [
    DatePipe,
    {provide: MatPaginatorIntl, useValue: getPtBrPaginatorIntl()},
    TipoDocumentoPipe,
    // {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    {provide: MAT_DATE_FORMATS, useValue: DEFAULT_DATE_FORMATS}
  ],
  exports: [DgDataTableComponent ]
})
export class DgDataTableModule {
}
