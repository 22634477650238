import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfiguracaoMainComponent } from './configuracao-main/configuracao-main.component';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMaskModule } from 'ngx-mask';
import { DgCardModule } from 'src/app/components/dg-shared/dg-card/dg-card.module';
import { DgInsertEditModule } from 'src/app/components/dg-shared/dg-crud/dg-insert-edit/dg-insert-edit.module';
import { DgDataTableModule } from 'src/app/components/dg-shared/dg-data-table/dg-data-table.module';
import { DgSharedModule } from 'src/app/components/dg-shared/dg-shared.module';
import { LoadingSemaModule } from 'src/app/components/loading-sema/loading-sema.module';


@NgModule({
  declarations: [ConfiguracaoMainComponent],
  imports: [
    CommonModule,
    FormsModule,
    DgCardModule,
    DgDataTableModule,
    DgInsertEditModule,
    DgSharedModule,
  
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatRadioModule,
    MatStepperModule,
    MatTooltipModule,
    MatDividerModule,
    LoadingSemaModule,
    NgxMaskModule.forChild(),
  ]
})
export class ConfiguracaoModule { }
