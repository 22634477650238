import { Injectable } from '@angular/core';
import * as moment from 'moment';

import {commandFunction} from 'ngx-wig';
import { CategoriaSelo } from 'src/app/enum/categoria-selo.enum';
import { CadastroSeloEntity } from 'src/app/pages/cadastro-selo/model/cadastro-selo.entity';

import { PdfMakeService } from 'src/app/services/pdf-make.service';
import { BaseAutosTermosService, interfaceAssinatura } from '../base-autos-termos/base-autos-termos.service';

// tslint:disable-next-line:class-nam

@Injectable({
  providedIn: 'root'
})
export class FormCarbonoNeutroService {

  categoriaEnum = CategoriaSelo;

  constructor(private makePdf: PdfMakeService, private docBase: BaseAutosTermosService) {}

  public open(d?: CadastroSeloEntity, tipo?: 'PF' | 'PJ') {
    this.makePdf.open(this.corpoDocumento(d, tipo));
  }

  public base64(callback: commandFunction, dados?: CadastroSeloEntity, tipo?: 'PF' | 'PJ') {
    this.makePdf.base64(
      this.corpoDocumento(dados, tipo),
      (data) => {
        callback(data);
      }
    );
  }

  private corpoDocumento(d?: CadastroSeloEntity, tipo?) {
    const nomePrograma = 	{
      margin: [0, 5, 0, 0],
      table: {
				widths: ['*'],
				body: [
					[{text: 'FORMULÁRIO DE ADESÃO', fontSize: 14, bold: true, fillColor: "#EEEEEE", alignment: 'center'}]
				]
			}
    };

    const titulo = {
      alignment: 'center',
      margin: [0, 5, 0, 0],
      table: {
				widths: [300, '*', '*'],
				body: [
					[{rowSpan: 2, text: `${this.categoriaEnum[d.modalidade]} - ${tipo}`, fontSize: 23, bold: true},
           {text: 'PROTOCOLO Nº:', fontSize: 8},
           {text: 'DATA:', fontSize: 8}],
          ['',
           {text: d?.protocolo, fontSize: 12, bold: true},
           {text: (moment(d.dataInclusao)).format('DD/MM/YYYY'), fontSize: 12, bold: true}],
				]
			}
    };

    let dadosLinha1, dadosLinha2, dadosLinha3, dadosLinha4, dadosLinha5, dadosLinha6, assinatura;

    if (tipo == 'PJ'){

      dadosLinha1 = 	{
        margin: [0, 20, 0, 0],
        table: {
          widths: ['*'],
          body: [
            [{text: [{text: 'RAZÃO SOCIAL:\n', fontSize: 6},
                     {text: d?.razaoSocial, fontSize: 8, bold: true}],
             border: [true, true, true, false]}]
          ]
        },
        layout: {
          defaultBorder: false,
        }
      };

      dadosLinha2 = 	{
        margin: [0, 0, 0, 0],
        table: {
          widths: ['*', '*'],
          body: [
            [{text: [{text: 'CNPJ:\n', fontSize: 6},
                     {text: this.docBase.cpfCnpfFormat(d?.cpfCnpj), fontSize: 8, bold: true}],
             border: [true, true, true, false]},
             {text: [{text: 'EMAIL:\n', fontSize: 6},
                     {text: d?.email, fontSize: 8, bold: true}],
             border: [true, true, true, false]}]
          ]
        },
        layout: {
          defaultBorder: false,
        }
      };

      dadosLinha3 = 	{
        margin: [0, 0, 0, 0],
        table: {
          widths: [200, '*', '*'],
          body: [
            [{text: [{text: 'NOME DO REPRESENTANTE:\n', fontSize: 6},
                     {text: d?.nomeRepresentante, fontSize: 8, bold: true}],
             border: [true, true, true, false]},
             {text: [{text: 'CPF DO REPRESENTANTE:\n', fontSize: 6},
                     {text: this.docBase.cpfCnpfFormat(d?.cpfRepresentante), fontSize: 8, bold: true}],
             border: [true, true, true, false]},
             {text: [{text: 'EMAIL DO REPRESENTANTE:\n', fontSize: 6},
                     {text: d?.emailRepresentante, fontSize: 8, bold: true}],
             border: [true, true, true, false]}],
          ]
        },
        layout: {
          defaultBorder: false,
        }
        
      };
  
      dadosLinha4 = 	{
        margin: [0, 0, 0, 0],
        table: {
          widths: ['*'],
          body: [
            [{text: [{text: 'LOGRADOURO:\n', fontSize: 6},
                     {text: this.docBase.textFormat(d?.logradouro), fontSize: 8, bold: true}],
             border: [true, true, true, false]}]
          ]
        },
        layout: {
          defaultBorder: false,
        }
      };
  
      dadosLinha5 = 	{
        margin: [0, 0, 0, 0],
        table: {
          widths: ['*', '*', '*'],
          body: [
            [{text: [{text: 'BAIRRO:\n', fontSize: 6},
                     {text: this.docBase.textFormat(d?.bairro), fontSize: 8, bold: true}],
             border: [true, true, true, false]},
             {text: [{text: 'NÚMERO:\n', fontSize: 6},
                     {text: this.docBase.textFormat(d?.numero), fontSize: 8, bold: true}],
             border: [true, true, true, false]},
             {text: [{text: 'COMPLEMENTO:\n', fontSize: 6},
                     {text: this.docBase.telefoneFormat(d?.complemento), fontSize: 8, bold: true}],
             border: [true, true, true, false]}],
          ]
        },
        layout: {
          defaultBorder: false,
        }
        
      };

      dadosLinha6 = 	{
        margin: [0, 0, 0, 0],
        table: {
          widths: [50, 200, 100, '*'],
          body: [
            [{text: [{text: 'UF:\n', fontSize: 6},
                     {text: this.docBase.textFormat(d?.uf), fontSize: 8, bold: true}],
             border: [true, true, true, true]},
             {text: [{text: 'MUNICÍPIO:\n', fontSize: 6},
                     {text: this.docBase.textFormat(d?.municipio), fontSize: 8, bold: true}],
             border: [true, true, true, true]},
             {text: [{text: 'CEP:\n', fontSize: 6},
                     {text: this.docBase.cepFormat(d?.cep), fontSize: 8, bold: true}],
             border: [true, true, true, true]},
             {text: [{text: 'TELEFONE:\n', fontSize: 6},
                     {text: this.docBase.telefoneFormat(d?.telefone), fontSize: 8, bold: true}],
             border: [true, true, true, true]}],
          ]
        },
        layout: {
          defaultBorder: false,
        }
        
      };

      assinatura = 	{
        margin: [0, 0, 0, 0],
        table: {
          dontBreakRows: true,
          widths: ['*'],
          body: [
            [{text: [
              {text: '\n\n\n............................................................ , ............ de ....................................... de ..................\n'},
              {text: '\n\n\n____________________________________________________\n'},
              {text: `${d?.nomeRepresentante}`, bold: true}
            ],
             fontSize: 10, alignment: 'center'}]
          ]
        },
        layout: 'noBorders'
      };
    } else{

      dadosLinha1 = 	{
        margin: [0, 20, 0, 0],
        table: {
          widths: ['*', 250, '*'],
          body: [
            [{text: [{text: 'NOME:\n', fontSize: 6},
                     {text: d?.nome, fontSize: 8, bold: true}],
             border: [true, true, true, false]},
             {text: [{text: 'SOBRENOME:\n', fontSize: 6},
                     {text: d?.sobrenome, fontSize: 8, bold: true}],
             border: [true, true, true, false]},
             {text: [{text: 'CPF:\n', fontSize: 6},
                     {text: this.docBase.cpfCnpfFormat(d?.cpfCnpj), fontSize: 8, bold: true}],
             border: [true, true, true, false]}]
          ]
        },
        layout: {
          defaultBorder: false,
        }
      };
  
      dadosLinha3 = 	{
        margin: [0, 0, 0, 0],
        table: {
          widths: ['*'],
          body: [
            [{text: [{text: 'LOGRADOURO:\n', fontSize: 6},
                     {text: this.docBase.textFormat(d?.logradouro), fontSize: 8, bold: true}],
             border: [true, true, true, false]}]
          ]
        },
        layout: {
          defaultBorder: false,
        }
      };
  
      dadosLinha4 = 	{
        margin: [0, 0, 0, 0],
        table: {
          widths: ['*', '*', '*'],
          body: [
            [{text: [{text: 'BAIRRO:\n', fontSize: 6},
                     {text: this.docBase.textFormat(d?.bairro), fontSize: 8, bold: true}],
             border: [true, true, true, false]},
             {text: [{text: 'NÚMERO:\n', fontSize: 6},
                     {text: this.docBase.textFormat(d?.numero), fontSize: 8, bold: true}],
             border: [true, true, true, false]},
             {text: [{text: 'COMPLEMENTO:\n', fontSize: 6},
                     {text: this.docBase.telefoneFormat(d?.complemento), fontSize: 8, bold: true}],
             border: [true, true, true, false]}],
          ]
        },
        layout: {
          defaultBorder: false,
        }
        
      };

      dadosLinha5 = 	{
        margin: [0, 0, 0, 0],
        table: {
          widths: [50, 200, 100, '*'],
          body: [
            [{text: [{text: 'UF:\n', fontSize: 6},
                     {text: this.docBase.textFormat(d?.uf), fontSize: 8, bold: true}],
             border: [true, true, true, true]},
             {text: [{text: 'MUNICÍPIO:\n', fontSize: 6},
                     {text: this.docBase.textFormat(d?.municipio), fontSize: 8, bold: true}],
             border: [true, true, true, true]},
             {text: [{text: 'CEP:\n', fontSize: 6},
                     {text: this.docBase.cepFormat(d?.cep), fontSize: 8, bold: true}],
             border: [true, true, true, true]},
             {text: [{text: 'TELEFONE:\n', fontSize: 6},
                     {text: this.docBase.telefoneFormat(d?.telefone), fontSize: 8, bold: true}],
             border: [true, true, true, true]}],
          ]
        },
        layout: {
          defaultBorder: false,
        }
        
      };

      assinatura = 	{
        margin: [0, 0, 0, 0],
        table: {
          dontBreakRows: true,
          widths: ['*'],
          body: [
            [{text: [
              {text: '\n\n\n............................................................ , ............ de ....................................... de ..................\n'},
              {text: '\n\n\n____________________________________________________\n'},
              {text: `${d?.nome} ${d?.sobrenome}`, bold: true}
            ],
             fontSize: 10, alignment: 'center'}]
          ]
        },
        layout: 'noBorders'
      };
    }

    let declaracao, declaracao2;

    if (d?.modalidade == 'CARBONO_0'){
      declaracao = 	{
        margin: [0, 20, 0, 0],
        table: {
          widths: ['*'],
          body: [
            [{text: 'Pelo presente instrumento, formaliza voluntariamente a adesão ao Programa Carbono Neutro MT, na categoria Carbono 0%, para a(s) propriedade(s) e/ou  o(s) empreendimento(s) declarado(s) abaixo.', fontSize: 10, bold: true, fillColor: "#EEEEEE", alignment: 'center'}]
          ]
        }
      };

      declaracao2 = 	{
        margin: [0, 20, 0, 0],
        table: {
          widths: ['*'],
          body: [
            [{text: 'O proponente declara ciência de que a obtenção do Selo Carbono 0% está condicionada à assinatura do Termo de Compromisso Voluntário de atingir a neutralização de emissões até 2035, com meta intermediária de redução de 80% das emissões até 2030, nos termos do Decreto nº 1.160/2021, e cumprimento do disposto na Instrução Normativa SEMA nº 05/2022.', fontSize: 10, bold: true, fillColor: "#EEEEEE", alignment: 'center'}]
          ]
        }
      };
    }
    if (d?.modalidade == 'APOIADOR'){
      declaracao = 	{
        margin: [0, 20, 0, 0],
        table: {
          widths: ['*'],
          body: [
            [{text: 'Pelo presente instrumento, formaliza voluntariamente a adesão ao Programa Carbono Neutro MT, na categoria Apoiador, por possuir aderência a política institucional de sustentabilidade da instituição, assumindo o compromisso de apoiar a divulgação e implementação do programa, nos termos do Decreto nº 1.160/2021.', fontSize: 10, bold: true, fillColor: "#EEEEEE", alignment: 'center'}]
          ]
        }
      };

      declaracao2 = 	{
        margin: [0, 20, 0, 0],
        table: {
          widths: ['*'],
          body: [
            [{text: 'O proponente declara ciência de que a obtenção do Selo Apoiador está condicionada à assinatura do Termo de Compromisso Voluntário de Apoiador ao Programa Carbono Neutro MT e cumprimento do disposto na Instrução Normativa SEMA nº 05/2022.', fontSize: 10, bold: true, fillColor: "#EEEEEE", alignment: 'center'}]
          ]
        }
      };
    }
    if (d?.modalidade == 'COMPROMISSARIO'){
      declaracao = 	{
        margin: [0, 20, 0, 0],
        table: {
          widths: ['*'],
          body: [
            [{text: 'Pelo presente instrumento, formaliza voluntariamente a adesão ao Programa Carbono Neutro MT, na categoria Compromissário, para a(s) propriedade(s) e/ou  o(s) empreendimento(s) declarado(s) abaixo.', fontSize: 10, bold: true, fillColor: "#EEEEEE", alignment: 'center'}]
          ]
        }
      };

      declaracao2 = 	{
        margin: [0, 20, 0, 0],
        table: {
          widths: ['*'],
          body: [
            [{text: 'O proponente declara ciência de que a obtenção do Selo Compromissário está condicionada à assinatura do Termo de Compromisso Voluntário de atingir a neutralização de emissões até 2035, com meta intermediária de redução de 80% das emissões até 2030, nos termos do Decreto nº 1.160/2021, e cumprimento do disposto na Instrução Normativa SEMA nº 05/2022.', fontSize: 10, bold: true, fillColor: "#EEEEEE", alignment: 'center'}]
          ]
        }
      };
    }
    if (d?.modalidade == 'FINANCIADOR'){
      declaracao = 	{
        margin: [0, 20, 0, 0],
        table: {
          widths: ['*'],
          body: [
            [{text: 'Pelo presente instrumento, formaliza voluntariamente a adesão ao Programa Carbono Neutro MT, na categoria Financiador, em razão da empresa promover o apoio financeiro ao Programa Carbono Neutro MT, nos termos exigidos pelo Decreto nº 1.160/2021.', fontSize: 10, bold: true, fillColor: "#EEEEEE", alignment: 'center'}]
          ]
        }
      };

      declaracao2 = 	{
        margin: [0, 20, 0, 0],
        table: {
          widths: ['*'],
          body: [
            [{text: 'O proponente declara ciência de que a obtenção do Selo Financiador está condicionada à demonstração do efetivo apoio financeiro vinculado ao Programa Carbono Neutro MT, assinatura do Termo de Compromisso Voluntário de Financiador e cumprimento do disposto na Instrução Normativa SEMA nº 05/2022.', fontSize: 10, bold: true, fillColor: "#EEEEEE", alignment: 'center'}]
          ]
        }
      };
    }

    let propriedadeRurais;

    if(d.propriedades){
      propriedadeRurais = 	{
        margin: [0, 20, 0, 0],
        table: {
          widths: ['*'],
          body: [
            [{text: 'PROPRIEDADES RURAIS:', fontSize: 8, bold: true, fillColor: "#EEEEEE"}]
          ]
        }
      };
    }


    let propriedadesRuraisLista = [];
    let listPpropriedades = d.propriedades?d.propriedades:[]; 
    listPpropriedades.forEach((propriedade, index) => {

      const especificacao = {
        margin: [0, 5, 0, 0],
        table: {
          headerRows: 1,
          dontBreakRows: true,
          keepWithHeaderRows: 10,
          widths: ['*', '*'],
          body: [
            [{text: [{text: 'ATIVIDADE:\n', fontSize: 6},
                     {text: this.docBase.textFormat(propriedade?.atividade), fontSize: 8, bold: true}],
              colSpan: 2}, ''],
            [{text: [{text: 'CAR (SIMCAR) Nº:\n', fontSize: 6}, 
                     {text: this.docBase.textFormat(propriedade?.car), fontSize: 8, bold: true}]},
             {text: [{text: 'IDENTIFICAÇÃO DO IMÓVEL:\n', fontSize: 6}, 
                     {text: this.docBase.textFormat(propriedade?.identificacao), fontSize: 8, bold: true}]}],
            [{text: [{text: 'LICENÇAS AMBIENTAIS:\n', fontSize: 6}, 
                     {text: this.docBase.textFormat(propriedade?.licencas), fontSize: 8, bold: true}]},
             {text: [{text: 'OUTORGA/CADASTRO :\n', fontSize: 6}, 
                     {text: this.docBase.textFormat(propriedade?.outorga), fontSize: 8, bold: true}]}]
          ]
        }
      };

      propriedadesRuraisLista.push(especificacao)
    });

    let empreendimendosUrbanos;

    if (d.empreendimentos){
      empreendimendosUrbanos = 	{
        margin: [0, 20, 0, 0],
        table: {
          widths: ['*'],
          body: [
            [{text: 'EMPREENDIMENTOS EM ÁREAS URBANAS:', fontSize: 8, bold: true, fillColor: "#EEEEEE"}]
          ]
        }
      };
    }

    let empreendimendosUrbanosLista = [];
    let listEmpreendimentos = d.empreendimentos?d.empreendimentos:[]; 
    listEmpreendimentos.forEach((empreendimento, index) => {

      const especificacao = {
        margin: [0, 5, 0, 0],
        table: {
          headerRows: 1,
          dontBreakRows: true,
          keepWithHeaderRows: 10,
          widths: ['*', '*'],
          body: [
            [{text: [{text: 'EMPREENDIMENTO:\n', fontSize: 6},
                     {text: this.docBase.textFormat(empreendimento?.empreendimento), fontSize: 8, bold: true}],
              colSpan: 2}, ''],
            [{text: [{text: 'ATIVIDADE:\n', fontSize: 6},
                     {text: this.docBase.textFormat(empreendimento?.atividade), fontSize: 8, bold: true}],
              colSpan: 2}, ''],
            [{text: [{text: 'LICENÇAS AMBIENTAIS:\n', fontSize: 6}, 
                     {text: this.docBase.textFormat(empreendimento?.licencas), fontSize: 8, bold: true}]},
             {text: [{text: 'OUTORGA/CADASTRO :\n', fontSize: 6}, 
                     {text: this.docBase.textFormat(empreendimento?.outorga), fontSize: 8, bold: true}]}]
          ]
        }
      };

      empreendimendosUrbanosLista.push(especificacao)
    });

    this.makePdf.setParamPage({
      header: this.docBase.getHeader(),
      footer: (currentPage, pageCount) => this.docBase.getFooter('Formulário de adeção ao Programa Carbono Neutro MT', d?.protocolo, currentPage, pageCount, false),
      pageSize: 'A4',
      pageMargins: [40, 100, 40, 60],
      styles: {
        title: {
          fontSize: 13,
          bold: true,
          alignment: 'center',
          decoration: 'underline'
        },
        center: {
          alignment: 'center'
        }
      },
      defaultStyle: {
        p: {
          fontSize: 11,
          alignment: 'justify'
        }
      }
    });
    return [
      nomePrograma,
      titulo,
      dadosLinha1,
      dadosLinha2,
      dadosLinha3,
      dadosLinha4,
      dadosLinha5,
      dadosLinha6,
      declaracao,
      propriedadeRurais,
      propriedadesRuraisLista,
      empreendimendosUrbanos,
      empreendimendosUrbanosLista,
      declaracao2,
      assinatura
    ];
  }

}
