import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DgFileInputComponent } from './dg-file-input/dg-file-input.component';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { ByteToSizePipe } from './pipe/byte-to-size.pipe';
import { FileNamePipe } from './pipe/file-name.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [DgFileInputComponent, ByteToSizePipe, FileNamePipe],
  imports: [
    CommonModule,
    MatIconModule,
    MatListModule,
    MatTooltipModule,
    MatButtonModule
  ],
  exports: [
    DgFileInputComponent
  ]
})
export class DgFileInputModule { }
