//angular
import { Component, OnInit, Injectable, Inject} from '@angular/core';
import { Router } from '@angular/router';

//material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

//received quadro de avisos
import { ReceivedAvisoService, messagesQuadroEntity } from './service/received-aviso.service';

//DG
import { SnackBarService } from '../../../services/snack-bar.service';


@Injectable({providedIn:'root'})
export class DgReceivedAvisoComponent {

  constructor(
    protected receivedService: ReceivedAvisoService,
    protected dialog: MatDialog,
  ) { }


  abrirDialogReceivedAviso() {

    this.receivedService.messagesInQuadroAviso().subscribe(
      
      data => {
        if (data.values[0] > 0) {

          let dialogRef = this.dialog.open(DgReceivedAvisoDialogComponent, {
            panelClass: 'received-aviso',
            data: data.values[0]
          });

        }
      }
    )

  }

}



@Component({
  selector: 'app-dg-received-dialog-aviso',
  templateUrl: './dg-received-aviso.component.html',
  styleUrls: ['./dg-received-aviso.component.css']
})
export class DgReceivedAvisoDialogComponent implements OnInit {

  constructor(
    protected dialogRef: MatDialogRef<DgReceivedAvisoDialogComponent>,
    protected router: Router,
    @Inject(MAT_DIALOG_DATA) public data: number,
    protected receivedService: ReceivedAvisoService,
    protected alert: SnackBarService,
  ) { }

  ngOnInit(): void {

    this.dialogRef.beforeClosed().subscribe(
      result => {
        this.enviarCienciaDeMensagem();
      }
    )

  }

  navegarQuadroAviso() {
    this.enviarCienciaDeMensagem()
    this.router.navigateByUrl('/quadro/avisos').then();
  }


  //Chama endpoint para sinalizar ciência de novas mensagens no quadro de aviso.
  enviarCienciaDeMensagem(){
    this.receivedService.AwareQuadroAviso().subscribe(
      data => this.alert.message(data.mensagem)
    )
  }

}



