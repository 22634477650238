import { Injectable } from '@angular/core';
import * as moment from 'moment';

import {commandFunction} from 'ngx-wig';
import { CategoriaSelo } from 'src/app/enum/categoria-selo.enum';
import { CadastroSeloEntity } from 'src/app/pages/cadastro-selo/model/cadastro-selo.entity';

import { PdfMakeService } from 'src/app/services/pdf-make.service';
import { BaseAutosTermosService, interfaceAssinatura } from '../base-autos-termos/base-autos-termos.service';
import { compromissario } from './modelo-base64-compromissario';
import { apoiador } from './modelo-base64-apoiador';
import { financiador } from './modelo-base64-financiador';
import { fundo } from './fundo_certificado';
import { selosBase64 } from './selos_base64';
import { assinatura } from './assinatura';
import { logoSema } from './logo-sema';
import { selosEn } from './selos_base64_en';



// tslint:disable-next-line:class-name
interface interfaceDados {
  protocolo?: any; // atributo retirado da apresentação, existencia para retrocompatibilidade
  infracao: any;
  nProcesso: any;
  autoado: any;
  despacho: any;
  analista: any;
  cargoAnalista?: any;
  unidadeAnalista?: any;
  superI: any;
  cargoSuperI?: any;
  unidadeSuperI?: any;
  date: Date;
  texto: any;
  tipoDocumento: any;
}

@Injectable({
  providedIn: 'root'
})
export class CertificadoCarbonoNeutroService {

  constructor(private makePdf: PdfMakeService) { }

  public docFormat(doc: string){
    if (doc.length === 11) {
      return 'CPF: ' + doc.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4');
    } else if(doc.length === 14){
      return 'CNPJ: ' + doc.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4\-\$5');
    } else {
      return doc
    }
  }

  public getDataAtual(){
    const meses = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro'
    ];
    const data = new Date();
    return ((data.getDate() + ' de ' + meses[(data.getMonth())] + ' de ' + data.getFullYear()));
  }

  public getDataAtualEn(){
    const meses = [
      "January", "February", "March", "April", "May", "June", "July",
      "August", "September", "October", "November", "December"
    ];
    const data = new Date();
    return ((meses[(data.getMonth())] + ' ' + data.getDate() + ', ' + data.getFullYear()));
  }

  public open(nome: string, cpfCnpj: string, modalidade: string, cidade: string, empreendimentos: any[], propriedades: any[], urlQrCode: string) {
    this.makePdf.open(this.criaCertificado(nome, cpfCnpj, modalidade, cidade, empreendimentos, propriedades, urlQrCode));
  }

  public base64(callback: commandFunction, nome: string, cpfCnpj: string, modalidade: string, cidade: string, empreendimentos: any[], propriedades: any[], urlQrCode: string) {
    this.makePdf.base64(
      this.criaCertificado(nome, cpfCnpj, modalidade, cidade, empreendimentos, propriedades, urlQrCode),
      (data) => {
        callback(data);
      }
    );
  }

  private criaCertificado(nome: string, cpfCnpj: string, modalidade: string, cidade: string, empreendimentos: any[], propriedades: any[], urlQrCode: string) {
    this.makePdf.setParamPage({
      pageSize: 'A4',
      pageOrientation: 'landscape',
      background: [
        {
            image: 'data:image/jpeg;base64,' + fundo.base64,
            width: 845
        }
      ],
      pageMargins: [0, 0, 0, 0],
      defaultStyle: {
        p: {
          alignment: 'justify'
        }
      }
    });

    let base64Selo = '';

    if (modalidade == 'Carbono 0%'){
      base64Selo = selosBase64.carbono0;
    } else if (modalidade == 'Compromissário'){
      base64Selo = selosBase64.compromissario;
    } else if (modalidade == 'Apoiador'){
      base64Selo = selosBase64.apoiador;
    } else if (modalidade == 'Financiador'){
      base64Selo = selosBase64.financiador;
    }
    
    let selo = {
      absolutePosition: {x:359, y:36},
      image: 'data:image/jpeg;base64,' + base64Selo,
			width: 130
    };

    let certificado = {
      text: 'CERTIFICADO',
      absolutePosition: {x:0, y:165}, alignment: 'center',
      color: '#0f1f66', fontSize: 50, bold: true,
    };

    let corpo = {};

    if (propriedades.length > 0){
      corpo = {
        absolutePosition: {x:50, y:240},
        table: {
          widths: [740],
          body: [
            [
              {
                characterSpacing: 1,
                alignment: 'justify',
                lineHeight: 1.3,
                text: [
                {text: 'O Governo de Mato Grosso, por meio da Secretaria de Estado de Meio Ambiente (Sema-MT), confere o selo:',
                 color: '#5565ad', fontSize: 16},
                {text: ` ${modalidade.toUpperCase()} `, fontSize: 16, color: '#0f1f66', bold: true},
                {text: 'no âmbito do Programa Carbono Neutro MT, instituído pelo Decreto no 1.160, de 25 de outubro de 2021, para',
                 color: '#5565ad', fontSize: 16},
                {text: ` ${nome.toUpperCase()}, ${this.docFormat(cpfCnpj)}, ${propriedades[0].identificacao.toUpperCase()}, CAR Nº:${propriedades[0].car.toUpperCase()}, `,
                 fontSize: 16, bold: true, color: '#0f1f66'},
                {text: 'localizada no município', color: '#5565ad', fontSize: 16},
                {text: ` ${cidade.toUpperCase()}.`, fontSize: 16, color: '#0f1f66', bold: true},
                {text: '\n\nSITUAÇÃO', color: '#0f1f66', fontSize: 16, bold: true},
                {text: `\nATIVO`, fontSize: 16, color: '#5565ad'},
                ]
              }
            ]
          ],
        },
        layout: 'noBorders'
      };
    } else if (empreendimentos.length > 0){
      corpo = {
        absolutePosition: {x:50, y:240},
        table: {
          widths: [740],
          body: [
            [
              {
                characterSpacing: 1,
                alignment: 'justify',
                lineHeight: 1.3,
                text: [
                {text: 'O Governo de Mato Grosso, por meio da Secretaria de Estado de Meio Ambiente (Sema-MT), confere o selo:',
                 color: '#5565ad', fontSize: 16},
                {text: ` ${modalidade.toUpperCase()} `, fontSize: 16, color: '#0f1f66', bold: true},
                {text: 'no âmbito do Programa Carbono Neutro MT, instituído pelo Decreto no 1.160, de 25 de outubro de 2021, para',
                 color: '#5565ad', fontSize: 16},
                {text: ` ${nome.toUpperCase()}, ${this.docFormat(cpfCnpj)}, ${empreendimentos[0].empreendimento.toUpperCase()}, `,
                 fontSize: 16, color: '#0f1f66', bold: true},
                {text: 'localizada no município', color: '#5565ad', fontSize: 16},
                {text: ` ${cidade.toUpperCase()}.`, fontSize: 16, color: '#0f1f66', bold: true},
                {text: '\n\nSITUAÇÃO', color: '#0f1f66', fontSize: 16, bold: true},
                {text: `\nATIVO`, fontSize: 16, color: '#5565ad'},
                ]
              }
            ]
          ],
        },
        layout: 'noBorders'
      };
    } else{
      corpo = {
        absolutePosition: {x:50, y:240},
        table: {
          widths: [740],
          body: [
            [
              {
                characterSpacing: 1,
                alignment: 'justify',
                lineHeight: 1.3,
                text: [
                {text: 'O Governo de Mato Grosso, por meio da Secretaria de Estado de Meio Ambiente (Sema-MT), confere o selo:',
                 color: '#5565ad', fontSize: 16},
                {text: ` ${modalidade.toUpperCase()} `, fontSize: 16, bold: true, color: '#0f1f66',},
                {text: 'no âmbito do Programa Carbono Neutro MT, instituído pelo Decreto no 1.160, de 25 de outubro de 2021, para',
                 color: '#5565ad', fontSize: 16},
                {text: ` ${nome.toUpperCase()}, ${this.docFormat(cpfCnpj)}.`,
                 fontSize: 16, bold: true, color: '#0f1f66',},
                {text: '\n\nSITUAÇÃO', color: '#0f1f66', fontSize: 16, bold: true},
                {text: `\nATIVO`, fontSize: 16, color: '#5565ad'},
                ]
              }
            ]
          ],
        },
        layout: 'noBorders'
      };
    };

    let ass_mauren = {
      absolutePosition: {x:0, y:338}, alignment: 'center',
      image: 'data:image/jpeg;base64,' + assinatura.base64,
			width: 260
    };

    let cargo_mauren = {
      text: 'Secretária de Estado de Meio Ambiente\nMauren Lazzaretti',
      absolutePosition: {x:0, y:415}, alignment: 'center',
      color: '#0f1f66', fontSize: 12, bold: true, characterSpacing: 1
    };

    let logo = {
      absolutePosition: {x:525, y:465},
      image: 'data:image/jpeg;base64,' + logoSema.base64,
			width: 220
    };

    let qrcode = {qr: `${urlQrCode}`, fit: '105', absolutePosition: {x:100, y:465}};

    let data = [
      {characterSpacing: 1, text: 'Cuiabá, ' + this.getDataAtual() + '.', color: '#5565ad', fontSize: 12, absolutePosition: {x:200, y:475}}
    ];

    let textoQR = {
      absolutePosition: {x:200, y:485},
      table: {
				widths: [300],
				body: [
					[
            {text: '\nAcesse o QR Code disponível ao lado para verificar a legitimidade deste documento.',
            characterSpacing: 1, color: '#5565ad', fontSize: 12}
          ]
				],
			},
			layout: 'noBorders'
    };

    return [
      ...data, certificado, qrcode, textoQR, selo, corpo, ass_mauren, cargo_mauren, logo
    ];
  }

  public openEn(nome: string, cpfCnpj: string, modalidade: string, cidade: string, empreendimentos: any[], propriedades: any[], urlQrCode: string) {
    this.makePdf.open(this.criaCertificadoEn(nome, cpfCnpj, modalidade, cidade, empreendimentos, propriedades, urlQrCode));
  }

  public base64En(callback: commandFunction, nome: string, cpfCnpj: string, modalidade: string, cidade: string, empreendimentos: any[], propriedades: any[], urlQrCode: string) {
    this.makePdf.base64(
      this.criaCertificadoEn(nome, cpfCnpj, modalidade, cidade, empreendimentos, propriedades, urlQrCode),
      (data) => {
        callback(data);
      }
    );
  }

  public modalidadeEn(modalidade){
    let map ={
      'Carbono 0%': '0% CARBON',
      'Compromissário': 'COMMITED',
      'Apoiador': 'SUPPORTER',
      'Financiador': 'FINANCIER'
    }

    return map[modalidade]
  }

  private criaCertificadoEn(nome: string, cpfCnpj: string, modalidade: string, cidade: string, empreendimentos: any[], propriedades: any[], urlQrCode: string) {
    this.makePdf.setParamPage({
      pageSize: 'A4',
      pageOrientation: 'landscape',
      background: [
        {
            image: 'data:image/jpeg;base64,' + fundo.base64,
            width: 845
        }
      ],
      pageMargins: [0, 0, 0, 0],
      defaultStyle: {
        p: {
          alignment: 'justify'
        }
      }
    });

    let base64Selo = '';

    if (modalidade == 'Carbono 0%'){
      base64Selo = selosEn.carbono0;
    } else if (modalidade == 'Compromissário'){
      base64Selo = selosEn.compromissario;
    } else if (modalidade == 'Apoiador'){
      base64Selo = selosEn.apoiador;
    } else if (modalidade == 'Financiador'){
      base64Selo = selosEn.financiador;
    }
    
    let selo = {
      absolutePosition: {x:359, y:36},
      image: 'data:image/jpeg;base64,' + base64Selo,
			width: 130
    };

    let certificado = {
      text: 'CERTIFICATE',
      absolutePosition: {x:0, y:165}, alignment: 'center',
      color: '#0f1f66', fontSize: 50, bold: true,
    };

    let corpo = {};

    if (propriedades.length > 0){
      corpo = {
        absolutePosition: {x:50, y:240},
        table: {
          widths: [740],
          body: [
            [
              {
                characterSpacing: 1,
                alignment: 'justify',
                lineHeight: 1.3,
                text: [
                {text: 'The Government of Mato Grosso, through the Secretary of State for the Environment (Sema-MT), grants the',
                 color: '#5565ad', fontSize: 16},
                {text: ` ${this.modalidadeEn(modalidade)} `, fontSize: 16, color: '#0f1f66', bold: true},
                {text: 'seal under the Carbon Neutral MT Program, instituted by Decree no. 1,160, October 25th, 2021, to',
                 color: '#5565ad', fontSize: 16},
                {text: ` ${nome.toUpperCase()}, ${this.docFormat(cpfCnpj)}, ${propriedades[0].identificacao.toUpperCase()}, CAR Nº:${propriedades[0].car.toUpperCase()}, `,
                 fontSize: 16, bold: true, color: '#0f1f66'},
                {text: 'located in the municipality of', color: '#5565ad', fontSize: 16},
                {text: ` ${cidade.toUpperCase()}.`, fontSize: 16, color: '#0f1f66', bold: true},
                {text: '\n\nSTATUS', color: '#0f1f66', fontSize: 16, bold: true},
                {text: `\nACTIVE`, fontSize: 16, color: '#5565ad'},
                ]
              }
            ]
          ],
        },
        layout: 'noBorders'
      };
    } else if (empreendimentos.length > 0){
      corpo = {
        absolutePosition: {x:50, y:240},
        table: {
          widths: [740],
          body: [
            [
              {
                characterSpacing: 1,
                alignment: 'justify',
                lineHeight: 1.3,
                text: [
                {text: 'The Government of Mato Grosso, through the Secretary of State for the Environment (Sema-MT), grants the',
                color: '#5565ad', fontSize: 16},
                {text: ` ${this.modalidadeEn(modalidade)} `, fontSize: 16, color: '#0f1f66', bold: true},
                {text: 'seal under the Carbon Neutral MT Program, instituted by Decree no. 1,160, October 25th, 2021, to',
                color: '#5565ad', fontSize: 16},
                {text: ` ${nome.toUpperCase()}, ${this.docFormat(cpfCnpj)}, ${empreendimentos[0].empreendimento.toUpperCase()}, `,
                fontSize: 16, color: '#0f1f66', bold: true},
                {text: 'located in the municipality of', color: '#5565ad', fontSize: 16},
                {text: ` ${cidade.toUpperCase()}.`, fontSize: 16, color: '#0f1f66', bold: true},
                {text: '\n\nSTATUS', color: '#0f1f66', fontSize: 16, bold: true},
                {text: `\nACTIVE`, fontSize: 16, color: '#5565ad'},
                ]
              }
            ]
          ],
        },
        layout: 'noBorders'
      };
    } else{
      corpo = {
        absolutePosition: {x:50, y:240},
        table: {
          widths: [740],
          body: [
            [
              {
                characterSpacing: 1,
                alignment: 'justify',
                lineHeight: 1.3,
                text: [
                {text: 'The Government of Mato Grosso, through the Secretary of State for the Environment (Sema-MT), grants the',
                color: '#5565ad', fontSize: 16},
                {text: ` ${this.modalidadeEn(modalidade)} `, fontSize: 16, color: '#0f1f66', bold: true},
                {text: 'seal under the Carbon Neutral MT Program, instituted by Decree no. 1,160, October 25th, 2021, to',
                color: '#5565ad', fontSize: 16},
                {text: ` ${nome.toUpperCase()}, ${this.docFormat(cpfCnpj)}.`,
                 fontSize: 16, bold: true, color: '#0f1f66',},
                {text: '\n\nSTATUS', color: '#0f1f66', fontSize: 16, bold: true},
                {text: `\nACTIVE`, fontSize: 16, color: '#5565ad'},
                ]
              }
            ]
          ],
        },
        layout: 'noBorders'
      };
    };

    let ass_mauren = {
      absolutePosition: {x:0, y:338}, alignment: 'center',
      image: 'data:image/jpeg;base64,' + assinatura.base64,
			width: 260
    };

    let cargo_mauren = {
      text: 'Secretary of State for the Environment\nMauren Lazzaretti',
      absolutePosition: {x:0, y:415}, alignment: 'center',
      color: '#0f1f66', fontSize: 12, bold: true, characterSpacing: 1
    };

    let logo = {
      absolutePosition: {x:525, y:465},
      image: 'data:image/jpeg;base64,' + logoSema.base64,
			width: 220
    };

    let qrcode = {qr: `${urlQrCode}`, fit: '105', absolutePosition: {x:100, y:465}};

    let data = [
      {characterSpacing: 1, text: 'Cuiabá, ' + this.getDataAtualEn() + '.', color: '#5565ad', fontSize: 12, absolutePosition: {x:200, y:475}}
    ];

    let textoQR = {
      absolutePosition: {x:200, y:485},
      table: {
				widths: [300],
				body: [
					[
            {text: '\nThe legitimacy of this document can be checked by accessing the QR Code.',
            characterSpacing: 1, color: '#5565ad', fontSize: 12}
          ]
				],
			},
			layout: 'noBorders'
    };

    return [
      ...data, certificado, qrcode, textoQR, selo, corpo, ass_mauren, cargo_mauren, logo
    ];
  }

  public openFinanciador(contemplado: string, acao: string, urlQrCode: string) {
    this.makePdf.open(this.criaCertificadoFinanciador(contemplado, acao, urlQrCode));
  }

  public base64Financiador(callback: commandFunction, contemplado: string, acao: string, urlQrCode: string) {
    this.makePdf.base64(
      this.criaCertificadoFinanciador(contemplado, acao, urlQrCode),
      (data) => {
        callback(data);
      }
    );
  }

  private criaCertificadoFinanciador(contemplado: string, acao: string, urlQrCode: string) {
    this.makePdf.setParamPage({
      pageSize: 'A4',
      pageOrientation: 'landscape',
      background: [
        {
            image: 'data:image/jpeg;base64,' + financiador.base64,
            width: 860
        }
      ],
      pageMargins: [0, 0, 0, 0],
      defaultStyle: {
        p: {
          alignment: 'justify'
        }
      }
    });

    let data = [
      {text: this.getDataAtual(), color: '#a8cf45', fontSize: 13, bold: true, absolutePosition: {x:55, y:28}}
    ];

    let corpo = {
      absolutePosition: {x:62, y:180},
      table: {
				widths: [490],
				body: [
					[
            {
              characterSpacing: 1,
              text: [
              {text: 'CONTEMPLADO:', color: '#a8cf45', fontSize: 20, bold: true},
              {text: `\n${contemplado}`, fontSize: 28},
              {text: '\n\n\nAÇÃO:', color: '#a8cf45', fontSize: 14, bold: true},
              {text: `\n${acao}`, fontSize: 12},
              {text: '\n\nINSTRUMENTO:', color: '#a8cf45', fontSize: 14, bold: true},
              {text: '\nCertifica o financiamento de ações voltadas a implementação do programa Carbono Neutro MT, com objetivo de neutralização de emissões até 2035, com meta intermediária de redução de 80% das emissões até 2030, nos termos do Decreto no 1.160, de 25 de outubro de 2021.', fontSize: 12}
              ]
            }
          ]
				],
			},
			layout: 'noBorders'
    };

    let qrcode = {qr: `${urlQrCode}`, fit: '100', absolutePosition: {x:80, y:465}};
    

    return [
      ...data, corpo, qrcode
    ];
  }

  public openApoiador(contemplado: string, acao: string, urlQrCode: string) {
    this.makePdf.open(this.criaCertificadoApoiador(contemplado, acao, urlQrCode));
  }

  public base64Apoiador(callback: commandFunction, contemplado: string, acao: string, urlQrCode: string) {
    this.makePdf.base64(
      this.criaCertificadoApoiador(contemplado, acao, urlQrCode),
      (data) => {
        callback(data);
      }
    );
  }

  private criaCertificadoApoiador(contemplado: string, acao: string, urlQrCode: string) {
    this.makePdf.setParamPage({
      pageSize: 'A4',
      pageOrientation: 'landscape',
      background: [
        {
            image: 'data:image/jpeg;base64,' + apoiador.base64,
            width: 865
        }
      ],
      pageMargins: [0, 0, 0, 0],
      defaultStyle: {
        p: {
          alignment: 'justify'
        }
      }
    });

    let data = [
      {text: this.getDataAtual(), color: '#a8cf45', fontSize: 13, bold: true, absolutePosition: {x:55, y:28}}
    ];

    let corpo = {
      absolutePosition: {x:62, y:180},
      table: {
				widths: [490],
				body: [
					[
            {
              characterSpacing: 1,
              text: [
              {text: 'CONTEMPLADO:', color: '#a8cf45', fontSize: 20, bold: true},
              {text: `\n${contemplado}`, fontSize: 28},
              {text: '\n\n\nAÇÃO:', color: '#a8cf45', fontSize: 14, bold: true},
              {text: `\n${acao}`, fontSize: 12},
              {text: '\n\nINSTRUMENTO:', color: '#a8cf45', fontSize: 14, bold: true},
              {text: '\nCertifica o apoio a divulgação e implementação do programa Carbono Neutro MT, com objetivo de neutralização de emissões até 2035, com meta intermediária de redução de 80% das emissões até 2030, nos termos do Decreto no 1.160, de 25 de outubro de 2021.', fontSize: 12}
              ]
            }
          ]
				],
			},
			layout: 'noBorders'
    };

    let qrcode = {qr: `${urlQrCode}`, fit: '100', absolutePosition: {x:80, y:465}};
    

    return [
      ...data, corpo, qrcode
    ];
  }

  public openCompromissario(contemplado: string, acao: string, urlQrCode: string) {
    this.makePdf.open(this.criaCertificadoCompromissario(contemplado, acao, urlQrCode));
  }

  public base64Compromissario(callback: commandFunction, contemplado: string, acao: string, urlQrCode: string) {
    this.makePdf.base64(
      this.criaCertificadoCompromissario(contemplado, acao, urlQrCode),
      (data) => {
        callback(data);
      }
    );
  }

  private criaCertificadoCompromissario(contemplado: string, acao: string, urlQrCode: string) {
    this.makePdf.setParamPage({
      pageSize: 'A4',
      pageOrientation: 'landscape',
      background: [
        {
            image: 'data:image/jpeg;base64,' + compromissario.base64,
            width: 862
        }
      ],
      pageMargins: [0, 0, 0, 0],
      defaultStyle: {
        p: {
          alignment: 'justify'
        }
      }
    });

    let data = [
      {text: this.getDataAtual(), color: '#a8cf45', fontSize: 13, bold: true, absolutePosition: {x:54, y:28}}
    ];

    let corpo = {
      absolutePosition: {x:61, y:180},
      table: {
				widths: [490],
				body: [
					[
            {
              characterSpacing: 1,
              text: [
              {text: 'CONTEMPLADO:', color: '#a8cf45', fontSize: 20, bold: true},
              {text: `\n${contemplado}`, fontSize: 28},
              {text: '\n\n\nAÇÃO:', color: '#a8cf45', fontSize: 14, bold: true},
              {text: `\n${acao}`, fontSize: 12},
              {text: '\n\nINSTRUMENTO:', color: '#a8cf45', fontSize: 14, bold: true},
              {text: '\nCertifica o compomisso de neutralização de emissões até 2035, com meta intermediária de redução de 80% das emissões até 2030, nos termos do Decreto no 1.160, de 25 de outubro de 2021.', fontSize: 12}
              ]
            }
          ]
				],
			},
			layout: 'noBorders'
    };

    let qrcode = {qr: `${urlQrCode}`, fit: '100', absolutePosition: {x:80, y:465}};
    

    return [
      ...data, corpo, qrcode
    ];
  }
}
