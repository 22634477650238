import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DgDataTableModule } from '../../components/dg-shared/dg-data-table/dg-data-table.module';
import { RecuperaTokenService } from 'src/app/services/recupera-token.service';
import { DgCardModule } from '../../components/dg-shared/dg-card/dg-card.module';
import { DgInsertEditModule } from '../../components/dg-shared/dg-crud/dg-insert-edit/dg-insert-edit.module';
import { JsonService } from 'src/app/services/json.service';
import { DgReceivedAvisoModule } from '../../components/dg-shared/dg-received-aviso/dg-received-aviso.module';
import { DgSharedModule } from 'src/app/components/dg-shared/dg-shared.module';
import { MatIconModule } from '@angular/material/icon';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CadastroSeloInsertComponent } from './cadastro-selo-insert/cadastro-selo-insert.component';
import { CadastroSeloMainComponent } from './cadastro-selo-main/cadastro-selo-main.component';
import { StatusSeloPipe } from './pipe/status-selo.pipe';
import { MatRadioModule } from '@angular/material/radio';
import { DgFileInputModule } from 'src/app/components/dg-shared/dg-file-input/dg-file-input.module';
import { CategoriaPipe } from './pipe/categoria.pipe';
import { CpfCnpjPipe } from './pipe/cpf-cnpj.pipe';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DgSharedModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatIconModule,
    DgInsertEditModule,
    DgDataTableModule,
    DgCardModule,
    DgReceivedAvisoModule,
    NgxMaskModule.forRoot(),
    MatDividerModule,
    MatTooltipModule,
    MatRadioModule,
    DgFileInputModule

  ],
  declarations: [
    CadastroSeloInsertComponent,
    CadastroSeloMainComponent,
    CategoriaPipe,
  ],
  providers:[
    StatusSeloPipe,
    CategoriaPipe,
    CpfCnpjPipe
  ]
})
export class CadastroSeloModule { }
