export enum TipoDocumento {
    AUTO_INFRACAO = 'Auto de Infração',
    AUTO_INSPECAO = 'Auto de Inspeção',
    TERMO_EMBARGO_INTERDICAO = 'Termo de embargo/interdição',
    TERMO_APREENSAO = 'Termo de Apreensão',
    RECIBO_DOACAO = 'Recibo de Doação',
    TERMO_DEPOSITO = 'Termo de Depósito',
    RELATORIO_TECNICO = 'Relatório Técnico',
    AVISO_RECEBIMENTO = 'Aviso de Recebimento',
    PUBLICACAO_DIARIA_OFICIO = 'Publicação em Diário Oficial',
    DEFESA_ADMINISTRATIVA = 'Defesa Adminstrativa',
    PEDIDO_DESEMBARGO = 'Pedido de Desembargo',
    PEDIDO_RESTITUICAO_BEM_TROCA_FIEL_DEPOSITARIO = 'Pedido de restituição de bem/troca de fiel depositário',
    DESPACHO = 'Despacho',
    MANIFESTACAO_TECNICA_PARECER_TECNICO = 'Manifestação Técnica (parecer técnico)',
    MANIFESTACAO_AUTUADO = 'Manifestação do autuado',
    CERTIDAO_REINCIDENCIA = 'Certidão de reincidência',
    DESPACHO_ENCAMINHAMENTO = 'Despacho de encaminhamento',
    DECISAO_ADMINISTRATIVA = 'Decisão administrativa',
    OFICIO = 'Ofício',
    AUTO_IMPOSICAO_MULTA = 'Auto de imposição de multa',
    RECURSO_ADMINISTRATIVO = 'Recurso administrativo',
    VOTO_RELATOR_REVISOR = 'Voto (relator/revisor)',
    ACORDAO = 'Acórdão',
    CERTIDAO_EMITIDA_CAR = 'Certidão emitida pela CAR (certicando o pagamento ou não da multa)',
    COMUNICACAO_INTERNA = 'Comunicação Interna',
    JUNTADA_DOCUMENTOS = 'Juntada de documentos'
}
