import { Component, Input, OnInit, HostListener, AfterContentInit, AfterViewInit } from '@angular/core';
import { DgSidebarItemModel } from '../dg-model/dg-sidebar-item-model';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dg-template',
  templateUrl: './dg-template.component.html',
  styleUrls: ['./dg-template.component.css']
})
export class DgTemplateComponent implements OnInit {

  @Input()
  showHeader: boolean = true;

  @Input()
  showSidebar: boolean = true;

  @Input()
  menuItens: Array<DgSidebarItemModel>;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  constructor() { }

  ngOnInit(): void {
  }

  possuiHeader() {
    if (this.showHeader) {
      return 'alturaHeader';
    } else {
      return '';
    }
  }

  @HostListener('window:show-loading')
  showLoadin() {
    setTimeout(() => {
      this.loadingSubject.next(true);
    })
  }

  @HostListener('window:hide-loading')
  hideLoadin() {
    setTimeout(() => {
      this.loadingSubject.next(false);
    },100);
  }

  @HostListener('window:hide-header')
  headerHide() {
    setTimeout(() => {
      this.showHeader = false;
    },100);
  }

  @HostListener('window:show-header')
  headerShow() {
    setTimeout(() => {
      this.showHeader = true;
    },100);
  }

}
