import { Injectable } from '@angular/core';
import { AppUtils} from '../../../../../utils/app-utils';
import { RecuperaTokenService } from 'src/app/services/recupera-token.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from "rxjs";
import { RestReturn } from "src/app/components/common/rest-return";
import { HttpClient, HttpParams } from "@angular/common/http";
import { AbstractHttpService } from 'src/app/services/http/abstract-http.service';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root'
})
export class DgHeaderService extends AbstractHttpService<any>{

  constructor(http: HttpClient, private util: AppUtils, protected recuperaToken: RecuperaTokenService, protected activatedRoute: ActivatedRoute, protected cookieService: CookieService) {
    super(http, util.getBaseUrlWebService(), `/api/v1/siga/autuacao/usuario/scorp`, recuperaToken, activatedRoute, cookieService);
  }
}
