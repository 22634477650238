import { Pipe, PipeTransform } from '@angular/core';
import { CategoriaSelo } from 'src/app/enum/categoria-selo.enum';

@Pipe({
  name: 'categoria'
})
export class CategoriaPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    return CategoriaSelo[value];
  }

}
