import { forEach } from 'min-dash';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Actionbutton } from './dg-actionbutton/actionbutton';

@Component({
  selector: 'dg-actionbar',
  templateUrl: './dg-actionbar.component.html',
  styleUrls: ['./dg-actionbar.component.css']
})
export class DgActionbarComponent implements OnInit {

  /* listaButton = [new Actionbutton(ButtonClasses.success, '', 'Inserir', 'Pressione para inserir'),
    new Actionbutton(ButtonClasses.warning, '', 'Alterar', 'Pressione para alterar'),
    new Actionbutton(ButtonClasses.danger, '', 'Excluir', 'Pressione para excluir'),
    new Actionbutton(ButtonClasses.dark, '', 'Filtrar', 'Pressione para excluir'),
    new Actionbutton(ButtonClasses.primary, '', 'Prosseguir', 'Pressione para excluir')
  ] */

  @Input()
  botoesAcao: Actionbutton[];

  botoesAcaoSubject = new BehaviorSubject<Actionbutton[]>([]);

  constructor() { }

  ngOnInit(): void {
    if (this.botoesAcao) {
      this.botoesAcaoSubject.next(this.botoesAcao);
    } else {
      this.botoesAcao = [];
    }
  }

  @HostListener('window:popular-botoes-acao', ['$event'])
  popularBotoesAcao(event) {
    this.botoesAcaoSubject.next(event.detail.botoes);
  }

  @HostListener('window:remover-botoes-acao', ['$event'])
  removerBotoesAcao(event) {
    const botoesAtivos: Actionbutton[] = this.botoesAcaoSubject.value;
    const botoesFiltrados: Actionbutton[] = botoesAtivos.filter((botao) => {
      return event.detail.botoes.findIndex(botaoIndex => botaoIndex.id === botao.id) < 0
    });
    this.botoesAcaoSubject.next(botoesFiltrados);
  }

}
