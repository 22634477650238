import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { RestReturn } from 'src/app/components/common/rest-return';
import { AbstractHttpService } from 'src/app/services/http/abstract-http.service';
import { RecuperaTokenService } from 'src/app/services/recupera-token.service';
import { AppUtils } from 'src/app/utils/app-utils';
import { CadastroSeloEntity } from '../model/cadastro-selo.entity';

@Injectable({
  providedIn: 'root'
})
export class CadastroSeloService extends AbstractHttpService<CadastroSeloEntity> {

  constructor(
    http: HttpClient, 
    private util: AppUtils, 
    protected cookieService: CookieService, 
    protected recuperaToken: RecuperaTokenService, 
    protected activatedRoute: ActivatedRoute
    ) {
    super(http, util.getBaseUrlWebService(), `/api/v1/carbono/neutro/pedido/certificado`, recuperaToken, activatedRoute, cookieService);
  }


  public buscarProcesso(): Observable<RestReturn<CadastroSeloEntity>> {
    const server = this.util.getBaseUrlWebService();
    let httpParam: HttpParams = new HttpParams();
    

    return this.http.get<RestReturn<CadastroSeloEntity>>(`${server}/api/v1/siga/autuacao/permissao/todos`, { params: httpParam });
  }

  public buscarArquivoPandora(chave: string): Observable<RestReturn<any>> {
    const server = this.util.getBaseUrlWebService();
    let httpParam: HttpParams = new HttpParams();
    

    return this.http.get<RestReturn<any>>(`${server}/api/v1/siga/carbono/neutro/pandora/${chave}`, { params: httpParam });
  }


  public incluirArquivoPandora(arquivo, pedidoId: number): Observable<RestReturn<any>> {
    const url = this.util.getBaseUrlWebService();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam
    .append("pedidoCertificadoId", pedidoId.toString());

    return this.http.post<RestReturn<any>>(`${url}/api/v1/siga/carbono/neutro/pandora/incluir/documento/pedido`, arquivo, { params: httpParam});
  }

  public deletarArquivosPandora(ids: number[]): Observable<RestReturn<any>> {
    const server = this.util.getBaseUrlWebService();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam
    .append("ids", `${ids}`);

    return this.http.delete<RestReturn<any>>(`${server}/api/v1/carbono/neutro/documento/pedido`, { params: httpParam });
  }

}
