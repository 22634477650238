import { Component, Input } from '@angular/core';
import { DgCustomButton } from '../../dg-data-table/model/DgCustomButton';

@Component({
  selector: 'dg-insert-edit',
  templateUrl: './dg-insert-edit.component.html',
  styleUrls: ['./dg-insert-edit.component.css']
})
export class DgInsertEditComponent {

  @Input()
  public enableDeletar = true;

  @Input()
  public enableSalvar = true;

  @Input()
  public enableVoltar = true;

  @Input()
  public padd = 'padding-top: 70px';

  @Input()
  customActionButtons: DgCustomButton[];

  content;

  constructor() { }

  deletar() {
    window.dispatchEvent(new CustomEvent('deletar-insert-edit-event'));
  }

  voltar() {
    window.dispatchEvent(new CustomEvent('voltar-insert-edit-event'));
  }

  salvar() {
    window.dispatchEvent(new CustomEvent('salvar-insert-edit-event'));
  }

  executarFuncao(event) {
    if (event) {
      window.dispatchEvent(new CustomEvent(event));
    }
  }

}
