import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { BreadcrumbModule } from 'angular-crumbs';
import { DgBreadcrumbComponent } from './dg-content-body/dg-breadcrumb/dg-breadcrumb.component';
import { DgContentBodyComponent } from './dg-content-body/dg-content-body.component';
import { DgHeaderComponent } from './dg-header/dg-header.component';
import { DgSidebarModule } from './dg-sidebar/dg-sidebar.module';
import { DgTemplateComponent } from './dg-template.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatMenuModule } from '@angular/material/menu';
import { LoadingSemaModule } from '../../loading-sema/loading-sema.module';

@NgModule({
  declarations: [
    DgContentBodyComponent,
    DgHeaderComponent,
    DgTemplateComponent,
    DgBreadcrumbComponent,
  ],
  imports: [
    RouterModule,
    BrowserAnimationsModule,
    DgSidebarModule,
    BreadcrumbModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    LoadingSemaModule
  ],
  exports: [
    DgContentBodyComponent,
    DgHeaderComponent,
    DgTemplateComponent]
})
export class DgTemplateModule { }
