import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'camelCaseToSentenceCase'
})
export class CamelCaseToSentenceCasePipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    let result = value.replace(/([A-Z])/g, ' $1');
    let finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  }
}
