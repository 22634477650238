import { DgConfirmDialog } from './dg-confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [DgConfirmDialog],
  imports: [
    MatDialogModule,
    CommonModule,
    MatButtonModule
  ],
  exports: [DgConfirmDialog]
})
export class DgConfirmDialogModule { }
