import { ButtonClasses } from './button-classes';

export class Actionbutton {

  constructor(
    public id: string,
    public type: ButtonClasses,
    public label: string,
    public title: string,
    public action: Function,
    public event?: string,
  ) {}
}
