import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DgCustomButton } from '../dg-data-table/model/DgCustomButton';

@Component({
  selector: "dg-confirm-dialog",
  templateUrl: "dg-confirm-dialog.component.html",
  styleUrls: ['./dg-confirm-dialog.component.css']
})
export class DgConfirmDialog {

  public titulo: string;

  public conteudo: string;

  public fechar = false;

  public customButtons: DgCustomButton[];



  constructor(public dialogRef: MatDialogRef<DgConfirmDialog>, @Inject(MAT_DIALOG_DATA) data: any) {
    dialogRef.disableClose = true;
    this.titulo = data.titulo;
    this.conteudo = data.conteudo;
    this.fechar = data.fechar; 
    this.customButtons = data.customButtons;
  }

  onClick(opcao: boolean): void {
    this.dialogRef.close(opcao);
  }

}
