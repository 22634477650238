import { AppUtils } from 'src/app/utils/app-utils';
import { Component } from '@angular/core';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { DgSidebarItemModel } from './components/dg-shared/dg-model/dg-sidebar-item-model';
import { RecuperaTokenService } from './services/recupera-token.service';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'carbono-neutro';
  showHeader = true;
  showSidebar;
  authorizationCookieName = environment.AUTHORIZATION_COOKIE_NAME;
  identityCookieName = environment.IDENTITY_COOKIE_NAME;

  constructor(public appUtils: AppUtils, protected recuperaToken: RecuperaTokenService,
    protected activatedRoute: ActivatedRoute, private cookieService: CookieService) {
    this.showSidebar = false;
    setTimeout(() => {
      let token = this.recuperaToken.getToken(this.activatedRoute, this.cookieService);
      if (token != 'null' && token){
        sessionStorage.setItem('token', token);

        sessionStorage.setItem('authorizationCookie', `${this.authorizationCookieName}=${token}`);
        sessionStorage.setItem('identityCookie', `${this.identityCookieName}=${token}`);

        this.cookieService.set(this.authorizationCookieName, token);
        this.cookieService.set(this.identityCookieName, token);
      }
    }, 2000)
  }

  public menuItens: Array<DgSidebarItemModel> = [
    new DgSidebarItemModel(null, faHome, 'Início', '/home')
  ];
}
