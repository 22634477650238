import { FilterType } from './../enum/filter-type';
import { ColumnType } from './../enum/column-type';
import { OperatorType } from './../enum/operator-type';
import 'reflect-metadata';
import { AbstractEntity } from 'src/app/model/abstract.entity';

const tableColumnKey = Symbol('isTableColumn');
const fieldKey = Symbol('isField');

export class DgTableField {

  constructor(nomeColuna: string, tipoColuna: string, pipeColuna: any, hyperLink: boolean, columnMask:string, toolTip: boolean | string) {
    this.nomeColuna = nomeColuna;
    this.tipoColuna = tipoColuna;
    this.pipeColuna = pipeColuna;
    this.hyperLink = hyperLink;
    this.columnMask = columnMask;
    this.toolTip = toolTip;
  }

  public nomeColuna: string;

  public tipoColuna: string;

  public pipeColuna: any;

  public hyperLink: boolean;

  public columnMask: string;

  public toolTip: boolean | string;
}

export class DgFilter {

  constructor(nomeCampo: string, tipoCampo: string, valores: any,
    operador?: OperatorType, formato?: string, campoPesquisa?: string,
    ordem?: number, fieldMask?: string) {
    this.nomeCampo = nomeCampo;
    this.tipoCampo = tipoCampo;
    this.valores = valores;
    this.operador = operador;
    this.formato = formato;
    this.campoPesquisa = campoPesquisa;
    this.ordem = ordem;
    this.fieldMask = fieldMask
  }

  public nomeCampo: string;

  public tipoCampo: string;

  public valores: any;

  public operador: OperatorType;

  public formato: string;

  public campoPesquisa: string;

  public ordem: number;

  public fieldMask: string;
}

export function DgFilterField(fieldName?: string, tipoFiltro: string = FilterType.TEXTO, valores?: string,
  operador?: OperatorType, formato?: string, campoPesquisa?: string, ordem?: number, fieldMask: string = '') {
  return function (target: object, propertyKey: string): void {
    let properties: Map<string, DgFilter> = Reflect.getMetadata(fieldKey, target);

    if (!properties) {
      properties = new Map();
    }

    const tableFilter: DgFilter = new DgFilter((fieldName ? fieldName : propertyKey), tipoFiltro, valores, operador, formato, campoPesquisa, ordem, fieldMask);

    properties.set(propertyKey, tableFilter);
    Reflect.defineMetadata(fieldKey, properties, target);
  };
}

export function DgTableColumn(columnName?: string, tipoColuna: string = ColumnType.MOSTRAR_VALOR, pipeColuna: any = '', hyperLink: boolean = false, columnMask: string = '', toolTip: boolean | string = false) {
  return function (target: object, propertyKey: string): void {
    let properties: Map<string, DgTableField> = Reflect.getMetadata(tableColumnKey, target);

    if (!properties) {
      properties = new Map();
    }

    const tableColumn: DgTableField = new DgTableField((columnName ? columnName : propertyKey), tipoColuna, pipeColuna, hyperLink, columnMask, toolTip);

    properties.set(propertyKey, tableColumn);
    Reflect.defineMetadata(tableColumnKey, properties, target);
  };
}

function getFilteredProperties(key, origin: object): Map<string, any> {
  return Reflect.getMetadata(key, origin);
}

export abstract class DgTableEntity extends AbstractEntity {
  public marcadoParaDeletar: boolean = false;
  public tableColumns: Map<string, DgTableField> = getFilteredProperties(tableColumnKey, this);
  public searchFields: Map<string, DgFilter> = getFilteredProperties(fieldKey, this);
}
