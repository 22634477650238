import { Component, HostListener, OnInit } from '@angular/core';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { ComboBoxValue } from 'src/app/components/dg-shared/dg-data-table/abstract/dg-combo-box-value';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { CadastroSeloEntity } from '../model/cadastro-selo.entity';
import { CadastroSeloService } from '../service/cadastro-selo.service';

@Component({
  selector: 'app-cadastro-selo-main',
  templateUrl: './cadastro-selo-main.component.html',
  styleUrls: ['./cadastro-selo-main.component.css']
})
export class CadastroSeloMainComponent implements OnInit {

  public readonly url = '/selos/';

  public entityMapping: CadastroSeloEntity;

  public comboBoxValues: Map<string, ComboBoxValue[]>;

  public numNovasAtividades = 0;

  public faBell = faBell;

  public times = 100;

  constructor(public service: CadastroSeloService, private alert: SnackBarService) {
  }

  ngOnInit(): void {
    this.entityMapping = new CadastroSeloEntity();

    this.popularValoresFiltrosCombos()

    window.dispatchEvent(new CustomEvent('hide-header'));

    this.service.pesquisar().subscribe(resp => console.log(resp));

  }

  @HostListener('window:change-table-carbono', ['$event'])
  changeTable(event?) {
    this.times = 100;
    setTimeout(() => {
      this.rowsChanges()
    }, 1000)
  }

  popularValoresFiltrosCombos() {
    this.comboBoxValues = new Map();
    this.comboBoxValues.set('categoriaFiltro', this.categoriaFiltro());
    this.comboBoxValues.set('statusFiltro', this.statusFiltro());

  }

  categoriaFiltro(): ComboBoxValue[] {
    const values: ComboBoxValue[] = [];
    values.push(new ComboBoxValue("Apoiador", "APOIADOR"));
    values.push(new ComboBoxValue("Carbono 0%", "CARBONO_0"));
    values.push(new ComboBoxValue("Compromissário", "COMPORMISSARIO"));
    values.push(new ComboBoxValue("Financiador", "FINANCIADOR"));
    return values;
  }

  statusFiltro(): ComboBoxValue[] {
    const values: ComboBoxValue[] = [];
    values.push(new ComboBoxValue("Novo", "NOVO"));
    values.push(new ComboBoxValue("Em processo", "EM_PROCESSO"));
    values.push(new ComboBoxValue("Outorgado", "OUTORGADO"));
    values.push(new ComboBoxValue("Cancelado", "CANCELADO"));

    return values;
  }

  rowsChanges(){
    var rows = document.getElementsByTagName('mat-row') as any;
    var list_rows = Array.from(rows) as any;
    var countBell = document.getElementById('count-bell') as any;
    this.numNovasAtividades = 0
    if (list_rows.length > 0){
      list_rows.forEach((row, index) => {
        try{
          if (row.children[5].outerText == 'NOVO'){
            rows[index].classList.add('atividade-nova');
            this.numNovasAtividades++;
          } 
        } catch{
          if (this.times > 0) {
            setTimeout(() => {
                this.times--;
                this.rowsChanges();            
            }, 100);
          }
        }
      });
    } else{
      if (this.times > 0) {
        setTimeout(() => {
            this.times--;
            this.rowsChanges();            
        }, 100);
      }
    }
  }

}
