import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { RestReturn } from 'src/app/components/common/rest-return';
import { RecuperaTokenService } from 'src/app/services/recupera-token.service';
import { AppUtils } from 'src/app/utils/app-utils';

@Injectable({
  providedIn: 'root'
})
export class CertificadoService {
  protected readonly path: string;

  constructor(protected http: HttpClient, private util: AppUtils,
     protected recuperaToken: RecuperaTokenService, protected activatedRoute: ActivatedRoute) {
    this.path = util.getBaseUrlWebService();
  }

  public buscarCertificado(filtros: Map<any, any>): Observable<any> {
    const url = this.path;
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("filtros", this.util.stringifyMap(filtros));

    return this.http.get<any>(`${url}/api/v1/carbono/neutro/buscar/externo/certificado`, { params: httpParam });
  }

}
