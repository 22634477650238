import { environment } from 'src/environments/environment';
import { AppUtils } from 'src/app/utils/app-utils';
import { ActivatedRoute, Params } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

export class RecuperaTokenService {

  creadToken(userName, userId, userUnid, userMat, userDoc){
    var token = {
      details: {
        id: parseInt(userId),
        nome: userName,
        servidor: (userUnid != null && userMat != null)
      },
      exp: 1612921408,
      iat: 1612892608,
      iss: "SEMA",
      jti: "30959b8f-9fe5-4c7b-8d96-35b0d7d20899",
      nbf: 1612892608,
      sub: userDoc,
    }

    return window.btoa(JSON.stringify(token))
  }

  getToken(activatedRoute: ActivatedRoute, cookieService: CookieService) {
    if (!environment.production) {
      var userName = sessionStorage.getItem("userName");
      var userId = sessionStorage.getItem("userId");
      var userUnid = sessionStorage.getItem("userUnid") === "null" ? null : sessionStorage.getItem("userUnid");
      var userMat = sessionStorage.getItem("userMat") === "null" ? null : sessionStorage.getItem("userMat");
      var userDoc = sessionStorage.getItem("userCpfCnpj") === "null" ? null : sessionStorage.getItem("userCpfCnpj");

      if (userName && userId){
        var novoToken = this.creadToken(userName, userId, userUnid, userMat, userDoc);
        return `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.${novoToken}.YWWX7WR2br7YafGmkJOu5ROD0na3O54JLJxB0tGNpuo`;
      } else{
        return 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI5MDU2NTEyNjc2OCIsIm5iZiI6MTYxMjg5MjYwOCwiaXNzIjoiU0VNQSIsImRldGFpbHMiOnsic2Vydmlkb3IiOmZhbHNlLCJub21lIjoiQWxleGFuZHJlIEdvbsOnYWx2ZXMgUGVyZWlyYSIsImlkIjoyMTQwMTB9LCJleHAiOjE2MTI5MjE0MDgsImlhdCI6MTYxMjg5MjYwOCwianRpIjoiMzA5NTliOGYtOWZlNS00YzdiLThkOTYtMzViMGQ3ZDIwODk5In0.YWWX7WR2br7YafGmkJOu5ROD0na3O54JLJxB0tGNpuo';
      }

    } else {
      let accessToken, token;
      activatedRoute.queryParams.subscribe((params: Params) => {
        accessToken = params['accessToken'];
        token = params['token'];
      });
      let sessionToken = sessionStorage.getItem('token')
      let cookieTokenAuthorization = cookieService.get(environment.AUTHORIZATION_COOKIE_NAME) === "null" ? null : cookieService.get(environment.AUTHORIZATION_COOKIE_NAME);
      let cookieTokenIdentity = cookieService.get(environment.IDENTITY_COOKIE_NAME) === "null" ? null : cookieService.get(environment.IDENTITY_COOKIE_NAME);
      if (token){
        sessionStorage.setItem('token', token);
        return token
      } else if (accessToken){
        sessionStorage.setItem('token', accessToken);
        return accessToken;
      } else if (sessionToken){
        sessionStorage.setItem('token', sessionToken);
        return sessionToken;
      } else if (cookieTokenIdentity) {
        sessionStorage.setItem('token', cookieTokenIdentity);
        return cookieTokenIdentity;
      } else {
        return null;
      }
    }
  }
}