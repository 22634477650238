import { Component, Input } from '@angular/core';

export interface InterfaceBtn {
  btn: 'basic' | 'accent' | 'warn' | 'primary' |
    // opções do BOOTSTRAP
    'bootstrap-secondary' | 'bootstrap-success' | 'bootstrap-danger' |
    'bootstrap-warning' | 'bootstrap-info' | 'bootstrap-dark' | string;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dg-button',
  templateUrl: './dg-button.component.html',
  styleUrls: ['./_dg-button.component.scss']
})
export class DgButtonComponent {

  public static all = false;

  public static idLiberado: string | undefined = undefined;

  @Input() color: InterfaceBtn['btn'] = 'basic';

  // tslint:disable-next-line:no-input-rename
  @Input('tipo') type: 'outline' | 'basic' | 'normal' | 'icon' | 'icon-mini' = 'normal';

  @Input() disabled: boolean | any;

  @Input() frame: string = 'material';

  @Input() size: 'normal' | 'sm' | 'lg' | 'block' = 'normal';

  // tslint:disable-next-line:no-input-rename
  @Input('id') idLib: string | undefined = undefined;

  @Input('type') typeBtnHtml: 'button' | 'submit' | string = 'button'

  bloqueia() {
    if (DgButtonComponent.all) {
      if (
        this.idLib !== undefined &&
        DgButtonComponent.idLiberado !== undefined
      ) {
        return !(this.idLib === DgButtonComponent.idLiberado);
      }
      return true;
    }
    return this.disabled;
  }

  // tslint:disable-next-line:member-ordering
  public static bloqueiaBtn(idLibera?: string) {
    if (DgButtonComponent.all) {
      DgButtonComponent.idLiberado = undefined;
    } else if (idLibera) {
      DgButtonComponent.idLiberado = idLibera;
    }
    DgButtonComponent.all = !DgButtonComponent.all;
  }

  constructor() {
  }

}
