import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { DgAbstractInsertEditComponent } from 'src/app/components/dg-shared/dg-abstract/dg-abstract-insert-edit.component';
import { DgConfirmDialog } from 'src/app/components/dg-shared/dg-confirm-dialog/dg-confirm-dialog.component';
import { DgFileInputComponent } from 'src/app/components/dg-shared/dg-file-input/dg-file-input/dg-file-input.component';
import { CategoriaSelo } from 'src/app/enum/categoria-selo.enum';
import { JsonService } from 'src/app/services/json.service';
import { RecuperaTokenService } from 'src/app/services/recupera-token.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { AppUtils } from 'src/app/utils/app-utils';
import { CadastroSeloEntity } from '../model/cadastro-selo.entity';
import { CadastroSeloService } from '../service/cadastro-selo.service';

@Component({
  selector: 'app-cadastro-selo-insert',
  templateUrl: './cadastro-selo-insert.component.html',
  styleUrls: ['./cadastro-selo-insert.component.scss']
})
export class CadastroSeloInsertComponent extends DgAbstractInsertEditComponent<CadastroSeloEntity> implements OnInit  {

  showDeletar = true;
  montarFormulario = false;
  blockTrocarUsuario = false;

  usuarioSelecionado: CadastroSeloEntity;

  permissoesUsuario: string[] = [];

  @ViewChild('mult') skillSel1: MatSelect;

  protocolo: FormControl;
  statusSelo: FormControl;

  pessoaFisica = false;
  pessoaJuritica = false;

  categorioEnum = CategoriaSelo;

  propriedadeEscolhida: {identificacao?: string, car?: string, atividade?: string, licencas?: string, outorga?: string};
  empreendimentoEscolhido: {empreendimento?: string, atividade?: string, licencas?: string, outorga?: string};

  @ViewChild('fileInput') fileInput: DgFileInputComponent;

  novaConsideracao = '';

  constructor(protected route: ActivatedRoute, protected service: CadastroSeloService, protected alert: SnackBarService,
    private util: AppUtils, protected dialog: MatDialog, protected jsonService: JsonService,
    protected location: Location, protected tokenService: RecuperaTokenService, protected cookieService: CookieService) {
      super(route, service, alert, location, dialog);
    }
  
  ngOnInit(): void {
    window.dispatchEvent(new CustomEvent('hide-header'));
    window.dispatchEvent(new CustomEvent('show-loading'));
    super.ngOnInit();

    this.statusSelo = new FormControl('', Validators.required);
  }

  initNewEntity() {
    this.entity = new CadastroSeloEntity(null)
    this.showDeletar = false;
    this.montarFormulario = true;
    this.listarUsuariosScorb();
  }

  afterFetchEntity() {
    this.blockTrocarUsuario = true;
    this.montarFormulario = true;
    this.usuarioSelecionado = this.entity;

    if (this.entity.cpfCnpj.length > 11){
      this.pessoaJuritica = true;
    } else{
      this.pessoaFisica = true;
    }

    /* this.entity.propriedades.push({
      identificacao: 'Fazenda Maricá',
      atividade: 'xxxxxxx',
      car: '23232323',
      licencas: '323232',
      outorga: '34234243'
    })

    this.entity.propriedades.push({
      identificacao: 'Fazenda Itaboraí',
      atividade: 'xxxxxxx',
      car: '23232323',
      licencas: '323232',
      outorga: '34234243'
    })

    this.entity.empreendimentos.push({
      empreendimento: 'Indústria e comécia ltda.',
      atividade: 'xxxxxxx',
      licencas: '323232',
      outorga: '34234243'
    }) */

    /* this.entity.documentos.push({
      arquivo: 'teste yyyy',
      nome: 'Formuário de adesão 000000/2022.pdf',
    })

    this.entity.documentos.push({
      arquivo: 'teste yyyy',
      nome: 'Termo de compromísso volutário 000000/2022.pdf',
    }) */

    window.dispatchEvent(new CustomEvent('hide-loading'));
  }

  beforeSaveEntity(){
    return true
  }

  addConsideracao(){
    const user = this.util.takeUserInfoFromPayload(this.tokenService.getToken(this.route, this.cookieService));

    this.entity.consideracoesSetor.unshift(
      {consideracao: this.novaConsideracao,
       dataInclusao: new Date(),
       nomeUsuarioInclusao: user ? user.nome : ''
      }
    )
  }

  atualizarPermissoesUsusario(permissoesId){
  }

  atualizarUsuario(usuario){
    this.entity = usuario;
  }

  listarPermissoes(){
    
  }

  localizarCliente(){
    this.pessoaFisica = true;
  }

  listarUsuariosScorb(){
    
  }

  excluirConsideracao(i){
    this.entity.consideracoesSetor.splice(i, 1);
  }

  editarConsedirecao(i){
    this.novaConsideracao = this.entity.consideracoesSetor[i].consideracao;
    this.excluirConsideracao(i);
  }

  filter(searchIn, selectId) {
    var input, filter, ul, li, a, i;
    input = document.getElementById(searchIn) as any;
    filter = input.value.toUpperCase();
    var div = document.getElementById(selectId) as any;
    a = div.getElementsByTagName("mat-option") as any;
    for (i = 0; i < a.length; i++) {
      var txtValue = a[i].textContent || a[i].innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        a[i].style.display = "";
      } else {
        a[i].style.display = "none";
      }
    }
  }

  defineClasssStatus(){
    if (!this.entity.status){
      return 'status_null'
    } else if (this.entity.status == 'EM_PROCESSO'){
      return 'status_em_processo'
    }
    else if (this.entity.status == 'OUTORGADO'){
      return 'status_outorgado'
    }
    else if (this.entity.status == 'NEGADO'){
      return 'status_negado'
    }
    else if (this.entity.status == 'CANCELADO'){
      return 'status_cancelado'
    } else{
      return 'status_null'
    }
  }

  public toggleAllSelection(checked) {
    if (!checked) {
      this.skillSel1.options.forEach( (item) => item.select());
    } else {
      this.skillSel1.options.forEach( (item) => {item.deselect()});
    }
  }


  openPropriedade(index){
    var modal = document.getElementById('myModalPropriedades') as any;
    modal.style.display = 'flex';
    this.propriedadeEscolhida = this.entity.propriedades[index]
  }

  openEmpreendimento(index){
    var modal = document.getElementById('myModalEmpreendimento') as any;
    modal.style.display = 'flex';
    this.empreendimentoEscolhido = this.entity.empreendimentos[index]
  }

  public closeModal(idModal){
    var modal = document.getElementById(idModal) as any;
    modal.style.display = 'none';
  }

  enviarArquivosPandora(){
    let i = 0;
    if (this.fileInput.getArquivos().length > 0){
      window.dispatchEvent(new CustomEvent('show-loading'));
      this.fileInput.getArquivos().forEach(arquivo => {
        let formData: FormData = new FormData();
        i++;
        if (!this.entity.documentos){
          this.entity.documentos = [];
        }
        formData.append('novoArquivo', arquivo);
        this.service.incluirArquivoPandora(formData, this.entity.id)
        .subscribe({
          next: result => {
            if(i == this.fileInput.getArquivos().length){
              setTimeout(() =>{
                this.alert.message(result.mensagem);
                this.fileInput.removerTodos();
                this.ngOnInit();
              }, 2000)
            }
          },
          error: error => {
            window.dispatchEvent(new CustomEvent('hide-loading'));
            this.alert.message(error.error.mensagem);
          }
        });
      });
    } else {
      this.alert.message('Nenhum arquivo selecionado');
    }
  }


  base64ToFile(base64Src: string, tipoArquivo: string){
    const byteArray = new Uint8Array(atob(base64Src).split('').map(char => char.charCodeAt(0)));
    return new Blob([byteArray], {type: tipoArquivo});
  }

  public downloadBlob(selectedFile): void {
    let fileName = this.entity.documentos[selectedFile].nomeArquivo;
    this.service.buscarArquivoPandora(this.entity.documentos[selectedFile].chave).subscribe({
      next: result =>{
        let blob = this.base64ToFile(result.values[0], result.tipoArquivo);
        const anchor = window.document.createElement('a');
        anchor.href = window.URL.createObjectURL(blob);
        anchor.download = fileName;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        window.URL.revokeObjectURL(anchor.href);
      },
      error: error=>{
        this.alert.message(error.error.mensagem);
      }
    });
  }
  
  public openBlob(selectedFile){
    this.service.buscarArquivoPandora(this.entity.documentos[selectedFile].chave).subscribe({
      next: result=>{
        let blob = this.base64ToFile(result.values[0], result.tipoArquivo);
        const anchor = window.document.createElement('a');
        anchor.href = window.URL.createObjectURL(blob);
        anchor.target='_blank'
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        window.URL.revokeObjectURL(anchor.href);
      },
      error: error => {this.alert.message(error.error.mensagem)}
  });
  }

  deletarArquivoPandora(selectedFile) {
    const dialogRef = this.dialog.open(DgConfirmDialog, {data: {conteudo: 'Deseja excluir esse documento anexado? Esta ação é permanente.', titulo: 'Excluir documento anexado'}});
    dialogRef.afterClosed().subscribe(podeDeletar => {
      if (podeDeletar) {
        window.dispatchEvent(new CustomEvent('show-loading'));
        this.service.deletarArquivosPandora([this.entity.documentos[selectedFile].id])
        .subscribe({
          next: result => {
            window.dispatchEvent(new CustomEvent('hide-loading'));
            this.entity.documentos.splice(selectedFile, 1);
          },
          error: error => {
            window.dispatchEvent(new CustomEvent('hide-loading'));
            this.alert.message(error.error.mensagem);
          }
        });
      }
    });
  }

}
