export class DgSidebarItemModel {
  constructor(
    public icon?: string,
    public faIcon?: any,
    public label?: string,
    public url?: string,
    public subitem?: DgSidebarItemModel[],
    public showItem?: boolean,
    public tagLabel?: string,
  ) {
    this.showItem = true;
  }
}
