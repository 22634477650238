import { Component, HostListener, Input, OnInit } from '@angular/core';
import { DgHeaderService } from './service/dg-header.service'
import { AppUtils } from 'src/app/utils/app-utils'
import { UserEntity } from './modal/user.entity';
import { RecuperaTokenService } from 'src/app/services/recupera-token.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'dg-header',
  templateUrl: './dg-header.component.html',
  styleUrls: ['./dg-header.component.css']
})
export class DgHeaderComponent implements OnInit {

  verBarraBotao = false;
  verBotao = true;
  userNameSelect;
  userIdSelect;
  userUnidSelect;
  userMatSelect;
  userCargoSelect;
  list_users: UserEntity[] = [];
  userLogger: UserEntity;
  url = '/home';
  devMode = false;

  constructor(private service: DgHeaderService, public appUtils: AppUtils, private tokenService: RecuperaTokenService, private route: ActivatedRoute
    ) { }

  ngOnInit(): void {
    
  }

  updateUser(userId){
    const user: UserEntity = this.list_users.find(u => u.id == userId);
    sessionStorage.setItem("user", JSON.stringify(user));
    sessionStorage.setItem("userName", user.nome);
    sessionStorage.setItem("userId", `${user.id}`);
    sessionStorage.setItem("userUnid", user.setor != null ? user.setor : null);
    sessionStorage.setItem("userMat", user.matricula != null ? user.matricula : null);
    sessionStorage.setItem("userCargo", user.cargo != null ? user.cargo : null);
    sessionStorage.setItem("userCpfCnpj", user.cpf != null ? user.cpf : null);
    this.userUnidSelect = user.organizacaoAdministrativa;
    this.userMatSelect = user.matricula;
    this.userNameSelect = user.nome;
    this.userIdSelect = user.id;
    this.userCargoSelect = user.cargo;
    window.dispatchEvent(new CustomEvent('update-sidebar'));
    window.dispatchEvent(new CustomEvent('update-home'));
  }


  @HostListener('window:sidebar-event', ['$event'])
  barraLateral(event){
    let fechar = event.detail.fechar;
    this.verBarraBotao = !this.verBarraBotao; 
    var painel = document.getElementsByClassName('main-panel') as any;
    var barra = document.getElementsByClassName('sidebar') as any;
    if(fechar){
      for (var i = 260; i > -1; i--){
        if(innerWidth > 900){
          painel[0].style.width = `calc(100% - ${i}px)`;
        } else{
          painel[0].style.width = `100%`;
        }
        if (this.devMode){
          barra[0].style.left = `${i - 260}px`
        }
      }
    } else{
      for (var i = 1; i < 261; i++){
        if(innerWidth > 900){
          painel[0].style.width = `calc(100% - ${i}px)`;
        } else{
          painel[0].style.width = `100%`;
        }
        if (this.devMode){
          barra[0].style.left = `${i - 260}px`
        }
      }
    }
  }
}
