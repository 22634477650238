import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class JsonService {

  //Código copiado de https://stackoverflow.com/questions/15312529/resolve-circular-references-from-json-object
  public resolveReferences(json) {
    var byid = {}, // all objects by id
      refs = []; // references to objects that could not be resolved
    json = (function recurse(obj, prop, parent) {
      if (typeof obj === 'number' && !prop)
        return byid[obj];
      if (typeof obj === 'number' && prop === 'unidadeSupervisora')
        return byid[obj];
      if (typeof obj !== 'object' || !obj) // a primitive value
        return obj;
      if (Object.prototype.toString.call(obj) === '[object Array]') {
        for (var i = 0; i < obj.length; i++)
          // check also if the array element is not a primitive value
          if (typeof obj[i] === 'number' && !prop)
            obj[i] = byid[obj[i]];
          else if (typeof obj[i] === 'number' && prop === 'unidadeSupervisora')
            obj[i] = byid[obj[i]];
          else if (typeof obj[i] !== 'object' || !obj[i]) // a primitive value
            continue;
          else if ("$id" in obj[i])
            obj[i] = recurse(obj[i], i, obj);
          else
            obj[i] = recurse(obj[i], prop, obj);
        return obj;
      }
      if ("$id" in obj) {
        var id = obj.$id;
        delete obj.$id;
        byid[id] = obj;
        for (var propObj in obj)
          obj[propObj] = recurse(obj[propObj], propObj, obj);
      }
      return obj;
    })(json); // run it!

    /* for (var i = 0; i < refs.length; i++) { // resolve previously unknown references
      var ref = refs[i];
      ref[0][ref[1]] = byid[ref[2]];
      // Notice that this throws if you put in a reference at top-level
    } */
    return json;
  }
}
